import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CircularProgress } from '@mui/material';
import { Colors } from './style/theme';

export default function Pricing() {
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/getAllMasterData`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setPlans(data.data);
        } else {
          console.error('Failed to fetch data');
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const sortedPlans = plans.slice().sort((a, b) => a.SubscriptionAmount - b.SubscriptionAmount);
  const freeTrialPlan = sortedPlans.find((plan) => plan.subscriptionTitle === 'Free Trial');
  const basicPlan = sortedPlans.find((plan) => plan.subscriptionTitle === 'Basic');
  const standardPlan = sortedPlans.find((plan) => plan.subscriptionTitle === 'Standard');
  const contactSalesPlan = sortedPlans.find((plan) => plan.subscriptionTitle === 'Contact Sales');

  const handleSelectPlan = (planId) => {
    navigate(`dashboard/subscription/${planId}`);
  };

  return (
    <div id="pricing">
      <CssBaseline />
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5' }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Container
              sx={{
                textAlign: 'center',
              }}
            >
              <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 4, pb: 6 }}>
                <motion.div
                  initial={{ opacity: 0, y: -30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1 }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontWeight: '600',
                        fontSize: '26px',
                        lineHeight: '48px',
                        color: '#161414',
                        textAlign: {
                          xs: 'center',
                        },
                      }}
                    >
                      Pricing Plan
                    </Typography>
                  </Box>
                </motion.div>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: '600',
                      fontSize: '20px',
                      lineHeight: '48px',
                      color: '#161414',
                      textAlign: {
                        xs: 'center',
                      },
                    }}
                  >
                    Select a Plan
                  </Typography>
                </Box>
              </Container>
              <Container maxWidth="lg" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                  {[freeTrialPlan, basicPlan, standardPlan, contactSalesPlan].map((plan, index) => {
                    if (plan) {
                      return (
                        <Grid item key={index} xs={12} md={3}>
                          <motion.div
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 0.97 }}
                            transition={{ duration: 0.2 }}
                          >
                            <Card
                              sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                              }}
                            >
                              <CardHeader
                                title={plan.subscriptionTitle}
                                titleTypographyProps={{ align: 'center', variant: 'h6' }}
                                sx={{
                                  backgroundColor: Colors.dark_shaft,
                                  color: Colors.white,
                                  padding: '15px',
                                  borderTopLeftRadius: '10px',
                                  borderTopRightRadius: '10px',
                                }}
                              />
                              <CardContent
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  flexGrow: 1,
                                }}
                              >
                                {plan.SubscriptionAmount != null && (
                                  <Box mb={2}>
                                    <Typography variant="h3" color="text.primary">
                                      ${plan.SubscriptionAmount}
                                    </Typography>
                                  </Box>
                                )}

                                {plan.subscriptionDescription ? (
                                  <Typography variant="body2" align="center" sx={{ mb: 2 }}>
                                    {plan.subscriptionDescription}
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" align="center" sx={{ mb: 2 }}>
                                    Contact our sales representatives to tailor or customized a subscription plan that
                                    perfectly fits your business needs.
                                  </Typography>
                                )}

                                <Typography variant="subtitle1" align="center" sx={{ mb: 1 }}>
                                  {plan.subscriptionDays > 0 ? `${plan.subscriptionDays} Days` : 'Flexible Duration'}
                                </Typography>
                                <Typography variant="subtitle1" align="center">
                                  {plan.numberOfIp > 0
                                    ? `${plan.numberOfIp} Node${plan.numberOfIp !== 1 ? "'s" : ''}`
                                    : "Scalable Node's"}
                                </Typography>
                              </CardContent>
                              <CardActions>
                                <Button
                                  fullWidth
                                  variant="outlined"
                                  sx={{ mb: 1.5 }}
                                  onClick={() => handleSelectPlan(plan.id)}
                                >
                                  Buy Plan
                                </Button>
                              </CardActions>
                            </Card>
                          </motion.div>
                        </Grid>
                      );
                    }
                    return null;
                  })}
                </Grid>
              </Container>
              <br />
            </Container>
          </>
        )}
      </div>
    </div>
  );
}
