import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container, Typography, Card, CardContent, CardMedia, Box } from '@mui/material';
import SecondaryButton from './Assets/SecondaryButton';
import './Assets/testimonialPage.css';

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const testimonials = [
    {
      id: 1,
      text: 'Real-time alerts have saved us from potential downtime.',
      imageUrl: '/assets/images/avatars/male/avatar_9.jpg',
    },
    {
      id: 2,
      text: 'User-friendly interface; minimal learning curve.',
      imageUrl: '/assets/images/avatars/female/avatar_5.jpg',
    },
    {
      id: 3,
      text: 'In-depth metrics provide actionable insights.',
      imageUrl: '/assets/images/avatars/male/avatar_5.jpg',
    },
  ];

  return (
    <Container
      id="testimonial"
      disableGutters
      maxWidth="lg"
      sx={{
        px: {
          xs: 2,
          sm: 5,
          md: 3.5,
        },
        my: 5,
      }}
    >
      <div className="testimonial-container centered-container">
        <Box sx={{ marginBottom: 0 }}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: '600',
              fontSize: '26px',
              lineHeight: '48px',
              color: '#161414',
              textAlign: {
                xs: 'center',
              },
            }}
          >
            Reviews
          </Typography>
        </Box>
        <div className="testimonial-carousel">
          <Slider {...settings}>
            {testimonials.map((testimonial) => (
              <div key={testimonial.id} className="testimonial-item">
                <Card className="testimonial-card">
                  <div className="testimonial-image-container">
                    <CardMedia component="img" alt="User" src={testimonial.imageUrl} className="testimonial-image" />
                  </div>
                  <CardContent>
                    <div className="testimonial-text-container">
                      <Typography variant="h5" className="testimonial-name">
                        {testimonial.name}
                      </Typography>
                      <Typography variant="body1" className="testimonial-text">
                        {testimonial.text}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </div>
            ))}
          </Slider>
        </div>
        <div className="testimonial-navigation">
          <SecondaryButton text="See All Testimonials" to="/testimonial" />
        </div>
      </div>
    </Container>
  );
};

export default Testimonial;
