import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';

const TimeIntervalDropDown = ({ onSelect }) => {
  TimeIntervalDropDown.propTypes = {
    onSelect: PropTypes.func,
  };
  const timeIntervals = [
    { label: 'Last 5 minutes', value: 5 },
    { label: 'Last 15 minutes', value: 15 },
    { label: 'Last 30 minutes', value: 30 },
    { label: 'Last 1 hour', value: 60 },
    { label: 'Last 3 hours', value: 180 },
    { label: 'Last 6 hours', value: 360 },
    { label: 'Last 12 hours', value: 720 },
    { label: 'Last 24 hours', value: 1440 },
    { label: 'Last 2 days', value: 2880 },
    { label: 'Last 7 days', value: 10080 },
    { label: 'Last 30 days', value: 43200 },
  ];
  const [selectedInterval, setSelectedInterval] = useState(timeIntervals[0].value);

  const handleSelectChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10);
    setSelectedInterval(selectedValue);
    onSelect(selectedValue);
  };

  return (
    <div>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="time-interval-label">Time Interval</InputLabel>
        <Select
          labelId="time-interval-label"
          id="time-interval"
          value={selectedInterval} // Set the default value as an empty string
          onChange={handleSelectChange} // Handle the change event accordingly
          label="Time Interval"
        >
          {timeIntervals.map((interval) => (
            <MenuItem key={interval.value} value={interval.value}>
              {interval.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TimeIntervalDropDown;
