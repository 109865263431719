import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import DoneIcon from '@mui/icons-material/Done';
import InputAdornment from '@mui/material/InputAdornment';
import { toast } from 'react-toastify';

EditModal.propTypes = {
  isOpen: PropTypes.bool,
  user: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default function EditModal({ isOpen, user, onClose, onSave }) {
  const initialFormData = {
    fullName: '',
    username: '',
    email: '',
    phoneNumber: '',
  };

  const [formData, setFormData] = useState({ ...initialFormData, ...user });
  const [validationErrors, setValidationErrors] = useState({});
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [isVerifiedLoading, setIsVerifiedLoading] = useState(false);

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^(\+91|0)?[6789]\d{9}$/;
    return phoneRegex.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    const newValidationErrors = { ...validationErrors };

    if (!value.trim()) {
      newValidationErrors[id] = `${id} is required`;
    } else {
      delete newValidationErrors[id];
    }

    if (id === 'username') {
      if (/\s/.test(value)) {
        newValidationErrors[id] = 'Username should not contain spaces';
      } else if (value !== value.toLowerCase()) {
        newValidationErrors[id] = 'Username should be in all lowercase';
      }
    } else if (id === 'phoneNumber' && !isValidPhoneNumber(value)) {
      newValidationErrors[id] = 'Invalid phone number format';
    }
    if (id === 'fullName') {
      const lettersAndSpaces = value.replace(/[^A-Za-z ]/g, '');
      const letterCount = lettersAndSpaces.replace(/ /g, '').length;
      if (letterCount < 3 || lettersAndSpaces !== value) {
        newValidationErrors[id] = 'Full name should contain at least three letters and no numbers or unknown words';
      }
    }
    if (id === 'email') {
      if (!value.trim()) {
        newValidationErrors[id] = 'Email is required';
      } else if (!isValidEmail(value)) {
        newValidationErrors[id] = 'Invalid email format';
      }
    }
    setValidationErrors(newValidationErrors);

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.email !== user.email) {
      if (!isVerified) {
        toast.error('Email must be verified before submitting the form');
        return;
      }
    }

    if (Object.keys(validationErrors).length === 0) {
      onSave(formData, e);
    }
  };

  const sendOTP = async () => {
    try {
      setIsVerifiedLoading(true);
      // if (isVerified) {
      //   toast.info('Email is already verified');
      //   return;
      // }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/sendEmailVerifactionMail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailId: formData.email }),
      });
      const data = await response.json();

      if (data.sussess === true) {
        toast.success(data.message, {
          autoClose: 2000,
        });
        setOtpDialogOpen(true);
      } else {
        console.error('Failed to send OTP');
        toast.error(data.message || 'Failed to send OTP');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error sending OTP. Please try again later.');
    } finally {
      setIsVerifiedLoading(false);
    }
  };

  const verifyOTP = async () => {
    try {
      setIsVerifiedLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/verifyOTP`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ otp: verificationCode }),
      });

      if (response.status === 200) {
        toast.success('OTP Verified!', {
          autoClose: 2000,
        });
        setIsVerified(true);
        setOtpDialogOpen(false);
      } else {
        toast.error('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      toast.error('An error occurred while verifying OTP. Please try again later.');
    } finally {
      setIsVerifiedLoading(false);
    }
  };
  const validateOTP = () => {
    if (!verificationCode) {
      setOtpError('OTP is required');
    } else if (!/^\d{4}$/.test(verificationCode)) {
      setOtpError('OTP must be exactly four digits');
    } else {
      setOtpError('');
      verifyOTP();
    }
  };

  const handleCloseOtpDialog = () => {
    setOtpDialogOpen(false);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h5">Edit User</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              id="fullName"
              value={formData.fullName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!validationErrors.fullName}
              helperText={validationErrors.fullName}
            />

            <TextField
              label="Email"
              id="email"
              value={formData.email}
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!validationErrors.email}
              helperText={validationErrors.email}
              InputProps={{
                endAdornment: isVerified ? (
                  <InputAdornment position="end">
                    <DoneIcon style={{ color: 'green' }} />
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    {isVerifiedLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button type="button" onClick={sendOTP}>
                        Verify
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
            />

            <TextField
              type="tel"
              label="Phone Number"
              id="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!validationErrors.phoneNumber}
              helperText={validationErrors.phoneNumber}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button type="submit" variant="contained" color="primary" sx={{ margin: '0 4px' }}>
                Save
              </Button>
              <Button onClick={onClose} variant="contained" color="secondary" sx={{ margin: '0 4px' }}>
                Close
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <Dialog open={otpDialogOpen} onClose={handleCloseOtpDialog}>
        <DialogTitle>OTP Verification</DialogTitle>
        <DialogContent>
          <TextField
            label="Enter OTP"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            error={!!otpError} // Set the error state
            helperText={otpError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOtpDialog}>Cancel</Button>
          {isVerifiedLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={validateOTP} color="primary">
              Verify
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
