import { useEffect } from 'react';
import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import DashboardLayout from '../layouts/dashboard';
import UserPage from '../pages/UserPage';
import LoginPage from '../pages/LoginPage';
import Page404 from '../pages/Page404';
import DashboardAppPage from '../pages/DashboardAppPage';
import SignUpPage from '../pages/SignUpPage';
import LandingRoute from '../landingPage/LandingRoute';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import About from '../landingPage/page/About';
import FAQPage from '../landingPage/page/FAQPage';
import Documentation from '../landingPage/page/Documentation/Document';
import DocumentDetailPage from '../landingPage/page/DocumentDetailPage';
import PrivacyPolicy from '../landingPage/page/PrivacyPolicy';
import ServiceDeliveryPolicy from '../landingPage/page/ServiceDeliveryPolicy';
import TermsAndConditions from '../landingPage/page/TermsAndConditions';
import LogOut from '../pages/LogOut';
import UpdatePasswordPage from '../pages/UpdatePasswordPage';
import MonitorPage from '../pages/MonitorPage';
import SubscriptionPage from '../sections/@dashboard/subscription/SubscriptionPage';
import ProfilePage from '../pages/ProfilePage';
import WalletPage from '../pages/WalletPage';
import MonitorGraphPage from '../pages/MonitorGraphPage';
import TestimonialPage from '../landingPage/page/TestimonialPage';
import SubscriptionPricingPage from '../pages/SubscriptionPricingPlan';
import ContactSales from '../pages/ContactSales';
import NewsUpdate from '../pages/NewsUpdate';
import { SuccessPage } from '../sections/@dashboard/razorpayPayment';
import DocumentationPage from '../pages/DocumentationPage';
import { getUserRole, isLoggedIn } from './authUtils';

// ----------------------------------------------------------------------

export default function RouterConfig() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn() && window.location.pathname.startsWith('/dashboard')) {
      navigate('/login');
    }
  }, [navigate]);

  const adminRoutes = [
    {
      path: '/',
      element: <LandingRoute />,
      children: [{ element: <Navigate to="/monitoring" />, index: true }],
    },
    {
      path: '/dashboard',
      element: isLoggedIn() ? <DashboardLayout isAdmin /> : <Navigate to="/login" />,
      children: [
        { path: '', element: <Navigate to="/dashboard/admin" /> },
        { path: 'admin', element: <UserPage /> },
        { path: 'users', element: <UserPage /> },
        { path: 'contact-sales', element: <ContactSales /> },
        { path: 'contact-sales/:userId', element: <ContactSales /> },
        { path: 'news-update', element: <NewsUpdate /> },
        { path: 'profile', element: <ProfilePage /> },
      ],
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/logout',
      element: <LogOut />,
    },
    {
      path: '/signup',
      element: <SignUpPage />,
    },
    {
      path: '*',
      element: <Page404 />,
    },
    {
      path: '/forgotPassword',
      element: <ForgotPasswordPage />,
    },
    {
      path: '/updatePassword',
      element: <UpdatePasswordPage />,
    },
    {
      path: '/about',
      element: <About />,
    },
    {
      path: '/faq',
      element: <FAQPage />,
    },
    {
      path: '/document',
      element: <Documentation />,
    },
    {
      path: '/testimonial',
      element: <TestimonialPage />,
    },
    {
      path: '/documentDetail',
      element: <DocumentDetailPage />,
    },
    {
      path: '/privacyPolicy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/serviceDeliveryPolicy',
      element: <ServiceDeliveryPolicy />,
    },
    {
      path: '/termsAndConditions',
      element: <TermsAndConditions />,
    },
  ];

  const userRoutes = [
    {
      path: '/',
      element: <LandingRoute />,
      children: [{ element: <Navigate to="/monitoring" />, index: true }],
    },
    {
      path: '/dashboard',
      element: isLoggedIn() ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'monitors', element: <MonitorPage /> },
        { path: 'alerts', element: <DashboardAppPage /> },
        { path: 'wallet', element: <WalletPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'subscription', element: <SubscriptionPricingPage /> },
        {
          path: '/dashboard/documentation',
          element: <DocumentationPage />,
        },
        {
          path: '/dashboard/monitorGraph/:userName/:dashboardName/:ipAddress/:portAddress',
          element: <MonitorGraphPage />,
        },
        {
          path: '/dashboard/subscription/:_id',
          element: <SubscriptionPricingPage />,
        },
        {
          path: '/dashboard/subscription/:_id/:subscriptionName/:subscriptionAmount',
          element: <SubscriptionPricingPage />,
        },
        {
          path: '/dashboard/wallet/payment',
          element: <WalletPage />,
        },
        {
          path: '/dashboard/wallet/success',
          element: <SuccessPage />,
        },
      ],
    },
    {
      path: '/dashboard/subscription',
      element: <SubscriptionPage />,
    },
    {
      path: '/dashboard/monitorGraph/:userName/:dashboardName/:ipAddress/:portAddress',
      element: <MonitorGraphPage />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/signup',
      element: <SignUpPage />,
    },
    {
      path: '/logout',
      element: <LogOut />,
    },
    {
      path: '*',
      element: <Page404 />,
    },
    {
      path: '/forgotPassword',
      element: <ForgotPasswordPage />,
    },
    {
      path: '/updatePassword',
      element: <UpdatePasswordPage />,
    },
    {
      path: '/about',
      element: <About />,
    },
    {
      path: '/faq',
      element: <FAQPage />,
    },
    {
      path: '/document',
      element: <Documentation />,
    },
    {
      path: '/testimonial',
      element: <TestimonialPage />,
    },
    {
      path: '/documentDetail',
      element: <DocumentDetailPage />,
    },
    {
      path: '/privacyPolicy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/serviceDeliveryPolicy',
      element: <ServiceDeliveryPolicy />,
    },
    {
      path: '/termsAndConditions',
      element: <TermsAndConditions />,
    },
  ];

  const routes = useRoutes(getUserRole() === 'admin' ? adminRoutes : userRoutes);

  return (
    <>
      <Helmet>
        <title>WatchNod: Comprehensive Server Health & Performance Monitoring</title>
        <meta name="google-site-verification" content="ugYgzsB80lsq4-0bYyoTSa3p4vy2CHFgpG51ty883S4" />
        <meta
          name="description"
          content="WatchNod offers peak performance with our server monitoring solution. Enjoy real-time alerts, a user-friendly dashboard, 24/7 support, and customizable plans."
          />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="WatchNod - Real-Time Server Monitoring" />
        <meta
          property="og:description"
          content="Enhance your server performance with WatchNod, the premier solution for reliable server monitoring and maximum uptime."
        />
        <meta
          property="og:keywords"
          content="watchnod, network monitoring, server monitoring, performance optimization, IT monitoring, Real-time Monitoring, Server Health Metrics, Alerts and Notifications and Historical Data and Reports"
        />
        <meta property="og:image" content="https://www.watchnod.com/path/to/image.jpg" />
        <meta property="og:url" content="https://www.watchnod.com/" />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: 'https://www.watchnod.com/',
            name: 'WatchNod',
            description: 'WatchNod provides powerful tools for effective network monitoring and performance metrics.',
            potentialAction: {
              '@type': 'SearchAction',
              target: 'https://www.watchnod.com/search?q={search_term_string}',
              'query-input': 'required name=search_term_string',
            },
            mainEntity: {
              '@type': 'WebPage',
              '@id': 'https://www.watchnod.com/',
              name: 'Server Monitoring Solutions',
              description:
                'Explore our comprehensive server monitoring solutions designed to optimize performance and ensure maximum uptime.',
            },
            subEntity: [
              {
                '@type': 'WebPage',
                '@id': 'https://www.watchnod.com',
                name: 'Real-Time Monitoring',
                description: 'Explore our real-time monitoring services to ensure seamless connectivity.',
              },
              {
                '@type': 'WebPage',
                '@id': 'https://www.watchnod.com',
                name: 'Server Health Metrics',
                description: 'Get insights into your server health with detailed metrics and reports.',
              },
            ],
          })}
        </script>
      </Helmet>
      {routes}
    </>
  );
}
