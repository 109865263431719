import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  Paper,
  TextField,
  Card,
  CardContent,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Grid,
} from '@mui/material';
import './Wallet.css';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Scrollbar from '../../../components/scrollbar/Scrollbar';

const StyledCard = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

const Wallet = (props) => {
  const userId = props.admin ? props.userId : localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  const [data, setData] = useState([]);
  const [totalBalance, setTotalBalance] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState('desc');
  const [filterDate, setFilterDate] = useState({ startDate: '', endDate: '' });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const filterUser = '';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const cleanToken = token.replace(/"/g, '');
        const config = {
          headers: {
            Authorization: `Bearer ${cleanToken}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getHistoryOfWallet?userId=${userId}`,
          config
        );
        setData(response.data.data);
      } catch (error) {
        console.error('Error fetching wallet history data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchTotalBalance = async () => {
      setIsLoading(true);
      try {
        const cleanToken = token.replace(/"/g, '');
        const config = {
          headers: {
            Authorization: `Bearer ${cleanToken}`,
          },
        };
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getWalletBalance?userId=${userId}`, config);
        setTotalBalance(response.data);
      } catch (error) {
        console.error('Error fetching total balance data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    fetchTotalBalance();
  }, [userId, token, props.refresh]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toISOString().split('T')[0];
  };

  const getTextColorClass = (transactionType) => {
    switch (transactionType) {
      case 'credit':
        return 'green-text';
      case 'debit':
        return 'red-text';
      default:
        return '';
    }
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleAddBalanceClick = () => {
    navigate('/dashboard/wallet/payment');
    props.openForm();
  };

  const timeIntervals = [
    { label: 'All day', value: 0 },
    { label: 'Last 1 day', value: 1 },
    { label: 'Last 7 days', value: 7 },
    { label: 'Last 15 days', value: 15 },
    { label: 'Last 30 days', value: 30 },
  ];
  const [selectedInterval, setSelectedInterval] = useState(0);

  const handleSelectChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10);
    setSelectedInterval(selectedValue);
    setFilterDate(getFormattedDate(selectedValue));
  };

  const getFormattedDate = (interval) => {
    if (interval === 0) return { startDate: '', endDate: '' };
    const currentDate = new Date();
    const endDate = currentDate.toISOString().split('T')[0];
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - interval + 1);
    const startDateFormatted = startDate.toISOString().split('T')[0];
    return { startDate: startDateFormatted, endDate };
  };

  const filteredData = data.filter((item) => {
    const createdAtDate = formatDate(item.createdAt);
    const { startDate, endDate } = filterDate;
    const dateMatch = startDate && endDate ? createdAtDate >= startDate && createdAtDate <= endDate : true;
    const userMatch = filterUser ? item.userId === filterUser : true;
    return dateMatch && userMatch;
  });

  const sortedData = filteredData.slice().sort((a, b) => {
    const aValue = String(a[orderBy]);
    const bValue = String(b[orderBy]);

    if (order === 'asc') {
      return aValue.localeCompare(bValue);
    }

    return bValue.localeCompare(aValue);
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const scrollableTableStyle = {
    maxHeight: '400px',
    overflowY: 'auto',
  };

  return (
    <StyledCard>
      <Card>
        <CardContent style={{ textAlign: 'left' }}>
          <Paper className="transaction-history">
            <Typography variant="h6" style={{ paddingBottom: '10px' }}>
              Transaction History
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  id="startDate"
                  label="From"
                  type="date"
                  value={filterDate.startDate}
                  onChange={(e) => setFilterDate({ ...filterDate, startDate: e.target.value })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  id="endDate"
                  label="To"
                  type="date"
                  value={filterDate.endDate}
                  onChange={(e) => setFilterDate({ ...filterDate, endDate: e.target.value })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  inputProps={{
                    min: filterDate.startDate,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="time-interval-label">Date Interval</InputLabel>
                  <Select
                    labelId="time-interval-label"
                    id="time-interval"
                    value={selectedInterval}
                    onChange={handleSelectChange}
                    label="Time Interval"
                    fullWidth
                  >
                    {timeIntervals.map((interval) => (
                      <MenuItem key={interval.value} value={interval.value}>
                        {interval.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {!props.admin && (
                <Grid item xs={12} sm={6} md={6}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Typography
                      variant="h6"
                      className="total-balance"
                      style={{ marginRight: '10px', fontSize: '1.4em' }}
                    >
                      Wallet Balance:
                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <span
                            className={`total-balance-text ${getTextColorClass(totalBalance.transactionType)}`}
                            style={{ fontWeight: 'bold' }}
                          >
                            ${totalBalance.balance}
                          </span>
                        </>
                      )}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleAddBalanceClick}>
                      Add Balance
                    </Button>
                  </div>
                </Grid>
              )}
            </Grid>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Scrollbar style={scrollableTableStyle}>
                    <Table id="WalletDataTable" className="standard-table">
                      <TableHead>
                        <TableRow className="transaction-type-header">
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === 'createdAt'}
                              direction={orderBy === 'createdAt' ? order : 'asc'}
                              onClick={() => handleSort('createdAt')}
                            >
                              Created At
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === 'balance'}
                              direction={orderBy === 'balance' ? order : 'asc'}
                              onClick={() => handleSort('balance')}
                            >
                              Balance
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === 'transactionType'}
                              direction={orderBy === 'transactionType' ? order : 'asc'}
                              onClick={() => handleSort('transactionType')}
                            >
                              Transaction Type
                            </TableSortLabel>
                          </TableCell>

                          <TableCell>
                            <TableSortLabel
                              active={orderBy === 'transactionReason'}
                              direction={orderBy === 'transactionReason' ? order : 'asc'}
                              onClick={() => handleSort('transactionReason')}
                            >
                              Transaction Reason
                            </TableSortLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{formatDate(item.createdAt)}</TableCell>
                            <TableCell>
                              <span
                                className={`balance-cell ${getTextColorClass(item.transactionType)}`}
                                style={{ fontWeight: 'bold' }}
                              >
                                ${item.balance}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                className={`transaction-type-cell ${getTextColorClass(item.transactionType)}`}
                                style={{ textTransform: 'capitalize' }}
                              >
                                {item.transactionType}
                              </span>
                            </TableCell>
                            <TableCell>{item.transactionReason}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </>
              )}
            </div>

            <TablePagination
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </CardContent>
      </Card>
    </StyledCard>
  );
};

Wallet.propTypes = {
  openForm: PropTypes.func,
  refresh: PropTypes.bool,
  admin: PropTypes.bool,
  userId: PropTypes.func,
};

export default Wallet;
