import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Grid, Typography, Paper, Button } from '@mui/material';
import PropTypes from 'prop-types';

const CustomTimeRangePopup = ({ open, onClose, onApply }) => {
  CustomTimeRangePopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
  };
  const [customTimeRange, setCustomTimeRange] = useState({
    start: '',
    end: '',
  });

  const [error, setError] = useState('');

  const handleCustomTimeChange = (event) => {
    const { name, value } = event.target;
    setCustomTimeRange((prevRange) => ({ ...prevRange, [name]: value }));
  };

  const handleClear = () => {
    setCustomTimeRange({ start: '', end: '' });
    setError('');
  };

  const handleCustomApply = () => {
    const startEpoch = Date.parse(customTimeRange.start);
    const endEpoch = Date.parse(customTimeRange.end);
    const currentEpoch = new Date().getTime();
    if (!customTimeRange.start || !customTimeRange.end) {
      setError('Please select both start and end times.');
    } else if (startEpoch > currentEpoch || endEpoch > currentEpoch) {
      setError('Please select a time range that is before the current time.');
    } else if (startEpoch < endEpoch) {
      onApply(startEpoch, endEpoch);
      onClose();
    } else {
      setError('Invalid time range. End time must be greater than start time.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Custom Time Range</DialogTitle>
      <DialogContent component={Paper} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Start Time
            </Typography>
            <TextField
              type="datetime-local"
              name="start"
              value={customTimeRange.start}
              onChange={handleCustomTimeChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              End Time
            </Typography>
            <TextField
              type="datetime-local"
              name="end"
              value={customTimeRange.end}
              onChange={handleCustomTimeChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <br />

        {error && (
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={4}>
            <Button variant="contained" color="primary" onClick={handleCustomApply} fullWidth>
              Apply
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button variant="outlined" color="secondary" onClick={handleClear} fullWidth>
              Clear
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button variant="outlined" color="secondary" onClick={onClose} fullWidth>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CustomTimeRangePopup;
