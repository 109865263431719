import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack } from '@mui/material';
import DocumentDetail from '../landingPage/page/Documentation/DocumentDetail';

export default function DocumentationPage() {
  return (
    <>
      <Helmet>
        <title>Documentation| WatchNod</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            WatchNod Documentation
          </Typography>
        </Stack>
        <DocumentDetail />
      </Container>
    </>
  );
}
