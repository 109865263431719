import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';

function UpdatePassword() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [currentPassword] = useState('');

  // useEffect(() => {
  //   async function fetchCurrentPassword() {
  //     try {
  //       const passwordResponse = await fetch(`${process.env.REACT_APP_API_URL}/getCurrentPassword`, {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       });

  //       if (!passwordResponse.ok) {
  //         throw new Error('Failed to fetch current password.');
  //       }

  //       const { currentPassword } = await passwordResponse.json();
  //       setCurrentPassword(currentPassword);
  //     } catch (error) {
  //       setError('An error occurred while fetching the current password.');
  //     }
  //   }

  //   fetchCurrentPassword();
  // }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setError('');
    setSuccessMessage('');

    if (formData.newPassword !== formData.confirmPassword) {
      setError('New password and confirm password do not match.');
      return;
    }

    if (formData.newPassword === currentPassword) {
      setError('New password must be different from the current password.');
      return;
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get('token');

    if (token) {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/updatePassword`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        const data = await response.json();
        if (data.success === true) {
          setSuccessMessage('Password updated successfully.');
          toast.success(data.message);
          setTimeout(() => {
            navigate('/Login', { replace: true });
          }, 2000);
        } else {
          toast.error(data.message);
          setError(data.message);
        }
      } catch (error) {
        setError('An error occurred while updating the password.');
      } finally {
        setIsLoading(false);
      }
    } else {
      setError('Token not found in the URL.');
      setIsLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="newPassword"
          label="New Password"
          type="password"
          value={formData.newPassword}
          onChange={handleInputChange}
          fullWidth
        />
        <TextField
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          value={formData.confirmPassword}
          onChange={handleInputChange}
          fullWidth
        />
      </Stack>

      {error && (
        <Typography variant="body2" color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {successMessage && (
        <Typography variant="body2" color="success" sx={{ mb: 2 }}>
          {successMessage}
        </Typography>
      )}

      <br />
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0rem' }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              fullWidth
              size="large"
              type="button"
              variant="contained"
              onClick={handleSubmit}
              loading={isLoading.toString()}
            >
              Submit
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default UpdatePassword;
