import React from 'react';
import { Typography } from '@mui/material';
import {
  Section,
  SectionTitle,
  StyledTitle,
  StyledDescription,
  StyledNotes,
  DarkBlueColor,
  StyledParagraph,
} from '../DocumentStyles';

const Policy = () => (
  <Section id="policy" style={{ padding: '3rem 0' }} >
    <SectionTitle>
      <DarkBlueColor>
        <u> Policy</u>
      </DarkBlueColor>
    </SectionTitle>
    <StyledTitle>Data Retention Policy</StyledTitle>
    <h3>1: Expired Plan Data Deletion</h3>
    <StyledDescription>
      <StyledParagraph>
        Upon the expiration of a user's subscription plan, it's important to note our data retention policy:
      </StyledParagraph>

      <h3>Retention Period:</h3>
      <StyledParagraph>
        All data associated with monitoring addresses in the expired plan will be retained for a period of 7 days after
        the plan's expiration date.
      </StyledParagraph>

      <h3>Data Deletion:</h3>
      <StyledParagraph>
        After the 7-day retention period, all data related to the expired plan will be permanently deleted from our
        system.
      </StyledParagraph>

      <h3>No Retrieval After Deletion:</h3>
      <StyledParagraph>Once the data is deleted, it cannot be retrieved in the future.</StyledParagraph>

      <StyledNotes>
        <strong>Note :- </strong>Ensure that you have read and understood our Data Retention Policy. By using our
        monitoring solution, you agree to abide by these terms.
      </StyledNotes>
    </StyledDescription>
    <h3>2: Manual Deletion of Monitoring Addresses</h3>
    <StyledDescription>
      <StyledParagraph>
        Users have the capability to manually delete monitoring addresses. It's important to understand the implications
        of this action:
      </StyledParagraph>

      <h3>Data Deletion:</h3>
      <StyledParagraph>
        When a monitoring address is manually deleted, all associated data, including historical metrics and monitoring
        logs, will be permanently and irreversibly deleted from our system.
      </StyledParagraph>

      <h3>No Retrieval After Deletion:</h3>
      <StyledParagraph>
        Once the monitoring address is deleted, the data cannot be recovered. Please exercise caution and ensure that
        the deletion is intentional.
      </StyledParagraph>

      <h3>Impact on Reports and Analytics:</h3>
      <StyledParagraph>
        Deleting a monitoring address may impact historical reports and analytics that involve the deleted data. Ensure
        you consider this before proceeding with deletion.
      </StyledParagraph>
      <StyledNotes>
        <strong>Note :- </strong> Users are encouraged to carefully review their monitoring addresses and consider
        exporting any necessary data before initiating manual deletions.
      </StyledNotes>
    </StyledDescription>

    <StyledTitle>
      <u>Usage Policy</u>
    </StyledTitle>
    <Typography variant="h5">1. Unauthorized Activities:</Typography>
    <StyledDescription>
      <h3>Detection and Blocking:</h3>
      <StyledParagraph>
        The monitoring solution reserves the right to detect and block users engaging in any unauthorized or unwanted
        activities within the platform.
      </StyledParagraph>

      <h3>Full Blocking Rights:</h3>
      <StyledParagraph>
        In the event of detected unwanted activity, the monitoring solution reserves the right to block the user's
        access with immediate effect.
      </StyledParagraph>
    </StyledDescription>
    <h3>2. AutoPay Authorization:</h3>
    <StyledDescription>
      <h3>Auto Deduction from Wallet:</h3>
      <StyledParagraph>
        Users explicitly authorize the monitoring solution to automatically deduct funds from their wallet when AutoPay
        is enabled.
      </StyledParagraph>

      <h3>Financial Transactions Transparency:</h3>
      <StyledParagraph>
        Users can manage their AutoPay settings to control the automatic deduction of funds, providing transparency and
        control over financial transactions.
      </StyledParagraph>
    </StyledDescription>
    <h3>3. Subscription Deletion Policy:</h3>
    <StyledDescription>
      <h3>Permanent Deletion Upon Cancellation:</h3>
      <StyledParagraph>
        Once a subscription is canceled by the user or terminated by the monitoring solution, it will be permanently
        deleted from the system without the option for reinstatement.
      </StyledParagraph>

      <h3>Non-Refundable:</h3>
      <StyledParagraph>
        Upon deletion, subscriptions are considered non-refundable, ensuring clarity regarding financial transactions
        and account management policies.
      </StyledParagraph>
    </StyledDescription>
  </Section>
);

export default Policy;
