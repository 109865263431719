import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Link,
  Stack,
  TextField,
  Checkbox,
  Button,
  Typography,
  IconButton,
  Grid,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function CompanySignUpForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    referralCode: '',
    agreeTerms: false,
  });

  const [errors, setErrors] = useState({
    fullName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    referralCode: '',
    agreeTerms: false,
    message: '',
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handlePasswordVisibilityToggle = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrors({});

    const validationErrors = {};

    if (!formData.fullName.trim()) {
      validationErrors.fullName = 'Full name is required';
    }
    if (!formData.username.trim()) {
      validationErrors.username = 'Username is required';
    }
    if (!formData.email.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = 'Invalid email format';
    }
    if (!formData.password) {
      validationErrors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      validationErrors.password = 'Password must be at least 8 characters';
    }
    if (formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = 'Passwords do not match';
    }
    if (!formData.phoneNumber.trim()) {
      validationErrors.phoneNumber = 'Phone number is required';
    } else if (!isValidPhoneNumber(formData.phoneNumber)) {
      validationErrors.phoneNumber = 'Invalid phone number format';
    }
    if (formData.referralCode.trim()) {
      if (!isValidReferralCode(formData.referralCode)) {
        validationErrors.referralCode = 'Invalid referral code';
      }
    }

    if (!formData.agreeTerms) {
      validationErrors.agreeTerms = 'You must agree to the terms and conditions';
    }

    if (Object.keys(validationErrors).length === 0) {
      const payload = {
        fullName: formData.fullName,
        userName: formData.username,
        email: formData.email,
        password: formData.password,
        phoneNumber: formData.phoneNumber,
        referralCode: formData.referralCode,
      };

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, payload);
        setIsLoading(true);
        if (response.status === 200) {
          toast.success('Congratulations you are successfully registered.', {
            autoClose: 2000,
          });

          navigate('/login');
        } else {
          console.error('Registration failed:', response.data);
          setErrors({ ...errors, message: response.data.message });
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message, {
            autoClose: 2000,
          });
        } else if (error.request) {
          setErrors({ ...errors, message: 'No response from the server' });
        } else {
          setErrors({
            ...errors,
            message: 'An error occurred while sending the request',
          });
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^(\+91|0)?[6789]\d{9}$/;
    return phoneRegex.test(phoneNumber);
  };

  function isValidReferralCode(referralCode) {
    return referralCode.length === 8;
  }

  return (
    <form onSubmit={handleSubmit} disabled={isLoading}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="fullName"
            label="Full Name"
            variant="outlined"
            fullWidth
            value={formData.fullName}
            onChange={handleChange}
            required
            error={Boolean(errors.fullName)}
            helperText={errors.fullName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="username"
            label="Username"
            variant="outlined"
            fullWidth
            value={formData.username}
            onChange={handleChange}
            required
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="email"
            label="Email Address"
            variant="outlined"
            fullWidth
            value={formData.email}
            onChange={handleChange}
            required
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="password"
            label="Password"
            variant="outlined"
            fullWidth
            type={passwordVisible ? 'text' : 'password'}
            value={formData.password}
            onChange={handleChange}
            required
            error={Boolean(errors.password)}
            helperText={errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordVisibilityToggle} edge="end">
                    {passwordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="confirmPassword"
            label="Confirm Password"
            variant="outlined"
            fullWidth
            type={passwordVisible ? 'text' : 'password'}
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="phoneNumber"
            label="Phone Number"
            variant="outlined"
            fullWidth
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            error={Boolean(errors.phoneNumber)}
            helperText={errors.phoneNumber}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="referralCode"
            label="Referral Code"
            variant="outlined"
            fullWidth
            value={formData.referralCode}
            onChange={handleChange}
            error={Boolean(errors.referralCode)}
            helperText={errors.referralCode}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <Checkbox name="agreeTerms" checked={formData.agreeTerms} onChange={handleChange} color="primary" />
            <Typography variant="body2">
              I agree to the{' '}
              <Link href="#" target="_blank">
                Terms and Conditions
              </Link>
            </Typography>
          </Stack>
          <Typography variant="body2" color="error">
            {errors.agreeTerms}
          </Typography>
        </Grid>
        {errors.message && (
          <Typography variant="body2" color="error" sx={{ mb: 2 }}>
            {errors.message}
          </Typography>
        )}
        <Grid item xs={12}>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Button type="submit" variant="contained" color="primary" fullWidth disabled={isLoading}>
                  Sign Up
                </Button>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
