import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import faqData from './faqData.json';

const Container = styled('section')(({ theme }) => ({
  position: 'relative',
  zIndex: 0,
  padding: '30px 0',
  margin: '0 auto',
  width: '100%',
  boxSizing: 'border-box',
  backgroundSize: 'cover',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'inherit',
    opacity: 0.8,
    zIndex: -1,
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '60%',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px 0',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '2.5rem',
  fontWeight: 700,
  marginBottom: '40px',
  color: '#333',
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
    marginBottom: '30px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    marginBottom: '20px',
  },
}));

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: '#fff',
  marginBottom: '20px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '10px',
  },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  borderRadius: '8px',
  padding: '16px',
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: '16px',
  color: '#555',
  fontSize: '1.2rem',
  lineHeight: 1.6,
  textAlign: 'justify',
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    fontSize: '1rem',
  },
}));

const AccordionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: '#333',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
}));

const SubDescription = styled('div')({
  marginTop: '8px',
  marginLeft: '20px',
  '& ul': {
    paddingLeft: '20px',
    margin: 0,
  },
  '& li': {
    marginBottom: '6px',
  },
});

const AdditionalInfo = styled(Typography)({
  marginTop: '16px',
  fontSize: '1.2rem',
  color: '#777',
});

const FAQDoc = ({ data = faqData }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      <Title variant="h3">{data.header}</Title>
      {data.content.map((item, index) => (
        <CustomAccordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <AccordionTitle>{item.title}</AccordionTitle>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            <Typography variant="h6">{item.description}</Typography>
            {item.subDescriptions && (
              <SubDescription>
                <ul>
                  {item.subDescriptions.map((desc, subIndex) => (
                    <li key={subIndex}>{desc}</li>
                  ))}
                </ul>
              </SubDescription>
            )}
            {item.additionalInfo && <AdditionalInfo>{item.additionalInfo}</AdditionalInfo>}
          </CustomAccordionDetails>
        </CustomAccordion>
      ))}
    </Container>
  );
};

FAQDoc.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      subDescriptions: PropTypes.arrayOf(PropTypes.string),
      additionalInfo: PropTypes.string,
    })).isRequired,
  }),
};

FAQDoc.defaultProps = {
  data: faqData, // Default data if `data` prop is not provided
};

export default FAQDoc;
