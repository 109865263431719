import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Container, Typography, Paper, Grid, Card, CircularProgress, Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import AddBalanceIcon from '@mui/icons-material/AddCircleOutline';
import WalletHistoryIcon from '@mui/icons-material/History';
import ReactConfetti from 'react-confetti';
import styled from 'styled-components';
import beautifulImage from './images/images.jpg';
import ConfirmationDialog from '../../../layouts/shared/ConfirmationDialog';
import { Payment } from '../razorpayPayment';

const StyledCard = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

PurchaseFormPage.propTypes = {
  closePurchaseForm: PropTypes.func.isRequired,
};

function PurchaseFormPage(props) {
  const [walletAmount, setWalletAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfettiActive, setConfettiActive] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const { _id, subscriptionName, subscriptionAmount } = useParams();
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getWalletBalance?userId=${userId}`, config);

        const walletAmount = response.data.balance;
        setWalletAmount(walletAmount);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, [userId, token, _id, subscriptionName, subscriptionAmount]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirmBuy = async (e) => {
    setIsConfirmationDialogOpen(false);
    e.preventDefault();
    setIsLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      if (_id) {
        const updatedFormData = {
          userId,
          subsciptionPlanId: _id,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/purchaseSubscription`,
          updatedFormData,
          config
        );
        toast.success(response.data.message);
        setConfettiActive(true);

        setTimeout(() => {
          setConfettiActive(false);
          props.closePurchaseForm();
        }, 3000);
      } else {
        toast.error('Invalid URL format.');
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleCancelBuy = () => {
    setIsConfirmationDialogOpen(false);
  };

  return (
    <Card>
      <div style={{ backgroundColor: 'blue', padding: '1rem', borderRadius: '5px' }}>
        <Typography variant="h6" color="white">
          Wallet Balance
        </Typography>
        <Typography variant="h4" style={{ color: 'white', marginLeft: '10px' }}>
          {walletAmount !== null ? `$${walletAmount.toFixed(2)}` : 'N/A'}
        </Typography>
      </div>

      <Container maxWidth="sm">
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '10px',
            marginBottom: '10px',
            marginTop: '20px',
          }}
        >
          <div
            role="button"
            tabIndex={0}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f0f0f0',
              padding: '20px',
              borderRadius: '10px',
              transition: 'background-color 0.3s ease, transform 0.3s ease',
              cursor: 'pointer',
            }}
            onClick={openForm}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                openForm();
              }
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#c0c0c0';
              e.currentTarget.style.transform = 'translateY(-5px)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#f0f0f0';
              e.currentTarget.style.transform = 'translateY(0)';
            }}
          >
            <Typography>
              <AddBalanceIcon style={{ color: 'primary', marginRight: '5px' }} />
            </Typography>
            <Typography variant="h6" style={{ color: 'primary' }}>
              Add Balance
            </Typography>
          </div>
          <Dialog open={isFormOpen} onClose={closeForm}>
            <StyledCard>
              <Payment open={openForm} closeForm={closeForm} />
            </StyledCard>
          </Dialog>

          <a href="/dashboard/wallet" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f0f0f0',
                padding: '20px',
                borderRadius: '10px',
                transition: 'background-color 0.3s ease, transform 0.3s ease',
                cursor: 'pointer',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#c0c0c0';
                e.currentTarget.style.transform = 'translateY(-5px)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '#f0f0f0';
                e.currentTarget.style.transform = 'translateY(0)';
              }}
            >
              <Typography>
                <WalletHistoryIcon style={{ color: 'primary', marginRight: '5px' }} />
              </Typography>
              <Typography variant="h6" style={{ color: 'primary' }}>
                Wallet History
              </Typography>
            </div>
          </a>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <img src={beautifulImage} alt="Beautiful landscape" style={{ maxWidth: '100%', height: 'auto' }} />
        </div>

        <Paper elevation={3} style={{ padding: '2rem', marginBottom: '10px' }}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Subscription Name</Typography>
                <TextField
                  label=""
                  name="subscriptionName"
                  type="text"
                  fullWidth
                  value={subscriptionName || ''}
                  InputProps={{
                    readOnly: true,
                    style: { backgroundColor: '#f0f0f0' },
                  }}
                  inputProps={{
                    style: { cursor: 'not-allowed' },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Amount ($)</Typography>
                <TextField
                  label=""
                  name="subscriptionAmount"
                  type="number"
                  fullWidth
                  value={subscriptionAmount || ''}
                  InputProps={{
                    readOnly: true,
                    style: { backgroundColor: '#f0f0f0' },
                  }}
                  inputProps={{
                    style: { cursor: 'not-allowed' },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Buy
                      </Button>

                      <Button
                        variant="contained"
                        onClick={() => props.closePurchaseForm()}
                        style={{ marginLeft: '1rem' }}
                      >
                        Close
                      </Button>
                    </>
                  )}
                </div>
                <ConfirmationDialog
                  isOpen={isConfirmationDialogOpen}
                  onClose={handleCancelBuy}
                  onConfirm={handleConfirmBuy}
                  message="Are you sure you want to Buy ?"
                />
                {isConfettiActive && <ReactConfetti tweenDuration={3000} />}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </Card>
  );
}

export default PurchaseFormPage;
