import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, CssBaseline } from '@mui/material';
import styled from 'styled-components';
import { NavbarPage } from '../Navbar/Navbar';
import { Footer } from '../Footer';
import FAQDoc from './Documentation/WatchNodDocumentation/Faq/FAQDoc';

const StyledCard = styled.div`
  background-color: #f7f7f7;
  padding: 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
`;

const TopMargin = styled.div`
  margin-top: 100px;
`;

const FormSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function FAQPage() {
  return (
    <>
      <Helmet>
        <title> Faq | WatchNod </title>
      </Helmet>
      <NavbarPage />
      <TopMargin />
      <CssBaseline />
      <StyledCard>
        <Card>
          <FormSide>
            <div className="container" id="contact-us">
              <FAQDoc />
            </div>
          </FormSide>
        </Card>
      </StyledCard>
      <div style={{ position: 'relative', bottom: 0, width: '100%' }}>
        <Footer />
      </div>
    </>
  );
}

export default FAQPage;
