import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Input,
  Slide,
  Button,
  IconButton,
  InputAdornment,
  ClickAwayListener,
  Paper,
  List,
  ListItem,
} from '@mui/material';
import { bgBlur } from '../../../utils/cssStyles';
import Iconify from '../../../components/iconify';

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function Searchbar() {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (option) => {
    const trimmedSearchText = option ? option.trim() : searchText.trim();
    const keywordMappings = {
      dashboard: '/dashboard/app',
      monitor: '/dashboard/monitors',
      monitors: '/dashboard/monitors',
      wallet: '/dashboard/wallet',
      profile: '/dashboard/profile',
      subscription: '/dashboard/subscription',
      users: '/dashboard/users',
      contact: '/dashboard/contact-sales',
      contact_sales: '/dashboard/contact-sales',
      news: '/dashboard/news-update',
      news_update: '/dashboard/news-update',
    };

    const lowerCaseSearchText = trimmedSearchText.toLowerCase();

    if (keywordMappings[lowerCaseSearchText]) {
      navigate(keywordMappings[lowerCaseSearchText]);
      setSearchText('');
    }

    handleClose();
  };

  const options = [
    'dashboard',
    'monitor',
    'monitors',
    'wallet',
    'profile',
    'subscription',
    'users',
    'contact',
    'contact_sales',
    'news',
    'news_update',
  ];

  const matchingOptions = options.filter((option) => option.startsWith(searchText.trim()));

  const handleOptionClick = (option) => {
    setSearchText(option);
    handleSearch(option);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" />
          </IconButton>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Button variant="contained" onClick={() => handleSearch()}>
              Search
            </Button>
            {searchText.length > 2 && open && matchingOptions.length > 0 && (
              <Paper style={{ position: 'absolute', top: '100%', left: 0, right: 0, zIndex: 10, paddingLeft: '4%' }}>
                <List>
                  {matchingOptions.map((option) => (
                    <ListItem button key={option} onClick={() => handleOptionClick(option)}>
                      {option}
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
