import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, CardContent, Typography, Avatar, Grid, Divider, Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSpring, animated } from 'react-spring';
import './Testimonials.css';
import { Footer } from '../Footer';
import { NavbarPage } from '../Navbar/Navbar';

const StyledCard = styled(Card)`
  background-color: #f0f8ff;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: justify;
`;
const TopMargin = styled.div`
  margin-top: 100px;
`;

const TestimonialCard = styled(Card)`
  && {
    margin: 20px;
    text-align: center;
    padding: 20px;
  }
`;

const TestimonialContent = styled(CardContent)`
  && {
    padding: 16px;
  }
`;

const TestimonialAvatar = styled(Avatar)`
  && {
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
  }
`;

const TestimonialText = styled(Typography)`
  && {
    margin-bottom: 12px;
  }
`;
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  centerMode: true,
  centerPadding: '0',
};

const testimonialsData = [
  {
    testimonial:
      'I have been using this monitoring solution for a while now, and it has exceeded my expectations. The real-time insights and proactive alerts have been crucial in preventing potential issues before they impact our services.',
  },
  {
    testimonial:
      'The monitoring tools provided by this solution are top-notch. They offer a comprehensive view of our systems health, allowing us to identify and resolve issues quickly. This has undoubtedly enhanced the reliability of our infrastructure.',
  },
  {
    testimonial:
      'Our team has found immense value in this monitoring solution. Its user-friendly, and the customizable dashboards provide a tailored experience. The ability to drill down into specific metrics has been instrumental in optimizing our resources.',
  },
  {
    testimonial:
      'Implementing this monitoring solution was a game-changer for our organization. The automated reporting and trend analysis have empowered us to make data-driven decisions, leading to increased efficiency and reduced downtime.',
  },
  {
    testimonial:
      'I can confidently say that this monitoring solution is a must-have for any IT team. The ease of integration with our existing infrastructure and the detailed analytics have simplified the complexities of managing a dynamic environment.',
  },
];

function TestimonialPage() {
  const testimonials = [
    'Impressive dashboard! Easy to use and intuitive.',
    'Real-time alerts have saved us from potential downtime.',
    'Highly scalable solution; perfect for growing businesses.',
    'Robust security features; peace of mind guaranteed.',
    'In-depth metrics provide actionable insights.',
    'Seamless integration with our existing infrastructure.',
    'Customer support is top-notch; always responsive.',
    'Cost-effective solution with premium features.',
    'Customizable settings meet our unique needs.',
    "The best server monitoring tool we've used.",
    'User-friendly interface; minimal learning curve.',
    'Reliable uptime statistics; invaluable for our operations.',
    'Advanced analytics help optimize server performance.',
    'Transparent pricing; no hidden fees.',
    'Efficient resource allocation; improved efficiency.',
    'Intelligent alerts system; proactive problem-solving.',
    'Highly recommended for IT professionals.',
    'Comprehensive reporting tools; great for audits.',
    'Cloud-ready solution; future-proofing our infrastructure.',
    'A game-changer in server monitoring; exceeded expectations.',
  ];

  const springProps = useSpring({ opacity: 1, from: { opacity: 0 } });

  const AnimatedTestimonialCard = animated(TestimonialCard);

  return (
    <>
      <Helmet>
        <title> Testimonial | WatchNod </title>
      </Helmet>
      <NavbarPage />
      <TopMargin />
      <CssBaseline />
      <StyledCard>
        <main>
          <Container>
            <Card>
              <div className="testimonials-container">
                <h2 style={{ textAlign: 'center', marginBottom: '30px', color: '#333333' }}>
                  &lsquo; What our customers say &rsquo;
                </h2>
                <div className="slider-container">
                  <Slider {...settings}>
                    {testimonialsData.map((testimonial, index) => (
                      <div key={index} className="testimonial-item">
                        <TestimonialAvatar alt="User" src={`/assets/images/avatars/gender/avatar_${index + 25}.jpg`} />
                        <p>{testimonial.testimonial}</p>
                        <p className="testimonial-name">{testimonial.name}</p>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </Card>
          </Container>
          <br />
          <Divider />
          <Grid container justifyContent="center">
            {testimonials.map((testimonial, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                {/* Pass the style prop with the springProps */}
                <AnimatedTestimonialCard style={springProps}>
                  <TestimonialAvatar alt="User" src={`/assets/images/avatars/gender/avatar_${index + 1}.jpg`} />
                  <TestimonialContent>
                    <TestimonialText variant="body1" color="textPrimary">
                      "{testimonial}"
                    </TestimonialText>
                  </TestimonialContent>
                </AnimatedTestimonialCard>
              </Grid>
            ))}
          </Grid>
        </main>
      </StyledCard>
      <div style={{ position: 'relative', bottom: 0, width: '100%' }}>
        <Footer />
      </div>
    </>
  );
}

export default TestimonialPage;
