import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import Logo from '../components/logo';
import { SignUpForm } from '../sections/auth/signup';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 580,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows?.card || '0px 4px 20px rgba(0, 0, 0, 0.1)',
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// const ToggleSwitch = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   gap: theme.spacing(2),
// }));

// const SwitchButton = styled(Button)(({ theme }) => ({
//   padding: theme.spacing(1),
//   borderRadius: '20px', // Round button shape
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.primary.contrastText,
//   '&.active': {
//     backgroundColor: theme.palette.secondary.main,
//   },
// }));

export default function SignUpPage() {
  const mdUp = useResponsive('up', 'md');
  // const [showCompanyForm, setShowCompanyForm] = useState(false);

  // const toggleForm = () => {
  //   setShowCompanyForm(!showCompanyForm);
  // };

  return (
    <>
      <Helmet>
        <title>Sign Up | WatchNod</title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />
        {mdUp && (
          <StyledSection>
            <Typography
              variant="h3"
              sx={{ px: 5, mt: 15, mb: 5, textAlign: 'center', fontFamily: 'poppins', color: '#333333' }}
            >
              Explore <br />
              <span style={{ fontSize: '1.7em', fontFamily: 'poppins', fontWeight: 'bold' }}>WatchNod</span>
            </Typography>
            <img src="/assets/illustrations/Mobile-login-Cristina.jpg" alt="signup" />
          </StyledSection>
        )}
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign Up to WatchNod
            </Typography>

            <Typography variant="body2" sx={{ mb: 2 }}>
              You have an account? <Link to="/login">Get started</Link>
            </Typography>

            <SignUpForm />

            {/* <ToggleSwitch sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mb: 1 }}>
              <SwitchButton
                onClick={() => !showCompanyForm && toggleForm()}
                className={!showCompanyForm ? 'active' : ''}
              >
                Personal
              </SwitchButton>
              <SwitchButton onClick={() => showCompanyForm && toggleForm()} className={showCompanyForm ? 'active' : ''}>
                Company
              </SwitchButton>
            </ToggleSwitch>

             <Divider sx={{ mb: 1 }} /> 
            {showCompanyForm ? <SignUpForm /> : <CompanySignUpForm />
            */}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
