import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from '../style/theme';

const FooterTitle = ({ text }) => (
  <Typography
    variant="h6"
    component="h6"
    sx={{
      color: Colors.black,
      fontWeight: '700',
      textTransform: 'capitalize',
      pb: 1,
    }}
  >
    {text}
  </Typography>
);
FooterTitle.propTypes = {
  text: PropTypes.string,
};
export default FooterTitle;
