import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import {
  Typography,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
  Divider,
  Paper,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import ConfirmationDialog from '../../../layouts/shared/ConfirmationDialog';

const cardStyles = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  border: '1px solid #2196F3',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
};

const headerStyles = {
  fontSize: '1.3rem',
  fontWeight: 'bold',
  color: '#2196F3',
  textAlign: 'center',
};

const priceStyles = {
  fontSize: '1.2rem',
  color: '#E91E63',
};

const descriptionStyles = {
  textAlign: 'center',
  marginTop: '16px',
  color: '#777',
};

const listItemStyles = {
  padding: '0',
  marginBottom: '5px',
  textAlign: 'center',
};

const deleteButtonStyles = {
  marginTop: '16px',
  backgroundColor: '#E91E63',
  color: 'white',
};
const renewButtonStyles = {
  marginTop: '16px',
  backgroundColor: 'blue',
  color: 'white',
  marginRight: '10px',
};
const StyledCard = styled.div`
  background-color: #f7f7f7; /* Background color for the card */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

UserSubscriptionPlans.propTypes = {
  refresh: PropTypes.func.isRequired,
};

export default function UserSubscriptionPlans({ refresh }) {
  const token = localStorage.getItem('token');

  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [isRenewPlanDialogOpen, setIsRenewPlanDialogOpen] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [itemIdToRenew, setItemIdToRenew] = useState(null);

  const fetchPlans = useCallback(async () => {
    setIsLoading(true);
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const cleanToken = token.replace(/"/g, '');
      const config = {
        headers: {
          Authorization: `Bearer ${cleanToken}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getUserSubscriptionPlans?userId=${userId}`,
        config
      );
      if (response.data) {
        setPlans(response.data.data);
      } else {
        setPlans([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  const handleDeletePlan = (purchaseSubscribeId, e) => {
    e.preventDefault();
    setItemIdToDelete(purchaseSubscribeId);
    setIsConfirmationDialogOpen(true);
  };
  const handleRenewPlan = (purchaseSubscribeId, e) => {
    e.preventDefault();
    setItemIdToRenew(purchaseSubscribeId);
    setIsRenewPlanDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsConfirmationDialogOpen(false);
    setIsLoading(true);
    try {
      const cleanToken = token.replace(/"/g, '');
      const config = {
        headers: {
          authorization: `Bearer ${cleanToken}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/deleteSubscriptionPlan`,
        { purchaseSubscribeId: itemIdToDelete },
        config
      );
      fetchPlans();
      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Failed to delete subscription plan');
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmRenew = async () => {
    setIsRenewPlanDialogOpen(false);
    setIsLoading(true);
    try {
      const cleanToken = token.replace(/"/g, '');
      const config = {
        headers: {
          authorization: `Bearer ${cleanToken}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/renewSubscriptionPlan`,
        { purchaseSubscribeId: itemIdToRenew },
        config
      );
      fetchPlans();
      if (response.success) {
        toast.success(response.data.message);
        refresh(true);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmationDialogOpen(false);
    setItemIdToDelete(null);
  };
  const handleCancelRenew = () => {
    setIsRenewPlanDialogOpen(false);
    setItemIdToRenew(null);
  };

  return (
    <div>
      <StyledCard style={{ marginTop: '2%' }}>
        <Card>
          <CardContent>
            <Paper>
              <Typography variant="h6" style={{ paddingBottom: '10px' }}>
                Running Plan
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    {plans.length === 0 ? (
                      <p>No Purchased Plan</p>
                    ) : (
                      <Grid container spacing={4}>
                        {plans.map((plan, index) =>
                          plan.customizedPlanId ? (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                              <Card elevation={4} style={cardStyles}>
                                <CardContent>
                                  <Typography variant="h5" style={headerStyles} gutterBottom>
                                    {plan.subsciptionPlanId.subscriptionTitle}
                                  </Typography>
                                  <Typography variant="h4" style={priceStyles} align="center">
                                    ${plan.customizedPlanId.SubscriptionAmount}
                                  </Typography>
                                  <Typography variant="body2" style={descriptionStyles} paragraph>
                                    Buy Date - {format(new Date(plan.createdAt), 'dd-MM-yyyy')}
                                  </Typography>
                                  <Typography variant="body2" style={descriptionStyles} paragraph>
                                    Expired Date - {format(new Date(plan.expiredOn), 'dd-MM-yyyy')}
                                    {new Date(plan.expiredOn) < new Date() && ( // Check if plan is expired
                                      <span style={{ color: 'red', fontWeight: 'bolder' }}> EXPIRED</span>
                                    )}
                                  </Typography>
                                  <Divider style={{ margin: '16px 0' }} />
                                  <List>
                                    <ListItem style={listItemStyles}>
                                      <ListItemText
                                        primary={`Subscription Days: "${plan.customizedPlanId.subscriptionDays} Days"`}
                                      />
                                    </ListItem>
                                    <ListItem style={listItemStyles}>
                                      <ListItemText primary={`Number of Node's: ${plan.customizedPlanId.numberOfIp}`} />
                                    </ListItem>
                                  </List>
                                  <Divider style={{ margin: '16px 0' }} />
                                  <div style={{ textAlign: 'center' }}>
                                    {new Date(plan.expiredOn) < new Date() && ( // Check if plan is expired
                                      <Button
                                        className="btn-sm"
                                        style={renewButtonStyles}
                                        onClick={(e) => handleRenewPlan(plan._id, e)}
                                      >
                                        Renew Plan
                                      </Button>
                                    )}

                                    <Button
                                      className="btn-sm"
                                      style={deleteButtonStyles}
                                      onClick={(e) => handleDeletePlan(plan._id, e)}
                                    >
                                      Delete Plan
                                    </Button>
                                  </div>
                                </CardContent>
                              </Card>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} md={3} key={plan._id}>
                              <Card elevation={4} style={cardStyles}>
                                <CardContent>
                                  <Typography variant="h5" style={headerStyles} gutterBottom>
                                    {plan.subsciptionPlanId.subscriptionTitle}
                                  </Typography>
                                  <Typography variant="h4" style={priceStyles} align="center">
                                    ${plan.subsciptionPlanId.SubscriptionAmount}
                                  </Typography>
                                  <Typography variant="body2" style={descriptionStyles} paragraph>
                                    Buy Date - {format(new Date(plan.createdAt), 'dd-MM-yyyy')}
                                  </Typography>
                                  <Typography variant="body2" style={descriptionStyles} paragraph>
                                    Expired Date - {format(new Date(plan.expiredOn), 'dd-MM-yyyy')}
                                    {new Date(plan.expiredOn) < new Date() && ( // Check if plan is expired
                                      <span style={{ color: 'red', fontWeight: 'bolder' }}> EXPIRED</span>
                                    )}
                                  </Typography>
                                  <Divider style={{ margin: '16px 0' }} />
                                  <List>
                                    <ListItem style={listItemStyles}>
                                      <ListItemText
                                        primary={`Subscription Days: "${plan.subsciptionPlanId.subscriptionDays} Days"`}
                                      />
                                    </ListItem>
                                    <ListItem style={listItemStyles}>
                                      <ListItemText
                                        primary={`Number of Node's: ${plan.subsciptionPlanId.numberOfIp}`}
                                      />
                                    </ListItem>
                                  </List>
                                  <Divider style={{ margin: '16px 0' }} />
                                  <div style={{ textAlign: 'center' }}>
                                    {new Date(plan.expiredOn) < new Date() && (
                                      <Button
                                        className="btn-sm"
                                        style={renewButtonStyles}
                                        onClick={(e) => handleRenewPlan(plan._id, e)}
                                      >
                                        Renew Plan
                                      </Button>
                                    )}

                                    <Button
                                      className="btn-sm"
                                      style={deleteButtonStyles}
                                      onClick={(e) => handleDeletePlan(plan._id, e)}
                                    >
                                      Delete Plan
                                    </Button>
                                  </div>
                                </CardContent>
                              </Card>
                            </Grid>
                          )
                        )}
                      </Grid>
                    )}
                  </>
                )}
              </div>
            </Paper>
          </CardContent>
        </Card>
      </StyledCard>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        message={
          <>
            Are you sure you want to delete this Plan?
            <br />
            <span style={{ fontSize: 'smaller', fontStyle: 'italic' }}>
              Note: Data associated with this subscription will be permanently deleted and cannot be refunded.
            </span>
          </>
        }
      />
      <ConfirmationDialog
        isOpen={isRenewPlanDialogOpen}
        onClose={handleCancelRenew}
        onConfirm={handleConfirmRenew}
        message="Are you sure you want to Renew this Plan?"
      />
    </div>
  );
}
