import React from 'react';
import { MdDashboard } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import { FaBook, FaListAlt, FaWallet } from 'react-icons/fa';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: <MdDashboard />,
  },
  {
    title: 'Monitors',
    icon: icon('ic_analytics'),
    path: '/dashboard/monitors',
  },
  // {
  //   title: "Alert",
  //   icon: <FaBell />, // Use FaBell from react-icons for "Alert."
  //   path: "/dashboard/alerts"
  // },
  {
    title: 'Wallet',
    icon: <FaWallet />,
    path: '/dashboard/wallet',
  },
  {
    title: 'Profile',
    icon: <CgProfile />,
    path: '/dashboard/profile',
  },
  {
    title: 'Subscription',
    icon: <FaListAlt />,
    path: '/dashboard/subscription',
  },
  {
    title: 'Documentation',
    icon: <FaBook />,
    path: '/dashboard/documentation',
  },
  {
    title: 'Logout',
    path: '/logout',
    icon: icon('ic_lock'),
  },
];

export default navConfig;
