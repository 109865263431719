import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  Container,
  Typography,
  Stack,
  Switch,
  Dialog,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';
import { UserSubscriptionPlans, Wallet } from '../sections/@dashboard/wallet';
import { Payment } from '../sections/@dashboard/razorpayPayment';

const StyledCard = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;
export default function WalletPage() {
  const [isAutoPayEnabled, setAutoPayEnabled] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const handleToggle = () => {
    setConfirmationDialogOpen(true);
  };

  useEffect(() => {
    const toggleStatus = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getAutoPayStatus`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success === true) {
          setAutoPayEnabled(response.data.data);
        } else {
          console.error('Error Get Auto-Pay Status', response.data.message);
        }
      } catch (error) {
        console.error('Error Getting Auto-Pay Status', error);
      } finally {
        setIsLoading(false);
      }
    };

    toggleStatus();
  }, [token]);

  const confirmToggle = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/updateAutoPayStatus`,
        { autoPay: !isAutoPayEnabled },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success === true) {
        toast.success(response.data.message, { autoClose: 2000 });
        setAutoPayEnabled(!isAutoPayEnabled);
      } else {
        console.error('Error Confirm Auto-Pay status', response.data.message);
        toast.error(response.data.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.error('Error updating Auto-Pay wallets', error);
      toast.error('Error Confirm Auto-Pay Status ', error.message, { autoClose: 2000 });
    } finally {
      setIsLoading(false);
      setConfirmationDialogOpen(false);
    }
  };

  const cancelToggle = () => {
    setConfirmationDialogOpen(false);
  };

  const openForm = () => {
    setIsFormOpen(true);
  };

  const refresh = () => {
    setIsRefresh(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    navigate('/dashboard/wallet');
  };

  return (
    <>
      <Helmet>
        <title>Wallet | WatchNod</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            Wallet
          </Typography>
          <Stack direction="row" alignItems="center">
            <Tooltip title="Auto Pay For Pay Automatic From Your Wallet" arrow>
              <IconButton color="primary" aria-label="Info">
                <InfoIcon />
              </IconButton>
            </Tooltip>
            {isAutoPayEnabled ? <>AutoPay On</> : <>AutoPay Off</>}
            <Switch
              onChange={handleToggle}
              checked={isAutoPayEnabled}
              name="autoPaySwitch"
              inputProps={{ 'aria-label': 'AutoPay Toggle' }}
            />
          </Stack>
        </Stack>

        <Wallet openForm={openForm} refresh={isRefresh} />
        <UserSubscriptionPlans refresh={refresh} />

        <Dialog open={isFormOpen} onClose={closeForm}>
          <StyledCard>
            <Payment open={openForm} closeForm={closeForm} />
          </StyledCard>
        </Dialog>

        <Dialog open={confirmationDialogOpen}>
          <StyledCard>
            <Typography variant="h5">
              Are you sure you want to turn AutoPay {isAutoPayEnabled ? 'OFF' : 'ON'}?
            </Typography>
            <br />
            <Stack direction="row" spacing={2} justifyContent="center">
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button onClick={confirmToggle} variant="contained" color="primary">
                    Confirm
                  </Button>
                  <Button onClick={cancelToggle} variant="contained" color="secondary">
                    Cancel
                  </Button>
                </>
              )}
            </Stack>
          </StyledCard>
        </Dialog>
      </Container>
    </>
  );
}
