import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Link,
  Stack,
  TextField,
  Checkbox,
  Button,
  Typography,
  IconButton,
  Grid,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Container,
  Paper,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function SignupForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    referralCode: '',
    agreeTerms: false,
  });

  const [errors, setErrors] = useState({
    fullName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    referralCode: '',
    agreeTerms: '',
    message: '',
  });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [otpError, setOtpError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [isVerifiedLoading, setIsVerifiedLoading] = useState(false);
  const [emailVerificationError, setEmailVerificationError] = useState(true);

  const handleOTPChange = (event) => {
    const otp = event.target.value;
    setOtpValue(otp);
    setOtpError('');
  };

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handlePasswordVisibilityToggle = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const handleConfirmPasswordVisibilityToggle = () => {
    setConfirmPasswordVisible((prevState) => !prevState);
  };

  const validateOTP = () => {
    if (!otpValue) {
      setOtpError('OTP is required');
    } else if (!/^\d{4}$/.test(otpValue)) {
      setOtpError('OTP must be exactly four digits');
    } else {
      setOtpError('');
      verifyOTP();
    }
  };

  const registerUser = async () => {
    const payload = {
      fullName: formData.fullName,
      userName: formData.username,
      email: formData.email,
      password: formData.password,
      phoneNumber: formData.phoneNumber,
      referralCode: formData.referralCode,
    };
    setIsLoading(true);

    try {
      const registerResponse = await axios.post(`${process.env.REACT_APP_API_URL}/register`, payload);

      if (registerResponse.status === 200) {
        toast.success(registerResponse.data.message, { autoClose: 2000 });
        closeDialog();
        navigate('/login');
      } else {
        console.error('Registration failed:', registerResponse.data.message);
        setErrors((prevErrors) => ({ ...prevErrors, message: registerResponse.data.message }));
      }
    } catch (registerError) {
      if (registerError.response) {
        toast.error(registerError.response.data.message, { autoClose: 2000 });
      } else if (registerError.request) {
        setErrors((prevErrors) => ({ ...prevErrors, message: 'No response from the server' }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, message: 'An error occurred while sending the request' }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resendOTP = async () => {
    try {
      setIsResendLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/sendEmailVerifactionMail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailId: formData.email }),
      });

      const data = await response.json();

      if (data.sussess === true) {
        toast.success(data.message, { autoClose: 2000 });
        openDialog();
      } else {
        toast.error(data.message);
        console.error('Failed to resend OTP');
        closeDialog();
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsResendLoading(false);
    }
  };

  const verifyOTP = async () => {
    try {
      setIsVerifiedLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/verifyOTP`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ otp: otpValue }),
      });

      const data = await response.json();

      if (data.success === true) {
        toast.success(data.message, { autoClose: 2000 });
        setIsVerified(true);
        setDialogOpen(false);
        setEmailVerificationError(false);
      } else {
        toast.error(data.message);
        setEmailVerificationError(true);
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      toast.error('An error occurred while verifying OTP. Please try again later.');
    } finally {
      setIsVerifiedLoading(false);
    }
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const sendOTP = async () => {
    try {
      setIsVerifiedLoading(true);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/sendEmailVerifactionMail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailId: formData.email }),
      });

      const data = await response.json();

      if (data.sussess === true) {
        toast.success(data.message, { autoClose: 2000 });
        setDialogOpen(true);
      } else {
        console.error('Failed to send OTP');
        toast.error(data.message || 'Failed to send OTP');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error sending OTP. Please try again later.');
    } finally {
      setIsVerifiedLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});
    setOtpError('');

    const validationErrors = {};

    if (!formData.fullName.trim()) {
      validationErrors.fullName = 'Full name is required';
    } else if (formData.fullName.length < 3) {
      validationErrors.fullName = 'Full name should be at least 3 characters long';
    } else if (formData.fullName.length > 50) {
      validationErrors.fullName = 'Full name should not exceed 50 characters';
    } else if (!/^[A-Za-z\s]+$/.test(formData.fullName)) {
      validationErrors.fullName = 'Full name can only contain alphabetic characters and spaces';
    }
    if (!formData.username.trim()) {
      validationErrors.username = 'Username is required';
    } else if (/\s/.test(formData.username)) {
      validationErrors.username = 'Username should not contain spaces';
    } else if (formData.username !== formData.username.toLowerCase()) {
      validationErrors.username = 'Username should be in all lowercase';
    }
    if (!formData.email.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = 'Invalid email format';
    } else if (emailVerificationError) {
      validationErrors.email = 'Email is not verified';
    }
    if (!formData.password) {
      validationErrors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      validationErrors.password = 'Password must be at least 8 characters';
    } else if (!/(?=.*[a-z])(?=.*[A-Z])/.test(formData.password)) {
      validationErrors.password = 'Password must contain both lowercase and uppercase letters';
    } else if (!/\d/.test(formData.password)) {
      validationErrors.password = 'Password must contain at least one digit';
    } else if (!/[@#$%^&+=]/.test(formData.password)) {
      validationErrors.password = 'Password must contain at least one special character (@#$%^&+=)';
    } else if (formData.password.length > 20) {
      validationErrors.password = 'Password should not exceed 20 characters';
    }
    if (formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = 'Passwords do not match';
    }
    if (!formData.phoneNumber.trim()) {
      validationErrors.phoneNumber = 'Phone number is required';
    } else if (!isValidPhoneNumber(formData.phoneNumber)) {
      validationErrors.phoneNumber = 'Invalid phone number format';
    }
    if (formData.referralCode.trim() && !isValidReferralCode(formData.referralCode)) {
      validationErrors.referralCode = 'Invalid referral code';
    }
    if (!formData.agreeTerms) {
      validationErrors.agreeTerms = 'You must agree to the terms and conditions';
    }

    if (Object.keys(validationErrors).length === 0) {
      if (isVerified) {
        registerUser();
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^(\+91|0)?[6789]\d{9}$/;
    return phoneRegex.test(phoneNumber);
  };

  function isValidReferralCode(referralCode) {
    return referralCode.length === 8;
  }

  return (
    <>
      <form onSubmit={handleSubmit} disabled={isLoading}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="fullName"
              label="Full Name"
              variant="outlined"
              autoComplete="fullName"
              fullWidth
              value={formData.fullName}
              onChange={handleChange}
              required
              error={Boolean(errors.fullName)}
              helperText={errors.fullName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="username"
              label="Username"
              variant="outlined"
              autoComplete="Username"
              fullWidth
              value={formData.username}
              onChange={handleChange}
              required
              error={Boolean(errors.username)}
              helperText={errors.username}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              label="Email Address"
              variant="outlined"
              autoComplete="email"
              fullWidth
              value={formData.email}
              required
              error={Boolean(errors.email)}
              helperText={errors.email}
              InputProps={{
                endAdornment: isVerified ? (
                  <InputAdornment position="end">
                    <DoneIcon style={{ color: 'green' }} />
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    {isVerifiedLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button type="button" onClick={sendOTP}>
                        Verify
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
              disabled={isVerified}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="password"
              label="Password"
              variant="outlined"
              autoComplete="password"
              fullWidth
              type={passwordVisible ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              required
              error={Boolean(errors.password)}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handlePasswordVisibilityToggle} edge="end">
                      {passwordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="confirmPassword"
              label="Confirm Password"
              variant="outlined"
              autoComplete="confirmPassword"
              fullWidth
              type={confirmPasswordVisible ? 'text' : 'password'}
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleConfirmPasswordVisibilityToggle} edge="end">
                      {confirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="phoneNumber"
              label="Phone Number"
              variant="outlined"
              autoComplete="phoneNumber"
              fullWidth
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="referralCode"
              label="Referral Code"
              variant="outlined"
              autoComplete="referralCode"
              fullWidth
              value={formData.referralCode}
              onChange={handleChange}
              error={Boolean(errors.referralCode)}
              helperText={errors.referralCode}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center">
              <Checkbox name="agreeTerms" checked={formData.agreeTerms} onChange={handleChange} color="primary" />
              <Typography variant="body2">
                I agree to the{' '}
                <Link href="termsAndConditions" target="_blank">
                  Terms and Conditions
                </Link>
              </Typography>
            </Stack>
            <Typography variant="body2" color="error">
              {errors.agreeTerms}
            </Typography>
          </Grid>
          {errors.message && (
            <Typography variant="body2" color="error" sx={{ mb: 2 }}>
              {errors.message}
            </Typography>
          )}
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button type="submit" variant="contained" color="primary" fullWidth disabled={isLoading}>
                    Sign Up
                  </Button>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </form>
      <Container maxWidth="md">
        <Dialog open={isDialogOpen} onClose={closeDialog} PaperComponent={Paper}>
          <div><DialogTitle>OTP Verification</DialogTitle></div>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <TextField
                  name="otp"
                  label="OTP"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  value={otpValue}
                  onChange={handleOTPChange}
                  error={Boolean(otpError)}
                  helperText={otpError}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0rem' }}>
                  {isVerifiedLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={validateOTP}
                        startIcon={<CheckCircleIcon />}
                        endIcon={<ErrorIcon />}
                        disabled={isVerifiedLoading}
                      >
                        Verify OTP
                      </Button>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
              <DialogActions>
                {isResendLoading ? (
                  <CircularProgress />
                ) : (
                  <Button color="primary" onClick={resendOTP}>
                    Resend OTP
                  </Button>
                )}
                <Button onClick={closeDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </div>
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
}
