import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Card,
  CircularProgress,
  TablePagination,
  Paper,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmationDialog from '../../../layouts/shared/ConfirmationDialog';
import { UserListHead, UserListToolbar } from '../user';
import Scrollbar from '../../../components/scrollbar/Scrollbar';

const NewsPage = () => {
  const [newsUpdates, setNewsUpdates] = useState([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [newsId, setNewsId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const selected = [];
  const token = localStorage.getItem('token');

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/getAllNewsUpdate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setNewsUpdates(response.data.data);
        setData(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching news updates:', error);
        toast.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [token]);

  const TABLE_HEAD = [
    { id: 'newsTitle', label: 'Title', alignRight: false },
    { id: 'newsDescription', label: 'Content', alignRight: false },
    { id: 'createdAt', label: 'Created At', alignRight: false },
    { id: 'updatedAt', label: 'Updated At', alignRight: false },
    { id: 'actions', label: 'Actions', alignRight: false },
  ];

  const handleRequestSort = (property) => {
    if (property === 'actions') {
      return;
    }
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    if (!Array.isArray(array)) {
      return [];
    }

    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => (a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    }
    return b[orderBy] < a[orderBy] ? -1 : 1;
  };

  const filteredData = stableSort(data, getComparator(order, orderBy)).filter((item) =>
    Object.values(item).join(' ').toLowerCase().includes(filterName.toLowerCase())
  );

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const slicedData = filteredData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const deleteNewsUpdate = (newsId, e) => {
    e.preventDefault();
    setNewsId(newsId);
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsConfirmationDialogOpen(false);
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/deleteNewsUpdateById`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { newsId },
      });
      setNewsUpdates((prevNewsUpdates) => prevNewsUpdates.filter((update) => update._id !== newsId));
      toast.success(response.data.message);
    } catch (error) {
      console.error('Error deleting news update:', error);
      toast.error(error.message);
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmationDialogOpen(false);
    setNewsId(null);
  };

  return (
    <>
      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          searchName="Search Title"
        />

        <Scrollbar>
          <TableContainer component={Paper} sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={newsUpdates.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  slicedData.map((newsUpdate, index) => (
                    <TableRow key={newsUpdate._id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{newsUpdate.newsTitle}</TableCell>
                      <TableCell>{newsUpdate.newsDescription}</TableCell>
                      <TableCell>{new Date(newsUpdate.createdAt).toLocaleString()}</TableCell>
                      <TableCell>{new Date(newsUpdate.updatedAt).toLocaleString()}</TableCell>
                      <TableCell>
                        <Button variant="contained" color="error" onClick={(e) => deleteNewsUpdate(newsUpdate._id, e)}>
                          Delete
                        </Button>
                        {/* Add an "Edit" button here for editing functionality */}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Card>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this?"
      />
    </>
  );
};

export default NewsPage;
