import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { RouterConfig } from './authorization';
import GoogleAnalytics from './components/GoogleAnalytics'; // Import GoogleAnalytics

function App() {
  useEffect(() => {
    document.title = 'WatchNod';
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <GoogleAnalytics /> {/* Add GoogleAnalytics component here */}
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <RouterConfig />
          <ToastContainer />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
