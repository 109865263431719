import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack } from '@mui/material';
import DocumentDetail from '../landingPage/page/Documentation/DocumentDetail';

export default function DocumentationPage() {
  return (
    <>
      <Helmet>
        <title>Documentation| WatchNod</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <h1>
            <Typography gutterBottom style={{ fontSize: '2.3rem', fontWeight: 'bold' }}>
              WatchNod Documentation
            </Typography>
          </h1>
        </Stack>
        <DocumentDetail />
      </Container>
    </>
  );
}
