import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const ContactSection = styled.div`
  padding: 70px 0;
`;

const ContactTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const ContactSubtitle = styled.p`
  text-align: center;
  margin-bottom: 40px;
`;

const ContactForm = styled.form`
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;

  @media (max-width: 600px) {
    padding: 6px;
  }
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;

  @media (max-width: 600px) {
    padding: 6px;
  }
`;

const ThankYouMessage = styled.p`
  text-align: center;
  font-weight: bold;
  color: #3956de;
  margin-bottom: 20px;
`;

function ContactUs() {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [refreshForm, setRefreshForm] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'name') {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        setError('Name should only contain letters and spaces.');
      } else {
        const lettersCount = value.replace(/\s/g, '').length;
        if (lettersCount <= 3) {
          setError('Name should have more than three letters.');
        } else {
          setError('');
        }
      }
    } else {
      setError('');
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      if (!formData.name || !formData.email || !formData.message) {
        setError('All fields are required.');
        setIsLoading(false);
        return;
      }

      if (!validateEmail(formData.email)) {
        setError('Please enter a valid email address.');
        return;
      }

      const apiUrl = `${process.env.REACT_APP_API_URL}/contactForm`;

      const response = await axios.post(apiUrl, {
        ...formData,
      });

      if (response.status === 200) {
        setError('');
        toast.success(response.data.message);
        setFormData({
          name: '',
          email: '',
          message: '',
        });
        setRefreshForm((prev) => !prev);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Server Not Found', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setError('Error Submitting Data');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContactSection>
      <ContactTitle>Contact Us</ContactTitle>
      <ContactSubtitle>
        Have questions or interested in learning more? Our dedicated support team is here to assist you. Reach out to us
        using the form below or visit our Contact page for more information.
      </ContactSubtitle>
      <ContactForm key={refreshForm} onSubmit={handleSubmit}>
        <FormGroup>
          <FormLabel htmlFor="name">Name:</FormLabel>
          <FormInput type="text" id="name" name="name" required onChange={handleInputChange} />
        </FormGroup>

        <FormGroup>
          <FormLabel htmlFor="email">Email:</FormLabel>
          <FormInput type="email" id="email" name="email" required onChange={handleInputChange} />
        </FormGroup>

        <FormGroup>
          <FormLabel htmlFor="message">Message:</FormLabel>
          <FormTextarea id="message" name="message" rows="4" required onChange={handleInputChange} />
        </FormGroup>

        {error && (
          <Grid item xs={12} md={12}>
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {error}
            </Typography>
          </Grid>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={isLoading ? <CircularProgress style={{ color: 'white' }} /> : null}
          sx={{
            margin: '10px auto',
            display: 'block',
            width: '100%',
          }}
          disabled={isLoading}
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </Button>
      </ContactForm>
      <ThankYouMessage>
        Thank you for choosing Watch-Nod. Together, let's build a future where server monitoring is not just a necessity
        but a competitive advantage.
      </ThankYouMessage>
    </ContactSection>
  );
}

export default ContactUs;
