import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Card,
  Container,
  Typography,
  TablePagination,
  CircularProgress,
} from '@mui/material';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../../../layouts/shared/ConfirmationDialog';
import { UserListHead, UserListToolbar } from '../user';
import Scrollbar from '../../../components/scrollbar';

const StyledTableCell = styled(TableCell)`
  padding: 16px;
  text-align: left;
`;

const StyledTableRow = styled(TableRow)`
  &:nth-child(odd) {
    background-color: #f2f2f2;
  }
`;

const TABLE_HEAD = [
  { id: 'userId.userName', label: <b>User Name</b>, alignRight: false },
  { id: 'subscriptionPlanId.subscriptionTitle', label: <b>Subscription Plan</b>, alignRight: false },
  { id: 'typeOfIp', label: <b>Ip Type</b>, alignRight: false },
  { id: 'dashboardName', label: <b>Dashboard Name</b>, alignRight: false },
  { id: 'ipAddress', label: <b>IP Address</b>, alignRight: false },
  { id: 'portAddress', label: <b>PORT Number</b>, alignRight: false },
  { id: 'isActive', label: <b>Status</b>, alignRight: false },
  { id: 'action', label: <b>Action</b>, alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return stabilizedThis
      .map((el) => el[0])
      .filter((_user) => _user.dashboardName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function AddressDataTable() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('userId.userName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const token = localStorage.getItem('token');

  const navigate = useNavigate();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const cleanToken = token.replace(/"/g, '');
      const config = {
        headers: {
          Authorization: `Bearer ${cleanToken}`,
        },
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getAddressByUserId?userId=${userId}`, config);
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = (_id, e) => {
    e.preventDefault();
    setItemIdToDelete(_id);
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsConfirmationDialogOpen(false);
    setIsLoading(true);
    try {
      const cleanToken = token.replace(/"/g, '');
      const config = {
        headers: {
          Authorization: `Bearer ${cleanToken}`,
        },
      };
      await axios.post(`${process.env.REACT_APP_API_URL}/deleteAddress`, { _id: itemIdToDelete }, config);
      fetchData();
    } catch (error) {
      console.error('Error deleting address:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmationDialogOpen(false);
    setItemIdToDelete(null);
  };

  const handleView = (item) => {
    const userName = item?.typeOfIp === 'public' ? `${item?.userId?.userName}_public` : `${item?.userId?.userName}_proxy`; 
    navigate(
      `/dashboard/monitorGraph/${userName}/${item.dashboardName}/${item.ipAddress}/${item.portAddress}`
    );
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <Card>
      <Container maxWidth="xl">
        <Typography variant="h6" sx={{ marginTop: '15px', fontSize: { xs: '1.5rem', md: '2rem' } }}>
          Watch List
        </Typography>
        <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} searchName="Search User Name" />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, overflowX: 'auto' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={data.length}
                      onRequestSort={handleRequestSort}
                    />
                    {data.length === 0 ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={TABLE_HEAD.length}>
                            <Typography sx={{ marginTop: '15px', justifyContent: 'center', display: 'flex' }}>
                              No Data Found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                          const { _id, userId, subscriptionPlanId,typeOfIp, dashboardName, ipAddress, portAddress, isActive } =
                            row;

                          return (
                            <StyledTableRow hover key={_id} tabIndex={-1}>
                              <TableCell padding="normal">{page * rowsPerPage + index + 1}</TableCell>
                              <StyledTableCell>{userId.userName}</StyledTableCell>
                              <StyledTableCell>{subscriptionPlanId.subscriptionTitle}</StyledTableCell>
                              <StyledTableCell>{typeOfIp}</StyledTableCell>
                              <StyledTableCell>{dashboardName}</StyledTableCell>
                              <StyledTableCell>{ipAddress}</StyledTableCell>
                              <StyledTableCell>{portAddress}</StyledTableCell>
                              <StyledTableCell>{isActive ? 'Active' : 'Expired'}</StyledTableCell>
                              <StyledTableCell>
                                <Button
                                  variant="contained"
                                  color="info"
                                  onClick={() => handleView(row)}
                                  style={{ marginRight: '10px', width: '25%' }}
                                  disabled={!isActive}
                                  sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }}
                                >
                                  View
                                </Button>
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={(e) => handleDelete(_id, e)}
                                  disabled={!isActive}
                                  sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }}
                                  style={{ width: '25%' }}
                                >
                                  Delete
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}

                        {emptyRows > 0 && (
                          <StyledTableRow style={{ height: 53 * emptyRows }}>
                            <StyledTableCell colSpan={6} />
                          </StyledTableRow>
                        )}
                      </TableBody>
                    )}

                    {isNotFound && (
                      <TableBody>
                        <TableRow key="is-not-found">
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>
                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </>
              )}
            </div>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this?"
      />
    </Card>
  );
}

export default AddressDataTable;
