import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import {
  Button,
  TextField,
  Grid,
  Autocomplete,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  Tooltip,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const SubmitButton = styled(Button)`
  background-color: #4caf50;
  color: white;
  margin-top: 5px;

  &:hover {
    background-color: #45a049;
  }
`;

export default function AddressForm(props) {
  const [formData, setFormData] = useState({
    dashboardName: '',
    ip: '',
    port: '',
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [planData, setPlanData] = useState([]);
  const [subscriptionPlanId, setSelectedPlanId] = useState(null);
  const [purchaseSubscribeId, setPurchaseSubscribeId] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIpType, setSelectedIpType] = useState('');
  const token = localStorage.getItem('token');

  const fetchPlanData = async (token, setPlanData, setLoading) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getUserSubscriptionPlansWithIp`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response && response.data && response.data.success) {
        const planDataArray = Object.keys(response.data.data).map((key) => ({
          purchaseSubscribeId: key,
          subscriptionPlanId: response.data.data[key].subscriptionPlanId,
          planName: response.data.data[key].planName,
          ipCount: response.data.data[key].ipCount,
        }));
        setPlanData(planDataArray);
        setLoading(false);
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error('Server Not Found', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setError('Error fetching plan data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlanData(token, setPlanData, setError);
  }, [token, setPlanData, setError]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDropdownChange = (_, newValue) => {
    setSelectedOption(newValue);
    let selectedPlan = null;
    if (newValue) {
      selectedPlan = planData.find((plan) => plan.purchaseSubscribeId === newValue.value);
    }
    if (selectedPlan) {
      setSelectedPlanId(selectedPlan.subscriptionPlanId);
      setPurchaseSubscribeId(selectedPlan.purchaseSubscribeId);
    } else {
      setSelectedPlanId(null);
      setPurchaseSubscribeId(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem('userId');

    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      if (!formData.dashboardName || !formData.ip || !formData.port || !selectedOption || !selectedIpType) {
        setError('All fields are required.');
        return;
      }

      const apiUrl = `${process.env.REACT_APP_API_URL}/addAddress`;

      const response = await axios.post(
        apiUrl,
        {
          ...formData,
          userId,
          subscriptionPlanId,
          purchaseSubscribeId,
          typeOfIp: selectedIpType,
        },
        config
      );

      if (response.status === 200) {
        setFormData({
          dashboardName: '',
          ip: '',
          port: '',
        });
        setSelectedOption(null);
        setSelectedIpType('');
        setError('');
        fetchPlanData(token, setPlanData, setError);
        props.reloadData();
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleIpTypeChange = (event) => {
    setSelectedIpType(event.target.value);
  };

  AddressForm.propTypes = {
    reloadData: PropTypes.func.isRequired,
  };

  // To disable the DropDown option whose IPCount is 0.
  const filteredOptions = planData.filter((plan) => plan.ipCount > 0);
  const formattedOptions = filteredOptions.map((plan, index) => ({
    label: `${index + 1}. ${plan.planName} (Remaining IP - ${plan.ipCount})`,
    value: plan.purchaseSubscribeId,
  }));

  return (
    <Card>
      <CardContent style={{ textAlign: 'center' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={formattedOptions}
              getOptionLabel={(option) => option.label}
              value={selectedOption}
              onChange={handleDropdownChange}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <TextField {...params} label="Subscription Plan" required />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              select
              fullWidth
              required
              label="IP Type"
              value={selectedIpType}
              onChange={handleIpTypeChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ marginRight: 1 }}>
                    <Tooltip title="Click for more information about IP types (Private or Public).">
                      <RouterLink to="/dashboard/documentation#typesOfIp" style={{ textDecoration: 'none' }}>
                        <IconButton
                          sx={{
                            color: 'action.active',
                            '&:hover': {
                              color: 'blue',
                            },
                          }}
                        >
                          <InfoIcon />
                        </IconButton>
                      </RouterLink>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiSelect-select': { textAlign: 'left' },
                '& .MuiInputBase-root': { paddingRight: 2 },
              }}
            >
              <MenuItem value="private">Private IP</MenuItem>
              <MenuItem value="public">Public IP</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              required
              label="Dashboard Name"
              name="dashboardName"
              value={formData.dashboardName}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Used for identifying different nodes in the Watch List.">
                      <IconButton>
                        <InfoIcon color="action" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              required
              label={selectedIpType === 'private' ? 'Hostname' : 'IP Address'}
              name="ip"
              value={formData.ip}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={
                        selectedIpType === 'private'
                          ? 'This field is case-sensitive. Please confirm the hostname before entering it.'
                          : 'This field is case-sensitive and must be formatted correctly. Please enter a valid IP address.'
                      }
                    >
                      <IconButton>
                        <InfoIcon color="action" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              required
              label="Port Number"
              name="port"
              value={formData.port}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Specify the port number to connect to the service.">
                      <IconButton>
                        <InfoIcon color="action" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {error && (
            <Grid item xs={12} md={12}>
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {error}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <SubmitButton variant="contained" type="submit" onClick={(e) => handleSubmit(e)}>
                    Submit
                  </SubmitButton>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
