import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Button, Stack, Grid, Typography, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Iframe from 'react-iframe';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TimeIntervalDropdown from './TimeIntervalDropDown';
import CustomTimeRangePopup from './CustomTimeRangePopup';

const iframeTitle = {
  color: 'black',
  textDecoration: 'none',
  fontSize: 'larger',
  fontWeight: 'bold',
};
const iframDivStyle = {
  marginLeft: '7px',
};
export default function MonitorGraph() {
  const [isLoadingCount, setIsLoadingCount] = useState(23); //  23 is the total number of iframe
  const [isFirstDivCollapsed, setIsFirstDivCollapsed] = useState(false);
  const token = localStorage.getItem('token');
  const [isSecondDivCollapsed, setIsSecondDivCollapsed] = useState(false);
  const [isThirdDivCollapsed, setIsThirdDivCollapsed] = useState(false);
  const [isFourthDivCollapsed, setIsFourthDivCollapsed] = useState(false);
  const [isFifthDivCollapsed, setIsFifthDivCollapsed] = useState(false);
  const [isSixthDivCollapsed, setIsSixthDivCollapsed] = useState(false);
  const currentTimeInMilliseconds = new Date().getTime();
  const [startTime, setStartTime] = useState(currentTimeInMilliseconds - 5 * 60 * 1000);
  const [endTime, setEndTime] = useState(currentTimeInMilliseconds);
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const [grafanaToken, setGrafanaToken] = useState();

  useEffect(() => {
    fetchGrafanaToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);

  const fetchGrafanaToken = async () => {
    try {
      const cleanToken = token.replace(/"/g, '');
      const config = {
        headers: {
          Authorization: `Bearer ${cleanToken}`,
        },
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/createGrafanaToken`, config);
      if (response.data.success === true) {
        setGrafanaToken(response.data.token);
      }
    } catch (error) {
      console.error('Error fetching token', error);
    }
  };
  const handleRefreshClick = () => {
    fetchGrafanaToken();
  };

  const { dashboardName, userName, ipAddress, portAddress } = useParams();

  const handleIntervalSelect = (selectedInterval) => {
    setIsLoadingCount(23);
    const intervalInMilliseconds = selectedInterval * 60 * 1000;
    setStartTime(currentTimeInMilliseconds - intervalInMilliseconds);
    setEndTime(currentTimeInMilliseconds);
  };
  const handleShowCustomPopup = () => {
    setShowCustomPopup(true);
  };

  const handleApplyButtonClick = (newStartTime, newEndTime) => {
    setIsLoadingCount(23);
    setStartTime(newStartTime);
    setEndTime(newEndTime);
  };
  const toggleFirstCollapse = () => {
    setIsFirstDivCollapsed(!isFirstDivCollapsed);
  };
  const toggleSecondCollapse = () => {
    setIsSecondDivCollapsed(!isSecondDivCollapsed);
  };
  const toggleThirdCollapse = () => {
    setIsThirdDivCollapsed(!isThirdDivCollapsed);
  };
  const toggleFourthCollapse = () => {
    setIsFourthDivCollapsed(!isFourthDivCollapsed);
  };
  const toggleFifthCollapse = () => {
    setIsFifthDivCollapsed(!isFifthDivCollapsed);
  };
  const toggleSixthCollapse = () => {
    setIsSixthDivCollapsed(!isSixthDivCollapsed);
  };
  const handleIframeLoad = () => {
    setIsLoadingCount((prevCount) => prevCount - 1);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h3" gutterBottom>
          Node Metrics
        </Typography>
        <Stack direction="row" spacing={2}>
          <TimeIntervalDropdown onSelect={handleIntervalSelect} />
          <Button variant="outlined" color="primary" onClick={handleShowCustomPopup}>
            Select Custom Time
          </Button>
          <Button variant="contained" color="primary" startIcon={<RefreshIcon />} onClick={handleRefreshClick}>
            Refresh
          </Button>
          {showCustomPopup && (
            <CustomTimeRangePopup
              open={showCustomPopup}
              onClose={() => {
                setShowCustomPopup(false);
              }}
              onApply={handleApplyButtonClick}
            />
          )}
        </Stack>
      </Stack>
      {isLoadingCount > 0 && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '110%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
        >
          <CircularProgress color="primary" />
        </div>
      )}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <a href="#" onClick={toggleFirstCollapse} style={iframeTitle}>
            Quick CPU / Mem / Disk
          </a>
        </AccordionSummary>
        <AccordionDetails>
          {!isFirstDivCollapsed && (
            <Grid container>
              {[20, 155, 16, 21, 154].map((panelId) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={panelId}>
                  <div style={iframDivStyle}>
                    <Iframe
                      title={`graph-${panelId}`}
                      src={`${process.env.REACT_APP_API_GRAFANA_URL}/d-solo/rYdddlPWk/${dashboardName}?orgId=1&refresh=1m&var-datasource=d42661db-afb2-49d7-bea2-6dc6b14b7168&var-job=${userName}&var-node=${ipAddress}%3A${portAddress}&var-diskdevices=%5Ba-z%5D%2B%7Cnvme%5B0-9%5D%2Bn%5B0-9%5D%2B%7Cmmcblk%5B0-9%5D%2B&from=${startTime}&to=${endTime}&theme=light&panelId=${panelId}&auth_token=${grafanaToken}`}
                      width="100%"
                      height={200}
                      frameBorder={1}
                      onLoad={handleIframeLoad}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
          {!isFirstDivCollapsed && (
            <Grid container>
              {[14, 15, 23, 75, 18].map((panelId) => (
                <Grid item xs={4} sm={3} md={3} lg={2} key={panelId}>
                  <div style={iframDivStyle}>
                    <Iframe
                      title={`graph-${panelId}`}
                      src={`${process.env.REACT_APP_API_GRAFANA_URL}/d-solo/rYdddlPWk/${dashboardName}?orgId=1&refresh=1m&var-datasource=d42661db-afb2-49d7-bea2-6dc6b14b7168&var-job=${userName}&var-node=${ipAddress}%3A${portAddress}&var-diskdevices=%5Ba-z%5D%2B%7Cnvme%5B0-9%5D%2Bn%5B0-9%5D%2B&from=${startTime}&to=${endTime}&theme=light&panelId=${panelId}&auth_token=${grafanaToken}`}
                      width="100%"
                      height={200}
                      frameBorder={1}
                      onLoad={handleIframeLoad}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </AccordionDetails>

      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <a href="#" onClick={toggleSecondCollapse} style={iframeTitle}>
            CPU / Memory / Net / Disk
          </a>
        </AccordionSummary>
        <AccordionDetails>
          {isSecondDivCollapsed ? null : (
            <Grid container>
              {[3, 24, 84, 156, 229, 42, 127].map((panelId) => (
                <Grid item xs={12} sm={12} md={12} lg={6} key={panelId}>
                  <div style={iframDivStyle}>
                    <Iframe
                      title={`graph-${panelId}`}
                      src={`${process.env.REACT_APP_API_GRAFANA_URL}/d-solo/rYdddlPWk/${dashboardName}?orgId=1&refresh=1m&var-datasource=d42661db-afb2-49d7-bea2-6dc6b14b7168&var-job=${userName}&var-node=${ipAddress}%3A${portAddress}&var-diskdevices=%5Ba-z%5D%2B%7Cnvme%5B0-9%5D%2Bn%5B0-9%5D%2B%7Cmmcblk%5B0-9%5D%2B&from=${startTime}&to=${endTime}&theme=light&panelId=${panelId}&auth_token=${grafanaToken}`}
                      width="100%"
                      height={300}
                      frameBorder={1}
                      onLoad={handleIframeLoad}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <a href="#" onClick={toggleThirdCollapse} style={iframeTitle}>
            System Processes
          </a>
        </AccordionSummary>
        <AccordionDetails>
          {isThirdDivCollapsed ? null : (
            <Grid container>
              {[62].map((panelId) => (
                <Grid item xs={12} sm={12} md={12} lg={12} key={panelId}>
                  <div style={iframDivStyle}>
                    <Iframe
                      title={`graph-${panelId}`}
                      src={`${process.env.REACT_APP_API_GRAFANA_URL}/d-solo/rYdddlPWk/${dashboardName}?orgId=1&refresh=1m&var-datasource=d42661db-afb2-49d7-bea2-6dc6b14b7168&var-job=${userName}&var-node=${ipAddress}%3A${portAddress}&var-diskdevices=%5Ba-z%5D%2B%7Cnvme%5B0-9%5D%2Bn%5B0-9%5D%2B%7Cmmcblk%5B0-9%5D%2B&from=${startTime}&to=${endTime}&theme=light&panelId=${panelId}&auth_token=${grafanaToken}`}
                      width="100%"
                      height={400}
                      frameBorder={1}
                      onLoad={handleIframeLoad}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          <a href="#" onClick={toggleFourthCollapse} style={iframeTitle}>
            System Misc
          </a>
        </AccordionSummary>
        <AccordionDetails>
          {isFourthDivCollapsed ? null : (
            <Grid container>
              {[7].map((panelId) => (
                <Grid item xs={12} sm={12} md={12} lg={12} key={panelId}>
                  <div style={iframDivStyle}>
                    <Iframe
                      title={`graph-${panelId}`}
                      src={`${process.env.REACT_APP_API_GRAFANA_URL}/d-solo/rYdddlPWk/${dashboardName}?orgId=1&refresh=1m&var-datasource=d42661db-afb2-49d7-bea2-6dc6b14b7168&var-job=${userName}&var-node=${ipAddress}%3A${portAddress}&var-diskdevices=%5Ba-z%5D%2B%7Cnvme%5B0-9%5D%2Bn%5B0-9%5D%2B%7Cmmcblk%5B0-9%5D%2B&from=${startTime}&to=${endTime}&theme=light&panelId=${panelId}&auth_token=${grafanaToken}`}
                      width="100%"
                      height={300}
                      frameBorder={1}
                      onLoad={handleIframeLoad}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5-content"
          id="panel5-header"
        >
          <a href="#" onClick={toggleFifthCollapse} style={iframeTitle}>
            Network Traffic
          </a>
        </AccordionSummary>
        <AccordionDetails>
          {isFifthDivCollapsed ? null : (
            <Grid container>
              {[60].map((panelId) => (
                <Grid item xs={12} sm={12} md={12} lg={12} key={panelId}>
                  <div style={iframDivStyle}>
                    <Iframe
                      title={`graph-${panelId}`}
                      src={`${process.env.REACT_APP_API_GRAFANA_URL}/d-solo/rYdddlPWk/${dashboardName}?orgId=1&refresh=1m&var-datasource=d42661db-afb2-49d7-bea2-6dc6b14b7168&var-job=${userName}&var-node=${ipAddress}%3A${portAddress}&var-diskdevices=%5Ba-z%5D%2B%7Cnvme%5B0-9%5D%2Bn%5B0-9%5D%2B%7Cmmcblk%5B0-9%5D%2B&from=${startTime}&to=${endTime}&theme=light&panelId=${panelId}&auth_token=${grafanaToken}`}
                      width="100%"
                      height={300}
                      frameBorder={1}
                      onLoad={handleIframeLoad}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6-content"
          id="panel6-header"
        >
          <a href="#" onClick={toggleSixthCollapse} style={iframeTitle}>
            Network Sock-state
          </a>
        </AccordionSummary>
        <AccordionDetails>
          {isSixthDivCollapsed ? null : (
            <Grid container>
              {[63, 124, 220].map((panelId) => (
                <Grid item xs={12} sm={12} md={12} lg={6} key={panelId}>
                  <div style={iframDivStyle}>
                    <Iframe
                      title={`graph-${panelId}`}
                      src={`${process.env.REACT_APP_API_GRAFANA_URL}/d-solo/rYdddlPWk/${dashboardName}?orgId=1&refresh=1m&var-datasource=d42661db-afb2-49d7-bea2-6dc6b14b7168&var-job=${userName}&var-node=${ipAddress}%3A${portAddress}&var-diskdevices=%5Ba-z%5D%2B%7Cnvme%5B0-9%5D%2Bn%5B0-9%5D%2B%7Cmmcblk%5B0-9%5D%2B&from=${startTime}&to=${endTime}&theme=light&panelId=${panelId}&auth_token=${grafanaToken}`}
                      width="100%"
                      height={300}
                      frameBorder={1}
                      onLoad={handleIframeLoad}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
