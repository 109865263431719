import React, { useEffect, useState } from 'react';
import ConfirmationDialog from '../layouts/shared/ConfirmationDialog';

function LogOut() {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const handleCancelLogOut = () => {
    setIsConfirmationDialogOpen(false);
    window.history.back();
  };

  const handleConfirmLogOut = async () => {
    setIsConfirmationDialogOpen(false);
    localStorage.clear();
    window.location.replace('/login');
  };
  useEffect(() => {
    setIsConfirmationDialogOpen(true);
  }, []);

  return (
    <>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={handleCancelLogOut}
        onConfirm={handleConfirmLogOut}
        message="Are you sure you want to Log Out?"
      />
    </>
  );
}

export default LogOut;
