import React from 'react';
import { Helmet } from 'react-helmet-async';
import AboutUs from './about-us';
import Feature from './Feature';
import Testimonial from './Testimonial';
import PricingPlanPage from './PricingPlan';
import Header from './Header';
import { Navbar } from './Navbar/Navbar';
import { Footer } from './Footer';

function LandingRoute() {
  return (
    <>
      <Helmet>
        <title> Home | WatchNod </title>
      </Helmet>
      <Navbar />
      <Header />
      <Feature />
      <AboutUs />
      <Testimonial />
      <PricingPlanPage />
      <Footer />
    </>
  );
}

export default LandingRoute;
