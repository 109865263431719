import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { Card, Container, CssBaseline } from '@mui/material';
import { NavbarPage } from '../../Navbar/Navbar';
import DocumentDetail from './DocumentDetail';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  flex: 1;
`;

const StyledCard = styled(Card)`
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px auto;
  text-align: justify;
`;

const FlexContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

function DocumentPage() {
  useEffect(() => {
    // eslint-disable-next-line
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <PageWrapper>
      <Helmet>
        <title> Document | WatchNod </title>
      </Helmet>
      <StyledCard>
        <NavbarPage />
        <CssBaseline />
        <ContentWrapper>
          <FlexContainer>
            {/* <Sidebar /> */}
            <DocumentDetail />
          </FlexContainer>
        </ContentWrapper>
      </StyledCard>
    </PageWrapper>
  );
}

export default DocumentPage;
