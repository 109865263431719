import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Typography } from '@mui/material';
import { NavbarPage } from '../Navbar/Navbar';
import { Footer } from '../Footer';

const TopMargin = styled.div`
  margin-top: 100px;
`;

const StyledContainer = styled(Container)`
  && {
    background-color: #f0f8ff;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 20px auto;
  }
  text-align: justify;
`;

const Title = styled(Typography)`
  margin-bottom: 20px;
  text-align: center;
`;

const SectionTitle = styled(Typography)`
  && {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  text-align: justify;
`;

const StyledFooter = styled(Footer)`
  margin-top: 50px;
`;

const bold = {
  fontWeight: 'bold',
};

const ServiceDeliveryPolicy = () => (
  <>
    <Helmet>
      <title>Service Delivery Policy | WatchNod</title>
    </Helmet>
    <NavbarPage />
    <TopMargin />
    <StyledContainer>
      <Title variant="h4"> Service Delivery Policy</Title>
      <SectionTitle variant="h5">Subscription Activation</SectionTitle>
      <Typography variant="body1">
        As soon as you complete the payment process and purchase any of our subscription plans, your services with
        Watch-Nod will commence immediately. This means you will gain access to all the features and functionalities
        included in your chosen subscription tier without delay.
      </Typography>
      <SectionTitle variant="h5">Instant Access</SectionTitle>
      <Typography variant="body1">
        Upon subscription activation, you will receive immediate access to our monitoring-based tool, allowing you to
        add servers for monitoring and start collecting server metrics data. Whether you're a new customer or renewing
        your subscription, your access will be seamless, ensuring uninterrupted monitoring and support for your server
        infrastructure.
      </Typography>
      <SectionTitle variant="h5">Confirmation and Account Setup</SectionTitle>
      <Typography variant="body1">
        After your payment is processed successfully, you will receive a confirmation email containing details about
        your subscription and instructions on how to access your account. If you're a new customer, you'll also receive
        information on setting up your account, including creating login credentials.
      </Typography>
      <SectionTitle variant="h5">Customer Support</SectionTitle>
      <Typography variant="body1">
        Should you encounter any issues or have questions about accessing or using our services after purchasing a
        subscription, our customer support team is available to assist you promptly. You can reach out to us via email
        or through our support portal, and we'll do our best to address your concerns and ensure a smooth experience.
      </Typography>
      <SectionTitle variant="h5">Changes to Subscription</SectionTitle>
      <Typography variant="body1">
        If you choose to upgrade or downgrade your subscription plan at any time, the changes will take effect
        immediately upon confirmation of the new plan and payment processing. You'll have instant access to the features
        and resources associated with your updated subscription level.
      </Typography>
      <SectionTitle variant="h5">Cancellation and Refunds</SectionTitle>
      <Typography variant="body1">
        If you decide to cancel your subscription, you may do so at any time. Upon cancellation, your access to our
        services will continue until the end of your current billing cycle. We do not offer refunds for partial
        subscription periods. In case of any transaction failure in the wallet, we'll refund the full amount in the next
        10 working days.
      </Typography>
      <SectionTitle variant="h5">Contact Us</SectionTitle>
      <Typography variant="body1">
        If you have any questions or require further assistance regarding our service delivery process, please don't
        hesitate to contact us at <Link>info@watchnod.com</Link>. We're here to help and ensure that your experience
        with Watch-Nod is exceptional.
      </Typography>
      <Typography variant="body1" style={bold}>
        <i>Last updated : Jan 21, 2024</i>
      </Typography>
      <Typography variant="body1" style={bold}>
        <i>Watch-Nod</i>
      </Typography>
    </StyledContainer>
    <StyledFooter />
  </>
);

export default ServiceDeliveryPolicy;
