import { useEffect } from 'react';
import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import DashboardLayout from '../layouts/dashboard';
import UserPage from '../pages/UserPage';
import LoginPage from '../pages/LoginPage';
import Page404 from '../pages/Page404';
import DashboardAppPage from '../pages/DashboardAppPage';
import SignUpPage from '../pages/SignUpPage';
import LandingRoute from '../landingPage/LandingRoute';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import About from '../landingPage/page/About';
import FAQPage from '../landingPage/page/FAQPage';
import Documentation from '../landingPage/page/Documentation/Document';
import DocumentDetailPage from '../landingPage/page/DocumentDetailPage';
import PrivacyPolicy from '../landingPage/page/PrivacyPolicy';
import ServiceDeliveryPolicy from '../landingPage/page/ServiceDeliveryPolicy';
import TermsAndConditions from '../landingPage/page/TermsAndConditions';
import LogOut from '../pages/LogOut';
import UpdatePasswordPage from '../pages/UpdatePasswordPage';
import MonitorPage from '../pages/MonitorPage';
import SubscriptionPage from '../sections/@dashboard/subscription/SubscriptionPage';
import ProfilePage from '../pages/ProfilePage';
import WalletPage from '../pages/WalletPage';
import MonitorGraphPage from '../pages/MonitorGraphPage';
import TestimonialPage from '../landingPage/page/TestimonialPage';
import SubscriptionPricingPage from '../pages/SubscriptionPricingPlan';
import ContactSales from '../pages/ContactSales';
import NewsUpdate from '../pages/NewsUpdate';
import { SuccessPage } from '../sections/@dashboard/razorpayPayment';
import DocumentationPage from '../pages/DocumentationPage';
import { getUserRole, isLoggedIn } from './authUtils';
// ----------------------------------------------------------------------

export default function RouterConfig() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn && window.location.pathname.startsWith('/dashboard')) {
      navigate('/login');
    }
  }, [navigate]);

  const adminRoutes = [
    {
      path: '/',
      element: <LandingRoute />,
      children: [{ element: <Navigate to="/monitoring" />, index: true }],
    },
    {
      path: '/dashboard',
      element: isLoggedIn ? <DashboardLayout isAdmin /> : <Navigate to="/login" />,
      children: [
        { path: '', element: <Navigate to="/dashboard/admin" /> },
        { path: 'admin', element: <UserPage /> },
        { path: 'users', element: <UserPage /> },
        { path: 'contact-sales', element: <ContactSales /> },
        { path: 'contact-sales/:userId', element: <ContactSales /> },
        { path: 'news-update', element: <NewsUpdate /> },
        { path: 'profile', element: <ProfilePage /> },
        // { path: '404', element: <Page404 /> },
        // { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/logout',
      element: <LogOut />,
    },
    {
      path: '/signup',
      element: <SignUpPage />,
    },
    {
      path: '*',
      element: <Page404 />,
    },
    {
      path: '/forgotPassword',
      element: <ForgotPasswordPage />,
    },
    {
      path: '/updatePassword',
      element: <UpdatePasswordPage />,
    },
    {
      path: '/about',
      element: <About />,
    },
    {
      path: '/faq',
      element: <FAQPage />,
    },
    {
      path: '/document',
      element: <Documentation />,
    },
    {
      path: '/testimonial',
      element: <TestimonialPage />,
    },
    {
      path: '/documentDetail',
      element: <DocumentDetailPage />,
    },
    {
      path: '/privacyPolicy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/serviceDeliveryPolicy',
      element: <ServiceDeliveryPolicy />,
    },
    {
      path: '/termsAndConditions',
      element: <TermsAndConditions />,
    },
  ];

  const userRoutes = [
    {
      path: '/',
      element: <LandingRoute />,
      children: [{ element: <Navigate to="/monitoring" />, index: true }],
    },
    {
      path: '/dashboard',
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'monitors', element: <MonitorPage /> },
        { path: 'alerts', element: <DashboardAppPage /> },
        { path: 'wallet', element: <WalletPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'subscription', element: <SubscriptionPricingPage /> },
        {
          path: '/dashboard/documentation',
          element: <DocumentationPage />,
        },
        {
          path: '/dashboard/monitorGraph/:userName/:dashboardName/:ipAddress/:portAddress',
          element: <MonitorGraphPage />,
        },
        {
          path: '/dashboard/subscription/:_id',
          element: <SubscriptionPricingPage />,
        },
        {
          path: '/dashboard/subscription/:_id/:subscriptionName/:subscriptionAmount',
          element: <SubscriptionPricingPage />,
        },
        {
          path: '/dashboard/wallet/payment',
          element: <WalletPage />,
        },
        {
          path: '/dashboard/wallet/success',
          element: <SuccessPage />,
        },
        // { path: '404', element: <Page404 /> },
        // { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/dashboard/subscription',
      element: <SubscriptionPage />,
    },
    {
      path: '/dashboard/monitorGraph/:userName/:dashboardName/:ipAddress/:portAddress',
      element: <MonitorGraphPage />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/signup',
      element: <SignUpPage />,
    },
    {
      path: '/logout',
      element: <LogOut />,
    },
    {
      path: '*',
      element: <Page404 />,
    },
    {
      path: '/forgotPassword',
      element: <ForgotPasswordPage />,
    },
    {
      path: '/updatePassword',
      element: <UpdatePasswordPage />,
    },
    {
      path: '/about',
      element: <About />,
    },
    {
      path: '/faq',
      element: <FAQPage />,
    },
    {
      path: '/document',
      element: <Documentation />,
    },
    {
      path: '/testimonial',
      element: <TestimonialPage />,
    },
    {
      path: '/documentDetail',
      element: <DocumentDetailPage />,
    },
    {
      path: '/privacyPolicy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/serviceDeliveryPolicy',
      element: <ServiceDeliveryPolicy />,
    },
    {
      path: '/termsAndConditions',
      element: <TermsAndConditions />,
    },
  ];

  const routes = useRoutes(getUserRole === 'admin' ? adminRoutes : userRoutes);

  return (
    <>
      <Helmet>
        <title>Watchnod - Network Monitoring</title>
        <meta
          name="description"
          content="Watchnod helps you monitor your network effectively. Sign up for free to get started."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: 'https://watchnod.com',
            potentialAction: {
              '@type': 'SearchAction',
              target: 'https://watchnod.com/search?q={search_term_string}',
              'query-input': 'required name=search_term_string',
            },
          })}
        </script>
      </Helmet>
      {routes}
    </>
  );
}
