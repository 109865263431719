import React from 'react';
import {
    Section,
    SectionTitle,
    StyledTitle,
    DarkBlueColor,
    StyledParagraph,
} from '../DocumentStyles';

const TypesOfIp = () => (
        <Section id="typesOfIp" style={{ padding: '4rem 0' }}>
            <SectionTitle>
                <DarkBlueColor>
                    <u>Types Of IP</u>
                </DarkBlueColor>
            </SectionTitle>
            <StyledTitle>Different Types of IP Addresses and Their Functions</StyledTitle>
            <ol>
                <li>
                    <strong>
                        Public IP Address
                    </strong>
                    <StyledParagraph>
                    <b>Definition</b>: A public IP address is an IP address that is assigned to a device or network by an Internet Service Provider (ISP) and is accessible over the internet.
                     It is used to identify and communicate with devices on the global internet.
                    </StyledParagraph>
                    <StyledParagraph>
                    <b>Usage</b>: Public IPs are used by websites, online services, and servers to communicate with devices on the internet. 
                    Each device or network with a public IP can be directly accessed from anywhere on the internet, assuming there are no firewalls or other security measures blocking it.
                    </StyledParagraph>
                    <StyledParagraph>
                    <b>Example</b>: When you visit a website, the website’s server has a public IP address.
                     Similarly, your home router might have a public IP address assigned by your ISP, which allows you to access online services.
                    </StyledParagraph>

                </li>
                <li>
                    <strong>
                    Private IP Address
                    </strong>
                    <StyledParagraph>
                    <b>Definition</b>: A private IP address is an IP address used within a private network. 
                    These addresses are not accessible from the internet and are used for communication within the local network.
                    </StyledParagraph>
                    <StyledParagraph>
                    <b>Usage</b>: Private IPs are used by devices within a local network, such as a home or office network.
                     They help route traffic internally and allow devices to communicate with each other without being exposed to the internet.
                    </StyledParagraph>
                    <StyledParagraph>
                    <b>Example</b>: Your computer, smartphone, and printer on a home network will have private IP addresses 
                    like 192.168.1.2, 192.168.1.3, etc., assigned by your router.
                    </StyledParagraph>

                </li>
            </ol>
        </Section>

    );

export default TypesOfIp;