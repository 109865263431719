import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import {
  Section,
  SectionTitle,
  StyledTitle,
  StyledDescription,
  CodeBlockContainer,
  StyledLink,
  CopyButton,
  copyToClipboard,
  DarkBlueColor,
  StyledParagraph,
  Code,
} from '../DocumentStyles';

const NodeExporter = () => {
  const [copiedCommand, setCopiedCommand] = useState('');

  const nodeExporterDoc = [
    {
      step: 'Step 1',
      description:
        'Pull Node Exporter Docker Image: Open a terminal or command prompt and execute the following command to pull the Node Exporter Docker image from the Docker Hub.',
      command: 'docker pull prom/node-exporter',
    },
    {
      step: 'Step 2',
      description:
        'Run Node Exporter Container: Once the image is pulled, run the Node Exporter container using the following command.',
      command: 'docker run -d --name=node-exporter -p 9100:9100 prom/node-exporter',
      subSteps: [
        {
          cmd: '-d',
          instruction: 'Detached mode, which means the container runs in the background.',
        },
        {
          cmd: '--name=node-exporter ',
          instruction: 'Assigns a name to the container.',
        },
        {
          cmd: '-p 9100:9100',
          instruction: 'Maps port 9100 on the host to port 9100 on the container.',
        },
        {
          cmd: 'prom/node-exporter',
          instruction: 'The name of the Docker image.',
        },
      ],
    },
    {
      step: 'Step 3',
      description:
        'Verify Node Exporter Container: You can verify that the Node Exporter container is running by executing:',
      command: 'docker ps',
      subSteps: [
        {
          instruction:
            'This command will list all running containers, and you should see the node-exporter container in the list.',
        },
      ],
    },
  ];

  return (
    <Section id="nodeExporter" style={{ padding: '3rem 0' }}>
      <SectionTitle>
        <DarkBlueColor>
          <u>NodeExporter</u>
        </DarkBlueColor>
      </SectionTitle>
      <StyledTitle>Installation of Node Exporter using Docker</StyledTitle>
      <StyledParagraph>
        Before starting the installation process, ensure that you have Docker installed on your system. If Docker is not
        installed, please follow the official installation guide for your operating system{' : '}
        <StyledLink href="https://docs.docker.com/get-docker/" target="_blank" rel="noopener noreferrer">
          Install Docker
        </StyledLink>
        &nbsp;
      </StyledParagraph>

      <StyledDescription>
        <StyledTitle>Step-by-Step Installation of Node Exporter using Docker:</StyledTitle>

        {nodeExporterDoc.map((item, index) => (
          <div key={index}>
            <strong>
              <u>{item.step}</u>
            </strong>
            <StyledParagraph>{item.description}</StyledParagraph>
            {item.command && (
              <CodeBlockContainer>
                <Code>{item.command}</Code>
                <Tooltip title={copiedCommand === item.command ? 'Copied!' : 'Copy'} arrow placement="top">
                  <CopyButton onClick={() => copyToClipboard(item.command, () => setCopiedCommand(item.command))}>
                    <CopyAllIcon />
                  </CopyButton>
                </Tooltip>
              </CodeBlockContainer>
            )}
            {item.subSteps &&
              item.subSteps.map((subStep, subIndex) => (
                <div key={subIndex}>
                  <StyledParagraph>
                    <i>
                      <b>{subStep.cmd}</b>
                    </i>{' '}
                    : {subStep.instruction}
                  </StyledParagraph>
                </div>
              ))}
          </div>
        ))}
      </StyledDescription>
    </Section>
  );
};

export default NodeExporter;
