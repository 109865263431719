import React from 'react';
import {
  Section,
  SectionTitle,
  StyledTitle,
  StyledDescription,
  StyledNotes,
  StyledParagraph,
  DarkBlueColor,
} from '../DocumentStyles';

const Dashboard = () => (
  <Section id="dashboard" style={{ padding: '3rem 0' }}>
    <SectionTitle>
      <DarkBlueColor>
        <u>Dashboard</u>
      </DarkBlueColor>
    </SectionTitle>

    <StyledTitle>Getting Started:</StyledTitle>
    <StyledDescription>
      Installation instructions Add a Server Monitor- Based on the OS platform, download and install the server
      monitoring agent and start monitoring your servers Step 1 - SetUp NodeExporter on server -Install node exporter on
      your server -do public your ip Step 2 - SignUp System requirements:
    </StyledDescription>

    <div className="container" id="dashboard">
      <StyledTitle>1: Dashboard</StyledTitle>
      <StyledDescription>
        A dashboard within our monitoring solution is a centralized and user-friendly interface that provides a
        comprehensive overview of key aspects of your subscription details. It serves as a visual command center,
        presenting real-time and relevant information at a glance. The dashboard is designed to empower users with
        actionable insights, allowing them to efficiently monitor the status of their subscribed plans, track IP usage,
        and stay informed about the latest news from the monitoring solution community.
      </StyledDescription>
      <ol className="pointList">
        <li>
          <strong>User Plan Remaining IPs:</strong>
          <StyledParagraph>
            <strong>Purpose:</strong>{' '}
            <ul>
              <li>Display the number of remaining IP addresses based on the user's subscription plan.</li>
            </ul>
          </StyledParagraph>
          <StyledParagraph>
            <strong>Visual Representation:</strong>
            <ul>
              <li> Use a clear and easily understandable visual representation to show the remaining IPs.</li>
            </ul>
          </StyledParagraph>
          <StyledParagraph>
            <strong>Information Details:</strong>
          </StyledParagraph>
          <ul>
            <li>Show the remaining available IPs.</li>
            <li>Show the plan name.</li>
            <li>Consider using color-coding to indicate different subscription plans.</li>
          </ul>
        </li>

        <li>
          <strong>User Payment Invoice Monthly:</strong>
          <StyledParagraph>
            <strong>Purpose:</strong>{' '}
            <ul>
              <li>Provide users with a summary of their monthly payment.</li>
            </ul>
          </StyledParagraph>
          <StyledParagraph>
            <strong>Visual Representation:</strong>{' '}
            <ul>
              <li>Use a graph to show user expense.</li>
            </ul>
          </StyledParagraph>
          <StyledParagraph>
            <strong>Information Details:</strong>
          </StyledParagraph>
          <ul>
            <li>Clearly show the amount spent on the month.</li>
          </ul>
        </li>

        <li>
          <strong>Monitors Status:</strong>
          <StyledParagraph>
            <strong>Purpose:</strong>
            <ul>
              <li> Offer insights into the status of the user's IPs for better resource management.</li>
            </ul>
          </StyledParagraph>
          <StyledParagraph>
            <strong>Visual Representation:</strong>{' '}
            <ul>
              <li>Use a pie chart to differentiate between active and inactive IPs.</li>
            </ul>
          </StyledParagraph>
          <StyledParagraph>
            <strong>Information Details:</strong>
          </StyledParagraph>
          <ul>
            <li>List the number of IP addresses along with their status (active or inactive).</li>
          </ul>
        </li>

        <li>
          <strong>News Section (WatchNod Community):</strong>
          <StyledParagraph>
            <strong>Purpose:</strong> Keep users informed about the latest updates, features, or community news related
            to the monitoring solution.
          </StyledParagraph>
          <StyledParagraph>
            <strong>Visual Representation:</strong> Display the news in a card format.
          </StyledParagraph>
          <StyledParagraph>
            <strong>Information Details:</strong>
          </StyledParagraph>
          <ul>
            <li>Include headlines and summaries from community news.</li>
            <li>Provide publication dates for each news item.</li>
          </ul>
        </li>

        <li>
          <strong>Last 5 Activity:</strong>
          <StyledParagraph>
            <strong>Purpose:</strong> Keep a record of the user's last 5 activities.
          </StyledParagraph>
          <StyledParagraph>
            <strong>Visual Representation:</strong> Display the activity in a tree list format.
          </StyledParagraph>
          <StyledParagraph>
            <strong>Information Details:</strong>
          </StyledParagraph>
          <ul>
            <li>Include activity details.</li>
            <li>Provide activity dates for each item.</li>
          </ul>
        </li>
      </ol>
    </div>

    <div className="container">
      <StyledTitle>2: Monitoring Page</StyledTitle>
      <StyledDescription>
        The Monitor Page stands as a foundational component within our WatchNod, pivotal for acquiring user-inputted
        IPs and port addresses. It facilitates this input through a user-friendly form, where users can conveniently
        submit their monitoring preferences.
      </StyledDescription>
      <h3>Key Features</h3>
      <ol className="pointList">
        <li>
          <strong>User Input Form:</strong>
          <StyledParagraph>
            <strong>Purpose:</strong> A form that allows users to enter specific IPs and port addresses for monitoring
            purposes. Ensures a streamlined and straightforward experience for users to submit their monitoring
            requirements.
          </StyledParagraph>
        </li>

        <li>
          <strong>View Button Navigation:</strong>
          <StyledParagraph>
            <strong>Purpose:</strong> Displayed below the list of monitored items, a "View" button is provided for each
            entry in the monitoring list. Allows users to navigate seamlessly to the Monitoring Graph screen by clicking
            the corresponding "View" button.
          </StyledParagraph>
        </li>

        <li>
          <strong>Delete Button Navigation:</strong>
          <StyledParagraph>
            <strong>Purpose:</strong> Displayed with the view button, a "Delete" button is provided for each entry in
            the monitoring list. Allow users to delete the IPs and PORT address data entry by clicking the corresponding
            "Delete" button.
          </StyledParagraph>
          <StyledParagraph>
            <strong>Notes:</strong> Deleted addresses cannot be recovered.
          </StyledParagraph>
        </li>

        <li>
          <strong>Monitoring List Integration:</strong>
          <StyledParagraph>
            <strong>Purpose:</strong> The Monitor Page consolidates a list of currently monitored items for quick
            reference. Each entry in the list is equipped with a "View" button, enhancing user interaction and
            navigation.
          </StyledParagraph>
          <StyledParagraph>
            <strong>User Interaction Flow:</strong>
          </StyledParagraph>
          <ul>
            <li>Users input IPs and port addresses through the provided form.</li>
            <li>The submitted items are dynamically added to the monitoring list on the same page.</li>
            <li>
              Each entry in the list is accompanied by a "View" button for immediate access to the Monitoring Graph
              screen.
            </li>
            <li>
              Clicking the "View" button initiates a smooth transition, offering users a detailed graphical
              representation of the monitored data.
            </li>
            <li>Each entry in the list is deleted by a "Delete" button near the "View" button.</li>
          </ul>
        </li>
      </ol>
      <div className="overall-Objective">
        The Monitor Page is not just a data entry point but a user-centric interface that simplifies the process of
        defining and accessing monitored items. Its design aims to enhance user experience, ensuring a seamless journey
        from inputting preferences to visualizing real-time monitoring graphs.
      </div>
    </div>

    <div className="container">
      <StyledTitle>3: Node Metrics Page</StyledTitle>
      <StyledDescription>
        The Node-Metrics Page stands as a crucial element in our WatchNod, playing a pivotal role in providing users
        with an in-depth and visual representation of monitored data. This page is dynamically accessed by users through
        the "View" button on the Monitor Page, offering a comprehensive view of graphs and metrics related to the
        specified IPs and port addresses.
      </StyledDescription>
      <h3>Key Features</h3>
      <ol className="pointList">
        <li>
          <strong>Graphical Visualization:</strong>
          <StyledParagraph>
            Displays real-time or historical graphs representing the monitored data, offering users a visual
            understanding of server metrics.
          </StyledParagraph>
        </li>

        <li>
          <strong>IFrame Integration:</strong>
          <StyledParagraph>
            Utilizes an IFrame element to seamlessly embed the graphical content into the Node-Metrics Page. Enables
            users to interact with and analyze monitoring graphs without leaving the page.
          </StyledParagraph>
        </li>

        <li>
          <strong>User-Driven Navigation:</strong>
          <StyledParagraph>
            Accessed by users through the "View" button on the Monitor Page, ensuring a direct and intuitive transition
            from data input to graphical analysis.
          </StyledParagraph>
        </li>

        <li>
          <strong>Dynamic Content:</strong>
          <StyledParagraph>
            The content displayed in the IFrame is dynamically generated based on the user's selected IPs and port
            addresses. Offers flexibility for users to monitor different sets of data on the same Node-Metrics Page.
          </StyledParagraph>
        </li>

        <li>
          <strong>Responsive Design:</strong>
          <StyledParagraph>
            Incorporates a responsive design to adapt seamlessly to various screen sizes and devices, ensuring an
            optimal viewing experience.
          </StyledParagraph>
          <StyledParagraph>
            <strong>User Experience:</strong>
          </StyledParagraph>
          <ul>
            <li>
              Users initiate the transition to the Node-Metrics Page by clicking the "View" button on the Monitor Page.
            </li>
            <li>
              The page dynamically loads an IFrame, embedding graphical content relevant to the selected monitoring
              parameters.
            </li>
            <li>
              Users can interact with the graphs, analyze trends, and make informed decisions directly within the
              Node-Metrics Page.
            </li>
          </ul>
        </li>
      </ol>
      <div className="overall-Objective">
        The Node-Metrics Page serves as a visual hub where users can explore and analyze the monitored data in a
        graphical format. Its integration with IFrames ensures a cohesive user experience, allowing for efficient
        monitoring and analysis without the need for extensive navigation. This page significantly contributes to the
        overall effectiveness of our WatchNod by providing users with actionable insights derived from visual
        representations of their monitored data.
      </div>
    </div>

    <div className="container">
      <StyledTitle>4: Wallet Page</StyledTitle>
      <StyledDescription>
        The Wallet Page serves as a comprehensive financial hub within our WatchNod, designed to empower users with
        insights into their financial transactions and active subscription plans.
      </StyledDescription>
      <section>
        <h3>Wallet page divide into two section:</h3>
      </section>
      <section>
        <h3>Transaction History Section:</h3>

        <ol className="pointList">
          <li>
            <strong>Payment Transaction Table:</strong>
            <StyledParagraph>
              Presents a detailed table showcasing the user's payment transactions. Includes key information such as
              transaction ID, date, amount, and transaction status.
            </StyledParagraph>
          </li>

          <li>
            <strong>Remaining Balance Display:</strong>
            <StyledParagraph>
              Clearly indicates the remaining balance in the user's wallet, providing a real-time snapshot of their
              financial standing.
            </StyledParagraph>
          </li>

          <li>
            <strong>Add Balance Button:</strong>
            <StyledParagraph>
              Features a prominent "Add Balance" button, allowing users to conveniently and securely add funds to their
              wallet.
            </StyledParagraph>
          </li>
        </ol>
      </section>

      <section>
        <h3>Running Plan Section:</h3>

        <ol className="pointList">
          <li>
            <strong>Active Subscription Plans Overview:</strong>
            <StyledParagraph>
              Displays a summarized view of the user's currently active subscription plans. Includes key details such as
              plan type, created date, expiration date.
            </StyledParagraph>
          </li>

          <li>
            <strong>User-Friendly Interface:</strong>
            <StyledParagraph>
              Ensures a user-friendly and intuitive interface, enabling users to easily navigate and comprehend their
              financial and subscription information.
            </StyledParagraph>
          </li>

          <li>
            <strong>Delete Functionality:</strong>
            <StyledParagraph>
              Every plan contains a delete button. The use of the "delete" button allows users to delete the running
              plan. Note: while deleting the plan, the whole data related to that plan is cleared and could not be
              retrieved in the future.
            </StyledParagraph>
          </li>
        </ol>
      </section>
      <section>
        <h3>User Interaction</h3>
        <li>
          <strong>Transaction History Interaction:</strong>
          <StyledParagraph>
            Users can review their transaction history, gaining insights into past payments and the reason for each
            transaction. The "Add Balance" button facilitates a seamless process for users to add funds to their wallet.
          </StyledParagraph>
        </li>

        <li>
          <strong>Running Plan Exploration:</strong>
          <StyledParagraph>
            Users can explore details of their active subscription plans, including plan duration and associated
            features. The user-friendly layout allows for quick and efficient management of subscription-related tasks.
          </StyledParagraph>
        </li>
      </section>
      <div className="overall-Objective">
        The Wallet Page is strategically crafted to offer users a comprehensive overview of their financial transactions
        and ongoing subscription plans. By providing transparency and easy navigation, this page empowers users to
        manage their finances and monitor their active subscription plans with confidence and clarity.
      </div>
    </div>

    <div className="container">
      <StyledTitle>5: Subscription Page</StyledTitle>
      <StyledDescription>
        The Subscription Page is a comprehensive and centralized interface within our WatchNod, showcasing all
        available subscription plans offered to users.
      </StyledDescription>
      <h3>Key Components</h3>

      <ol className="pointList">
        <li>
          <strong>Plan Details Display:</strong>
          <StyledParagraph>
            Presents a detailed list of subscription plans available for users to choose from. Includes essential
            information such as plan type, features, pricing, and any other relevant details.
          </StyledParagraph>
        </li>

        <li>
          <strong>Buy button:</strong>
          <StyledParagraph>
            User can easily buy a plan on clicking the "Buy Plan" button on the left of each plan displayed.
          </StyledParagraph>
        </li>

        <li>
          <strong>Clear Plan Differentiation:</strong>
          <StyledParagraph>
            Clearly distinguishes between various plans, emphasizing unique features and benefits associated with each.
            May use visual elements such as icons or badges to highlight specific plan attributes.
          </StyledParagraph>
        </li>

        <li>
          <strong>Pricing Information:</strong>
          <StyledParagraph>
            Displays transparent pricing information for each subscription plan, providing users with a clear
            understanding of the cost associated with their chosen plan.
          </StyledParagraph>
        </li>

        <li>
          <strong>Subscription Duration:</strong>
          <StyledParagraph>
            Includes the duration of each subscription plan (e.g., monthly, annually), enabling users to make informed
            decisions based on their preferences.
          </StyledParagraph>
        </li>
      </ol>

      <h3>User Interaction</h3>

      <ol className="pointList">
        <li>
          <strong>Plan Selection:</strong>
          <StyledParagraph>
            Users can interact with the Subscription Page to explore details of each plan and make an informed decision
            based on their requirements.
          </StyledParagraph>
        </li>

        <li>
          <strong>Subscription Process:</strong>
          <StyledParagraph>
            Seamless integration with a subscription process, allowing users to subscribe to their chosen plan directly
            by clicking on the "Buy Plan" button on each plan from the Subscription Page.
          </StyledParagraph>
        </li>

        <li>
          <strong>Plan Comparison:</strong>
          <StyledParagraph>
            Users may compare different plans side by side to evaluate features, pricing, and duration before making a
            decision.
          </StyledParagraph>
        </li>
      </ol>

      <StyledNotes>
        <strong>Notes :- </strong> Once a user's plan expires, data for all monitored addresses within that plan will be
        retained for only 7 days. After this 7-day period following the expiration of the plan, all data associated with
        that plan will be permanently deleted and cannot be retrieved in the future.
      </StyledNotes>
      <div className="overall-Objective">
        The Subscription Page serves as a user-friendly gateway for users to explore and select subscription plans that
        align with their monitoring needs. By providing clear and detailed information about each plan, this page
        ensures transparency and facilitates a straightforward subscription process, contributing to a positive user
        experience within our WatchNod.
      </div>
    </div>

    <div className="container">
      <StyledTitle>6: Profile Page</StyledTitle>
      <StyledDescription>
        The Profile Page serves as a centralized hub within our WatchNod, offering users the ability to manage their
        personal information and address details efficiently.
      </StyledDescription>
      <section>
        <h3>Personal Details Section:</h3>

        <li>
          <strong>Display of Personal Information:</strong>
          <StyledParagraph>
            Presents a clear and organized display of the user's personal details. Includes information such as name,
            email address, contact number, and any other relevant details.
          </StyledParagraph>
        </li>

        <li>
          <strong>Edit Button:</strong>
          <StyledParagraph>
            Features an "Edit" button, providing users with the ability to update and modify their personal details as
            needed. Ensures a seamless and user-friendly experience for personal information management.
          </StyledParagraph>
        </li>
      </section>

      <section>
        <h3>Address Form Section:</h3>

        <ol className="pointList">
          <li>
            <strong>User Input Form:</strong>
            <StyledParagraph>
              Contains a form allowing users to input or update their address details. Fields may include address line,
              city, state, postal code, and any other relevant information.
            </StyledParagraph>
          </li>

          <li>
            <strong>Efficient Data Collection:</strong>
            <StyledParagraph>
              Streamlines the process of collecting accurate and up-to-date address information from users.
            </StyledParagraph>
          </li>

          <li>
            <strong>User-Friendly Interface:</strong>
            <StyledParagraph>
              Ensures a user-friendly interface for easy navigation and data entry within the address form.
            </StyledParagraph>
          </li>

          <li>
            <strong>Submission Button:</strong>
            <StyledParagraph>
              Includes a "Submit" or "Save" button for users to confirm and save their updated address details.
            </StyledParagraph>
          </li>
        </ol>
      </section>

      <h3>User Interaction</h3>

      <ol className="pointList">
        <li>
          <strong>Editing Personal Details:</strong>
          <StyledParagraph>
            Users can click the "Edit" button in the Personal Details section to initiate the editing process. The
            editing interface allows users to modify existing personal details.
          </StyledParagraph>
        </li>

        <li>
          <strong>Address Form Submission:</strong>
          <StyledParagraph>
            Users can input or update their address details in the provided form. The submission of the form updates the
            user's address information in the system.
          </StyledParagraph>
        </li>
      </ol>
      <div className="overall-Objective">
        The Profile Page is designed with a dual focus on user convenience and accurate data management. By seamlessly
        incorporating the ability to edit personal details and update address information, this page empowers users to
        maintain and customize their profile information with ease. The user-friendly interface and clear navigation
        ensure a positive user experience for profile management within our WatchNod.
      </div>
    </div>
  </Section>
);

export default Dashboard;
