import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, CssBaseline, Divider } from '@mui/material';
import styled from 'styled-components';
import { NavbarPage } from '../Navbar/Navbar';
import { Footer } from '../Footer';
import { AboutUs, ContactUs } from './section/AboutPage';
import aboutImage from '../images/about2.jpg';

const StyledCard = styled.div`
  background-color: #f7f7f7;
  padding: 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
`;

const TopMargin = styled.div`
  margin-top: 100px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const ImageSide = styled.div`
  flex: 1;
  background-image: url(${aboutImage});
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const FormSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function About() {
  return (
    <>
      <Helmet>
        <title> About-Us | WatchNod </title>
      </Helmet>
      <NavbarPage />
      <TopMargin />
      <CssBaseline />
      <StyledCard>
        <Card>
          <Wrapper>
            <ImageSide />
            <FormSide>
              <div className="container" id="contact-us">
                <ContactUs />
              </div>
            </FormSide>
          </Wrapper>
          <Divider />
          <AboutUs />
        </Card>
      </StyledCard>
      <div style={{ position: 'relative', bottom: 0, width: '100%' }}>
        <Footer />
      </div>
    </>
  );
}

export default About;
