import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Container, Stack, Typography, Dialog } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { NewsUpdatePage, NewsPage } from '../sections/@dashboard/newsUpdate';

const StyledCard = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

export default function NewsUpdate() {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const navigate = useNavigate();

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    navigate('/dashboard/news-update');
  };

  return (
    <>
      <Helmet>
        <title>News Update | WatchNod</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            News Report Update
          </Typography>
          <Button variant="contained" onClick={openForm}>
            Add News
          </Button>
        </Stack>
        <NewsPage openForm={openForm} key={reloadData} />

        <Dialog open={isFormOpen} onClose={closeForm}>
          <StyledCard>
            <NewsUpdatePage closeForm={closeForm} reloadData={() => setReloadData(!reloadData)} />
          </StyledCard>
        </Dialog>
      </Container>
    </>
  );
}
