// authUtils.js

import jwtDecode from 'jwt-decode';

// Check if the token is expired
export const isTokenExpired = () => {
  const token = localStorage.getItem('token');
  if (!token) return true;

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error('Error decoding the token:', error);
    return true;
  }
};

// Check if the user is logged in
export const isLoggedIn = () =>
  !!localStorage.getItem('token') &&
  !!localStorage.getItem('userId') &&
  !!localStorage.getItem('role') &&
  !isTokenExpired();

// Get the user role
export const getUserRole = () => localStorage.getItem('role');
