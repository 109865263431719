import React, { useState, useEffect, useRef } from 'react';
import {
  styled,
  useTheme,
  Drawer,
  Divider,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import ChatIcon from '@mui/icons-material/Chat';
import DescriptionIcon from '@mui/icons-material/Description';
import LockIcon from '@mui/icons-material/Lock';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Colors } from '../style/theme';

const drawerWidth = 205;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 5),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const DrawerItem = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const drawerRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleItemClick = (index) => {
    setActiveItem(index);
    setOpen(false);
  };

  const itemList = [
    { text: 'Home', icon: <HomeIcon />, to: '/' },
    { text: 'About', icon: <InfoIcon />, to: '/about' },
    { text: 'Testimonial', icon: <ChatIcon />, to: '/testimonial' },
    { text: 'Document', icon: <DescriptionIcon />, to: '/document' },
    { text: 'LogIn', icon: <LockIcon />, to: '/login' },
    { text: 'SignUp', icon: <PersonAddIcon />, to: '/signup' },
  ];

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        sx={{ ...(open && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        ref={drawerRef}
        sx={{
          flexGrow: 1,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {itemList.map((item, index) => {
            const { text, to, icon } = item;
            const isActive = activeItem === index;
            return (
              <ListItem
                key={text}
                component={Link}
                to={to}
                sx={{
                  color: isActive ? Colors.white : Colors.dim_grey,
                  backgroundColor: isActive ? Colors.shaft : 'transparent',
                  border: 'none',
                  borderRadius: '5px',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: isActive ? Colors.dark_shaft : Colors.dark_shaft,
                    color: isActive ? Colors.white : Colors.white,
                  },
                }}
                onClick={() => handleItemClick(index)}
              >
                <ListItemIcon
                  sx={{
                    color: isActive ? Colors.white : Colors.grey,
                    backgroundColor: isActive ? Colors.shaft : 'transparent',
                    border: 'none',
                    borderRadius: '5px',
                    '&:hover': {
                      backgroundColor: isActive ? Colors.dark_shaft : Colors.shaft,
                      color: isActive ? Colors.white : Colors.white,
                    },
                  }}
                >
                  {icon}
                </ListItemIcon>

                <ListItemText primary={text} />
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </>
  );
};

export default DrawerItem;
