import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import MonitorGraph from '../sections/@dashboard/monitorGraph/MonitorGraph';

export default function MonitorGraphPage() {
  return (
    <>
      <Helmet>
        <title>MonitorGraph| WatchNod</title>
      </Helmet>

      <Container maxWidth="xl">
        <MonitorGraph />
      </Container>
    </>
  );
}
