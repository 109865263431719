import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  TextareaAutosize,
  Button,
  Container,
  Typography,
  Paper,
  Grid,
  Card,
  CircularProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

function SubscriptionFormPage(props) {
  SubscriptionFormPage.propTypes = {
    closeForm: PropTypes.func.isRequired,
  };
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    description: '',
  });

  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getUserById?userId=${userId}`, config);
        const userData = response.data.data;
        setUserData(userData);
        setFormData({
          name: userData.fullName || '',
          email: userData.email || '',
          phoneNumber: userData.phoneNumber || '',
          description: userData.description || '',
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUser();
  }, [userId, token]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setFormData({
      name: userData.fullName || '',
      email: userData.email || '',
      phoneNumber: userData.phoneNumber || '',
      description: userData.description || '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const urlParts = window.location.pathname.split('/');
      const lastPart = urlParts[urlParts.length - 1];
      if (urlParts.length >= 3 && lastPart) {
        const subsciptionPlanId = lastPart;
        const updatedFormData = {
          ...formData,
          userId,
          subsciptionPlanId,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/submitContactSalesForm`,
          updatedFormData,
          config
        );
        setUserData(updatedFormData);
        setIsEditing(false);
        toast.success(response.data.message);
        props.closeForm();
      } else {
        toast.error('Invalid URL format.');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
      toast.error('An error occurred while submitting the form.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Card>
      <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <Paper elevation={3} style={{ padding: '2rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Full Name"
                  name="name"
                  type="text"
                  required
                  disabled={!isEditing}
                  value={formData.name}
                  onChange={handleChange}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  required
                  disabled={!isEditing}
                  value={formData.email}
                  onChange={handleChange}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  type="text"
                  required
                  disabled={!isEditing}
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  minRows={4}
                  placeholder="Description"
                  name="description"
                  required
                  disabled={!isEditing}
                  value={formData.description}
                  onChange={handleChange}
                  sx={{ width: '100%' }}
                  style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '4px',
                    border: '1px solid #ffff',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    transition: 'border-color 0.3s ease-in-out',
                    '&:focus': {
                      borderColor: '#007bff',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                  {isLoading ? (
                    <CircularProgress />
                  ) : isEditing ? (
                    <>
                      <Button
                        variant="contained"
                        onClick={handleCancelEdit}
                        color="secondary"
                        style={{ marginRight: '1rem' }}
                      >
                        Cancel
                      </Button>
                      <Button variant="contained" type="submit" color="primary">
                        Submit
                      </Button>
                    </>
                  ) : (
                    <Button variant="contained" onClick={handleEdit} color="primary">
                      Edit
                    </Button>
                  )}
                  <Button variant="contained" onClick={() => props.closeForm()} style={{ marginLeft: '1rem' }}>
                    Close
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </Card>
  );
}

export default SubscriptionFormPage;
