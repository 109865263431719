import React, { useState } from 'react';
import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';

function ForgotPassword() {
  const [formData, setFormData] = useState({ email: '' });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showLoadingPage, setShowLoadingPage] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setError('');
    setSuccessMessage('');
    setShowLoadingPage(false);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.match(emailRegex)) {
      setError('Invalid email format. Please enter a valid email address.');
      return;
    }

    try {
      setIsLoading(true);
      const payload = JSON.stringify({ email: formData.email });
      const response = await fetch(`${process.env.REACT_APP_API_URL}/forgotPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: payload,
      });
      const data = await response.json();
      if (data.sussess === true) {
        setSuccessMessage('Reset password email sent successfully.');
        toast.success(data.message);
        setShowLoadingPage(true);
      } else {
        setError(data.message);
        toast.error(data.message);
      }
    } catch (error) {
      setError('An error occurred while sending the reset password email.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {!showLoadingPage ? (
        <>
          <Stack spacing={3}>
            <TextField
              name="email"
              label="Email address"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
            />
          </Stack>

          {error && (
            <Typography variant="body2" color="error" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}

          {successMessage && (
            <Typography variant="body2" color="success" sx={{ mb: 2 }}>
              {successMessage}
            </Typography>
          )}

          <br />

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Button
                  fullWidth
                  size="large"
                  type="button"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isLoading.toString()}
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </>
      ) : (
        <div>
          <Typography variant="h6">Please Check Your Email!</Typography>
        </div>
      )}
    </>
  );
}

export default ForgotPassword;
