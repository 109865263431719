import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Card, Container, CssBaseline, Typography } from '@mui/material';
import { Footer } from '../../Footer';
import { NavbarPage } from '../../Navbar/Navbar';

const StyledCard = styled(Card)`
  background-color: #f0f8ff; /* Light blue background */
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px auto;
  text-align: justify;
`;

const TopMargin = styled.div`
  margin-top: 100px;
`;

const InnerDiv = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #fff;

  @media (min-width: 520px) {
    width: calc(50% - 20px);
    margin-right: 20px;

    &:nth-child(even) {
      margin-right: 0;
    }
  }
`;

const MainHeader = styled.header`
  text-align: center;
  margin-bottom: 30px;

  h3 {
    color: #333;
    margin-bottom: 10px;
  }

  span {
    color: #666;
    font-size: 18px;
  }
`;

const FlexContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

function Documentation() {
  return (
    <>
      <Helmet>
        <title> Document | WatchNod </title>
      </Helmet>
      <NavbarPage />
      <TopMargin />
      <CssBaseline />
      <StyledCard>
        <Container>
          <MainHeader>
            <Typography variant="h3" gutterBottom>
              WatchNod Documentation
            </Typography>
            <span>Welcome to our comprehensive documentation for WatchNod.</span>
          </MainHeader>

          <FlexContainer>
            <InnerDiv>
              <section id="section1">
                <h2>Getting Started</h2>
                <p>Learn how to get started with our WatchNod Application.</p>
                <Link to="/documentDetail#overview">Learn More</Link>
              </section>
            </InnerDiv>

            <InnerDiv>
              <section id="section2">
                <h2>Usage</h2>
                <p>Learn how to use the application to monitor your systems and networks.</p>
                <Link to="/documentDetail#dashboard">Learn More</Link>
              </section>
            </InnerDiv>
          </FlexContainer>

          <FlexContainer>
            <InnerDiv>
              <section id="section3">
                <h2>Configuration</h2>
                <p>Configure the application to monitor your systems effectively.</p>
                <Link to="/documentDetail#dashboard">Learn More</Link>
              </section>
            </InnerDiv>

            <InnerDiv>
              <section id="section4">
                <h2>Advanced Features</h2>
                <p>Explore advanced features and customization options.</p>
                <Link to="/documentDetail#contactSale">Learn More</Link>
              </section>
            </InnerDiv>
          </FlexContainer>

          <FlexContainer>
            <InnerDiv>
              <section id="section5">
                <h2>Set Up for the public IP</h2>
                <p>Install NodeExporter.</p>
                <Link to="/documentDetail#publicIp">Learn More</Link>
              </section>
            </InnerDiv>

            <InnerDiv>
              <section id="section6">
                <h2>Set Up for the Private/NAT-IP</h2>
                <p>Integrating Pushprox with Application.</p>
                <Link to="/documentDetail#publicIpAndNatIp">Learn More</Link>
              </section>
            </InnerDiv>


          </FlexContainer>

          <FlexContainer>
            <InnerDiv>
              <section id="section7">
                <h2>Installation of Node Exporter using Docker</h2>
                <p>Step-by-Step Installation of Node Exporter using Docker:.</p>
                <Link to="/documentDetail#nodeExporter">Learn More</Link>
              </section>
            </InnerDiv>
            <InnerDiv>
              <section id="section8">
                <h2>Data Retention Policy</h2>
                <p>Click on learn more to view our detailed privacy policy.</p>
                <Link to="/documentDetail#policy">Learn More</Link>
              </section>
            </InnerDiv>


          </FlexContainer>
          <FlexContainer>
            <InnerDiv>
              <section id="section9">
                <h2>Frequently Asked Questions</h2>
                <p>Click on learn more to read all FAQs.</p>
                <Link to="/documentDetail#faq">Learn More</Link>
              </section>
            </InnerDiv>
          </FlexContainer>
        </Container>
      </StyledCard>
      <div style={{ position: 'relative', bottom: 0, width: '100%' }}>
        <Footer />
      </div>
    </>
  );
}

export default Documentation;
