import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Tooltip } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default function Header({ onOpenNav, isAdmin }) {
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {!isAdmin && (
          <Tooltip title="Search" arrow>
            <span>
              <Searchbar />
            </span>
          </Tooltip>
        )}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <Tooltip title="Toggle Theme" arrow>
            <IconButton onClick={toggleDarkMode} aria-label="toggle dark mode">
              <Brightness4Icon />
            </IconButton>
          </Tooltip> */}

          {/* <LanguagePopover /> */}
          <Tooltip title="Notification" arrow>
            <span>
              <NotificationsPopover />
            </span>
          </Tooltip>

          <Tooltip title="Profile" arrow>
            <span>
              <AccountPopover />
            </span>
          </Tooltip>
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
