import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import {
  Container,
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CloseIcon from '@mui/icons-material/Close';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { AddressDataTable, AddressForm } from '../sections/@dashboard/monitor';
import { StyledLink, StyledDescription, StyledParagraph, Code } from '../landingPage/page/Documentation/DocumentStyles';

const StyledCard = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: justify;
`;

const SpaceBetweenComponents = styled.div`
  margin-bottom: 20px;
`;

const CodeBlockContainer = styled.div`
  position: relative;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
  font-family: monospace;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-x: auto;

  @media (max-width: 768px) {
    padding: 8px;
    margin: 8px 0;
  }
`;

const CopyButton = styled(IconButton)`
  position: absolute;
  top: 6px;
  right: 10px;
  background-color: #d3d3d3;
  color: #fff;
  padding: 5px;

  &:hover {
    background-color: #049ee0;
  }

  @media (max-width: 768px) {
    top: 5px;
    right: 8px;
  }
`;

const nodeExporterDoc = [
  {
    step: 'Step 1',
    description:
      'Pull Node Exporter Docker Image: Open a terminal or command prompt and execute the following command to pull the Node Exporter Docker image from the Docker Hub.',
    command: 'docker pull prom/node-exporter',
  },
  {
    step: 'Step 2',
    description:
      'Run Node Exporter Container: Once the image is pulled, run the Node Exporter container using the following command.',
    command: 'docker run -d --name=node-exporter -p 9100:9100 prom/node-exporter',
    subSteps: [
      {
        cmd: '-d',
        instruction: 'Detached mode, which means the container runs in the background.',
      },
      {
        cmd: '--name=node-exporter ',
        instruction: 'Assigns a name to the container.',
      },
      {
        cmd: '-p 9100:9100',
        instruction: 'Maps port 9100 on the host to port 9100 on the container.',
      },
      {
        cmd: 'prom/node-exporter',
        instruction: 'The name of the Docker image.',
      },
    ],
  },
  {
    step: 'Step 3',
    description:
      'Verify Node Exporter Container: You can verify that the Node Exporter container is running by executing:',
    command: 'docker ps',
    subSteps: [
      {
        instruction:
          'This command will list all running containers, and you should see the node-exporter container in the list.',
      },
    ],
  },
];

const pushProxyDoc = [
  {
    step: 'Step 1',
    description: 'First, you need to pull the Pushprox Docker image from the Docker registry.',
    command: 'docker pull prometheuscommunity/pushprox',
  },
  {
    step: 'Step 2',
    description:
      "Run the Pushprox container in detached mode using the host network. This allows the container to use the host's network stack, which is often required for proper communication with the Pushprox server.",
    command:
      'docker run -d --network host --name pushprox-client --entrypoint /app/pushprox-client prometheuscommunity/pushprox --proxy-url=http://164.52.207.135:8080/',
  },
  {
    step: 'Step 3',
    description: 'Get the hostname inside the container.',
    command: 'hostname',
  },
];

export default function MonitorPage() {
  const [reloadData, setReloadData] = useState(false);
  const [openPopup, setOpenPopup] = useState(true);
  const [neverShowAgain, setNeverShowAgain] = useState(false);
  const [copiedCommand, setCopiedCommand] = useState('');
  const [expandedPanel, setExpandedPanel] = useState(false);

  const handleClosePopup = () => {
    if (neverShowAgain) {
      localStorage.setItem('neverShowPopup', 'true');
    }
    setOpenPopup(false);
  };

  const handleCheckboxChange = (event) => {
    setNeverShowAgain(event.target.checked);
  };

  const copyToClipboard = (text, callback) => {
    navigator.clipboard.writeText(text).then(callback);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  useEffect(() => {
    const neverShowPopup = localStorage.getItem('neverShowPopup');
    if (neverShowPopup === 'true') {
      setOpenPopup(false);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Monitor | Watch Node UI</title>
      </Helmet>
      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            padding: '1%',
            borderRadius: '12px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">Complete Guide for Setting Up a Public-IP or Private/NAT-IP</Typography>
          <IconButton edge="end" color="inherit" onClick={handleClosePopup}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          dividers
          sx={{
            maxHeight: '60vh',
            overflowY: 'auto',
          }}
        >
          <Accordion expanded={expandedPanel === 'panel1'} onChange={handleAccordionChange('panel1')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              <Typography variant="h5">For Linux OS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                <Typography variant="h6">For Public-IP </Typography>
                  <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1.1d-content"
                  id="panel1.1d-header"
                >
                  <Typography >Step-1: Installation of Node Exporter using Docker</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Before starting the installation process, ensure that you have Docker installed on your system. If
                    Docker is not installed, please follow the official installation guide for your operating system{' '}
                    {' : '}
                    <StyledLink href="https://docs.docker.com/get-docker/" target="_blank" rel="noopener noreferrer">
                      Install Docker
                    </StyledLink>
                    &nbsp;
                    <StyledDescription>
                      <h4>Step-by-Step Installation of Node Exporter using Docker:</h4>
                      <ol>
                        {nodeExporterDoc.map((item, index) => (
                          <li key={index}>
                            <StyledParagraph>{item.description}</StyledParagraph>
                            {item.command && (
                              <CodeBlockContainer>
                                <Code>{item.command}</Code>
                                <Tooltip
                                  title={copiedCommand === item.command ? 'Copied!' : 'Copy'}
                                  arrow
                                  placement="top"
                                >
                                  <CopyButton
                                    onClick={() => copyToClipboard(item.command, () => setCopiedCommand(item.command))}
                                  >
                                    <CopyAllIcon />
                                  </CopyButton>
                                </Tooltip>
                              </CodeBlockContainer>
                            )}
                            {item.subSteps &&
                              item.subSteps.map((subStep, subIndex) => (
                                <div key={subIndex}>
                                  <StyledParagraph>
                                    <i>
                                      <b>{subStep.cmd}</b>
                                    </i>{' '}
                                    : {subStep.instruction}
                                  </StyledParagraph>
                                </div>
                              ))}
                          </li>
                        ))}
                      </ol>
                    </StyledDescription>
                  </Typography>
                </AccordionDetails>
              </Accordion>
                </li>
                <li>
                <Typography variant="h6">For Private/NAT-IP </Typography>
                <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1.1d-content"
                  id="panel1.1d-header"
                >
                  <Typography >Step-1: Installation of Node Exporter using Docker</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Before starting the installation process, ensure that you have Docker installed on your system. If
                    Docker is not installed, please follow the official installation guide for your operating system{' '}
                    {' : '}
                    <StyledLink href="https://docs.docker.com/get-docker/" target="_blank" rel="noopener noreferrer">
                      Install Docker
                    </StyledLink>
                    &nbsp;
                    <StyledDescription>
                      <h4>Step-by-Step Installation of Node Exporter using Docker:</h4>
                      <ol>
                        {nodeExporterDoc.map((item, index) => (
                          <li key={index}>
                            <StyledParagraph>{item.description}</StyledParagraph>
                            {item.command && (
                              <CodeBlockContainer>
                                <Code>{item.command}</Code>
                                <Tooltip
                                  title={copiedCommand === item.command ? 'Copied!' : 'Copy'}
                                  arrow
                                  placement="top"
                                >
                                  <CopyButton
                                    onClick={() => copyToClipboard(item.command, () => setCopiedCommand(item.command))}
                                  >
                                    <CopyAllIcon />
                                  </CopyButton>
                                </Tooltip>
                              </CodeBlockContainer>
                            )}
                            {item.subSteps &&
                              item.subSteps.map((subStep, subIndex) => (
                                <div key={subIndex}>
                                  <StyledParagraph>
                                    <i>
                                      <b>{subStep.cmd}</b>
                                    </i>{' '}
                                    : {subStep.instruction}
                                  </StyledParagraph>
                                </div>
                              ))}
                          </li>
                        ))}
                      </ol>
                    </StyledDescription>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                  <Typography >Step-2: Set Up for the Private/NAT-IP</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Pushprox is a service that allows you to expose local applications to Prometheus for monitoring.
                    Follow these steps to integrate Pushprox into your application using Docker.
                  </Typography>
                  <ul>
                    <b>Prerequisites</b>
                    <li>Docker installed on your machine.</li>
                    <li>
                      Access to the Pushprox server with &nbsp;
                      <StyledLink href="http://164.52.207.135:8080/" target="_blank" rel="noopener noreferrer">
                        http://164.52.207.135:8080/
                      </StyledLink>
                      &nbsp; Pushprox server URL.
                    </li>
                    <li>Your application's hostname or Fully Qualified Domain Name (FQDN).</li>
                  </ul>
                  <StyledDescription>
                    <h4>Steps to Integrate Pushprox:</h4>
                    <ol>
                      {pushProxyDoc.map((item, index) => (
                        <li key={index}>
                          <StyledParagraph>{item.description}</StyledParagraph>
                          {item.command && (
                            <CodeBlockContainer>
                              <Code>{item.command}</Code>
                              <Tooltip
                                title={copiedCommand === item.command ? 'Copied!' : 'Copy'}
                                arrow
                                placement="top"
                              >
                                <CopyButton
                                  onClick={() => copyToClipboard(item.command, () => setCopiedCommand(item.command))}
                                >
                                  <CopyAllIcon />
                                </CopyButton>
                              </Tooltip>
                            </CodeBlockContainer>
                          )}
                          {item.subSteps &&
                            item.subSteps.map((subStep, subIndex) => (
                              <div key={subIndex}>
                                <StyledParagraph>
                                  <i>
                                    <b>{subStep.cmd}</b>
                                  </i>{' '}
                                  : {subStep.instruction}
                                </StyledParagraph>
                              </div>
                            ))}
                        </li>
                      ))}
                    </ol>
                  </StyledDescription>
                </AccordionDetails>
              </Accordion>
                </li>
              </ul>
              
            </AccordionDetails>
          </Accordion>
          <Divider />
          <Accordion expanded={expandedPanel === 'panel2'} onChange={handleAccordionChange('panel2')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
              <Typography variant="h5">For Mac OS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                <Typography variant="h6">For Public-IP </Typography>
                  <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1.1d-content"
                  id="panel1.1d-header"
                >
                  <Typography >Step-1: Installation of Node Exporter using Docker</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Before starting the installation process, ensure that you have Docker installed on your system. If
                    Docker is not installed, please follow the official installation guide for your operating system{' '}
                    {' : '}
                    <StyledLink href="https://docs.docker.com/get-docker/" target="_blank" rel="noopener noreferrer">
                      Install Docker
                    </StyledLink>
                    &nbsp;
                    <StyledDescription>
                      <h4>Step-by-Step Installation of Node Exporter using Docker:</h4>
                      <ol>
                        {nodeExporterDoc.map((item, index) => (
                          <li key={index}>
                            <StyledParagraph>{item.description}</StyledParagraph>
                            {item.command && (
                              <CodeBlockContainer>
                                <Code>{item.command}</Code>
                                <Tooltip
                                  title={copiedCommand === item.command ? 'Copied!' : 'Copy'}
                                  arrow
                                  placement="top"
                                >
                                  <CopyButton
                                    onClick={() => copyToClipboard(item.command, () => setCopiedCommand(item.command))}
                                  >
                                    <CopyAllIcon />
                                  </CopyButton>
                                </Tooltip>
                              </CodeBlockContainer>
                            )}
                            {item.subSteps &&
                              item.subSteps.map((subStep, subIndex) => (
                                <div key={subIndex}>
                                  <StyledParagraph>
                                    <i>
                                      <b>{subStep.cmd}</b>
                                    </i>{' '}
                                    : {subStep.instruction}
                                  </StyledParagraph>
                                </div>
                              ))}
                          </li>
                        ))}
                      </ol>
                    </StyledDescription>
                  </Typography>
                </AccordionDetails>
              </Accordion>
                </li>
                <li>
                <Typography variant="h6">For Private/NAT-IP </Typography>
                <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1.1d-content"
                  id="panel1.1d-header"
                >
                  <Typography >Step-1: Installation of Node Exporter using Docker</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Before starting the installation process, ensure that you have Docker installed on your system. If
                    Docker is not installed, please follow the official installation guide for your operating system{' '}
                    {' : '}
                    <StyledLink href="https://docs.docker.com/get-docker/" target="_blank" rel="noopener noreferrer">
                      Install Docker
                    </StyledLink>
                    &nbsp;
                    <StyledDescription>
                      <h4>Step-by-Step Installation of Node Exporter using Docker:</h4>
                      <ol>
                        {nodeExporterDoc.map((item, index) => (
                          <li key={index}>
                            <StyledParagraph>{item.description}</StyledParagraph>
                            {item.command && (
                              <CodeBlockContainer>
                                <Code>{item.command}</Code>
                                <Tooltip
                                  title={copiedCommand === item.command ? 'Copied!' : 'Copy'}
                                  arrow
                                  placement="top"
                                >
                                  <CopyButton
                                    onClick={() => copyToClipboard(item.command, () => setCopiedCommand(item.command))}
                                  >
                                    <CopyAllIcon />
                                  </CopyButton>
                                </Tooltip>
                              </CodeBlockContainer>
                            )}
                            {item.subSteps &&
                              item.subSteps.map((subStep, subIndex) => (
                                <div key={subIndex}>
                                  <StyledParagraph>
                                    <i>
                                      <b>{subStep.cmd}</b>
                                    </i>{' '}
                                    : {subStep.instruction}
                                  </StyledParagraph>
                                </div>
                              ))}
                          </li>
                        ))}
                      </ol>
                    </StyledDescription>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                  <Typography >Step-2: Set Up for the Private/NAT-IP</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Pushprox is a service that allows you to expose local applications to Prometheus for monitoring.
                    Follow these steps to integrate Pushprox into your application using Docker.
                  </Typography>
                  <ul>
                    <b>Prerequisites</b>
                    <li>Docker installed on your machine.</li>
                    <li>
                      Access to the Pushprox server with &nbsp;
                      <StyledLink href="http://164.52.207.135:8080/" target="_blank" rel="noopener noreferrer">
                        http://164.52.207.135:8080/
                      </StyledLink>
                      &nbsp; Pushprox server URL.
                    </li>
                    <li>Your application's hostname or Fully Qualified Domain Name (FQDN).</li>
                  </ul>
                  <StyledDescription>
                    <h4>Steps to Integrate Pushprox:</h4>
                    <ol>
                      {pushProxyDoc.map((item, index) => (
                        <li key={index}>
                          <StyledParagraph>{item.description}</StyledParagraph>
                          {item.command && (
                            <CodeBlockContainer>
                              <Code>{item.command}</Code>
                              <Tooltip
                                title={copiedCommand === item.command ? 'Copied!' : 'Copy'}
                                arrow
                                placement="top"
                              >
                                <CopyButton
                                  onClick={() => copyToClipboard(item.command, () => setCopiedCommand(item.command))}
                                >
                                  <CopyAllIcon />
                                </CopyButton>
                              </Tooltip>
                            </CodeBlockContainer>
                          )}
                          {item.subSteps &&
                            item.subSteps.map((subStep, subIndex) => (
                              <div key={subIndex}>
                                <StyledParagraph>
                                  <i>
                                    <b>{subStep.cmd}</b>
                                  </i>{' '}
                                  : {subStep.instruction}
                                </StyledParagraph>
                              </div>
                            ))}
                        </li>
                      ))}
                    </ol>
                  </StyledDescription>
                </AccordionDetails>
              </Accordion>
                </li>
              </ul>
              
            </AccordionDetails>
          </Accordion>
          <Divider />
          <Accordion expanded={expandedPanel === 'panel3'} onChange={handleAccordionChange('panel3')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
              <Typography variant="h5">For Window OS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">Currently it will not work on docker desktop.</Typography>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormControlLabel
            control={<Checkbox checked={neverShowAgain} onChange={handleCheckboxChange} />}
            label={
              <Typography variant="body2" color="textSecondary">
                Never show this again
              </Typography>
            }
          />
          <Button onClick={handleClosePopup} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            Monitor
          </Typography>
        </Stack>
        <StyledCard>
          <AddressForm reloadData={() => setReloadData(!reloadData)} />
          <SpaceBetweenComponents />
          <AddressDataTable key={reloadData} />
        </StyledCard>
      </Container>
    </>
  );
}
