import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { Container, Typography, Stack, Dialog } from '@mui/material';
import { ContactSalesData, CustomizePlan } from '../sections/@dashboard/contactSale';

const StyledCard = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;	
export default function ContactSales() {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  const navigate = useNavigate();


  const openForm = (id) => {
    setSelectedUser(id);
    setIsFormOpen(true);
  };
  const closeForm = () => {
    setIsFormOpen(false);
    navigate('/dashboard/contact-sales');
  };

  return (
    <>
      <Helmet>
        <title>Contact Sale | WatchNod </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            User Customize Plan
          </Typography>
        </Stack>

        <ContactSalesData openForm={openForm} key={reloadData} />
        
        <Dialog open={isFormOpen} onClose={closeForm}>
          <StyledCard>
            <CustomizePlan closeForm={closeForm} selectedUser={selectedUser} reloadData={() => setReloadData(!reloadData)}/>
          </StyledCard>
        </Dialog>
      </Container>
    </>
  );
}
