import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../../../layouts/shared/ConfirmationDialog';

const cardStyles = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  border: '1px solid #2196F3',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
};
const headerStyles = {
  fontSize: '1.3rem',
  fontWeight: 'bold',
  color: '#2196F3',
  textAlign: 'center',
};

const priceStyles = {
  fontSize: '1.2rem',
  color: '#E91E63',
};

const descriptionStyles = {
  textAlign: 'center',
  marginTop: '16px',
  color: '#777',
};

const listItemStyles = {
  padding: '0',
  marginBottom: '5px',
  textAlign: 'center',
};
const buyButtonStyles = {
  backgroundColor: '#2196F3',
  color: 'white',
  cursor: 'pointer',
  marginRight: '4%',
};
const deleteButtonStyles = {
  backgroundColor: '#FF0000',
  color: 'white',
  cursor: 'pointer',
  marginRight: '4%',
};

const CustomizedPlanBox = (props) => {
  const [data, setData] = useState([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('token');

  const fetchData = useCallback(async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/getCustomizedPlansByUserId?userId=${localStorage.getItem(
        'userId'
      )}`;
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();

      if (responseData.success && responseData.data.length > 0) {
        setData(responseData.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleBuyPlan = (plan, e) => {
    e.preventDefault();
    if (plan._id) {
      props.openPurchaseCustomizeForm(plan._id);
      const encodedSubscriptionId = encodeURIComponent(plan._id);
      const encodedSubscriptionName = 'Customize Plan';
      const encodedSubscriptionAmount = encodeURIComponent(plan.SubscriptionAmount);
      navigate(
        `/dashboard/subscription/${encodedSubscriptionId}/${encodedSubscriptionName}/${encodedSubscriptionAmount}`
      );
    } else {
      toast(`Plan with ID ${plan._id} not getting response`);
    }
  };

  const handleDeletePlan = (plan, e) => {
    e.preventDefault();
    setItemIdToDelete(plan._id);
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsConfirmationDialogOpen(false);
    setIsLoading(true);
    try {
      const cleanToken = token.replace(/"/g, '');
      const config = {
        headers: {
          Authorization: `Bearer ${cleanToken}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/deleteCustomPlanByUser`,
        { customPlanId: itemIdToDelete },
        config
      );
      if (response.data.success === true) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      fetchData();
    } catch (error) {
      toast.error(error.response.data.message);
      console.error('Error deleting address:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmationDialogOpen(false);
    setItemIdToDelete(null);
  };

  CustomizedPlanBox.propTypes = {
    openPurchaseCustomizeForm: PropTypes.func.isRequired,
  };

  return (
    <Card>
      <CardContent>
        <Paper>
          <Typography variant="h6" style={{ paddingBottom: '10px' }}>
            Customize Plans
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                {data.length === 0 ? (
                  <p>No Plans</p>
                ) : (
                  <Grid container spacing={4}>
                    {data.map((plan, index) => (
                      <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card elevation={4} style={cardStyles}>
                          <CardContent>
                            <Typography variant="h4" style={headerStyles} gutterBottom>
                              Pricing Plan
                            </Typography>
                            <Typography variant="h4" style={priceStyles} align="center">
                              ${plan.SubscriptionAmount || '__'} for {plan.subscriptionDays || '__'} Days
                            </Typography>
                            <Typography variant="body2" style={descriptionStyles} paragraph>
                              Created Date - {new Date(plan.createdAt).toLocaleDateString()}
                            </Typography>
                            <Divider style={{ margin: '16px 0' }} />
                            <List>
                              <ListItem style={listItemStyles}>
                                <ListItemText primary={`Subscription Days: ${plan.subscriptionDays} Days`} />
                              </ListItem>
                              <ListItem style={listItemStyles}>
                                <ListItemText primary={`Number of Node's: ${plan.numberOfIp}`} />
                              </ListItem>
                            </List>
                            <Divider style={{ margin: '16px 0' }} />
                            <div style={{ textAlign: 'center' }}>
                              <Button
                                className="btn-sm"
                                style={buyButtonStyles}
                                onClick={(e) => handleBuyPlan(plan, e)}
                              >
                                Buy Plan
                              </Button>
                              <Button
                                className="btn-sm"
                                style={deleteButtonStyles}
                                onClick={(e) => handleDeletePlan(plan, e)}
                              >
                                Delete Plan
                              </Button>
                            </div>
                          </CardContent>
                          <ConfirmationDialog
                            isOpen={isConfirmationDialogOpen}
                            onClose={handleCancelDelete}
                            onConfirm={handleConfirmDelete}
                            message="Are you sure you want to delete customized plan?"
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </>
            )}
          </div>
        </Paper>
      </CardContent>
    </Card>
  );
};

export default CustomizedPlanBox;
