import React from 'react';
import { Typography } from '@mui/material';
import {
  Section,
  StyledTitle,
  StyledDescription,
  StyledParagraph,
  DarkBlueColor,
  SectionTitle,
} from '../DocumentStyles';

const ContactSale = () => (
  <Section id="contactSale" style={{ padding: '3rem 0' }}>
    <SectionTitle>
      <DarkBlueColor>
        <u>Advanced Feature</u>
      </DarkBlueColor>
    </SectionTitle>
    {/* <StyledTitle>Advanced Feature</StyledTitle> */}
    <StyledTitle>7: Contact Sales</StyledTitle>
    <StyledDescription>
      The Contact Sales feature is a specialized offering within our WatchNod, allowing users to request a new
      subscription plan tailored to their unique needs. Users can leverage a dedicated form to submit details about the
      desired plan directly to the WatchNod community.
    </StyledDescription>
    <h3>Key Components</h3>

    <ol className="pointList">
      <li>
        <strong>Request Form:</strong>
        <StyledParagraph>
          Features a user-friendly form where users can input specific details and requirements for the custom
          subscription plan they are seeking.
        </StyledParagraph>
      </li>

      <li>
        <strong>Submission Process:</strong>
        <StyledParagraph>
          Guides users through a seamless process of submitting their plan request to the WatchNod community.
        </StyledParagraph>
      </li>

      <li>
        <strong>User Notification:</strong>
        <StyledParagraph>
          Sends confirmation or acknowledgment notifications to users upon successful submission of their custom plan
          request.
        </StyledParagraph>
      </li>

      <li>
        <strong>Community Interaction:</strong>
        <StyledParagraph>
          Facilitates communication between users and the WatchNod community, allowing for clarification and additional
          details if needed.
        </StyledParagraph>
      </li>
    </ol>
    <Typography variant="h4" style={{ textDecoration: 'underline' }}>
      Customize Plans Section on Subscription Page
    </Typography>

    <section>
      <h3>Key Components:</h3>

      <ol className="pointList">
        <li>
          <strong>List of Custom Plans:</strong>
          <StyledParagraph>
            Displays a curated list of custom subscription plans, each designed to address specific user needs.
          </StyledParagraph>
        </li>

        <li>
          <strong>Plan Details:</strong>
          <StyledParagraph>
            Includes comprehensive details about each custom plan, such as days, pricing, created date, and IPs.
          </StyledParagraph>
        </li>

        <li>
          <strong>Buy Button:</strong>
          <StyledParagraph>
            Features a "Buy" button for each custom plan, allowing users to seamlessly purchase their chosen plan
            directly from the Subscription Page.
          </StyledParagraph>
        </li>
      </ol>
    </section>

    <h3>User Interaction:</h3>

    <ol className="pointList">
      <li>
        <strong>Contacting Sales:</strong>
        <StyledParagraph>
          Users initiate the process by accessing the Contact Sales feature and submitting their custom plan request
          through the provided form.
        </StyledParagraph>
      </li>

      <li>
        <strong>Custom Plan Exploration:</strong>
        <StyledParagraph>
          Users can explore the Customize Plans section to review details of custom plans created by the WatchNod
          community.
        </StyledParagraph>
      </li>

      <li>
        <strong>Purchase Process:</strong>
        <StyledParagraph>
          The "Buy" button associated with each custom plan enables users to initiate the purchase process for their
          chosen plan.
        </StyledParagraph>
      </li>
    </ol>
    <div className="overall-Objective">
      The Contact Sales and Customize Plans features collectively enhance user engagement by providing a channel for
      tailored subscription plans. Users have the flexibility to communicate their specific requirements, and the
      community responds by creating custom plans that are seamlessly integrated into the Subscription Page. This
      collaborative approach ensures a personalized and efficient subscription experience within our WatchNod.
    </div>
    <StyledTitle>8: AutoPay Functionality on Wallet Page</StyledTitle>
    <strong>Location:</strong>
    <StyledParagraph>
      The AutoPay functionality is conveniently located at the top-right corner of the Wallet Page.
    </StyledParagraph>

    <strong>Toggle Button:</strong>
    <StyledParagraph>
      The AutoPay feature is represented by a toggle button that users can easily switch on or off.
    </StyledParagraph>

    <strong>Purpose:</strong>
    <StyledParagraph>
      <strong>Auto Renewal of Expired Plans:</strong> The primary purpose of the AutoPay functionality is to facilitate
      the automatic renewal of expired subscription plans.
    </StyledParagraph>

    <h3>How AutoPay Works?</h3>
    <ol className="pointList">
      <li>
        <strong>Plan Expiry Check:</strong> On the day a user's subscription plan expires, the AutoPay system initiates
        a check.
      </li>
      <li>
        <strong>Wallet Balance Verification:</strong> The system verifies the user's wallet balance to ensure it is
        greater than or equal to the price of the expired plan.
      </li>
      <li>
        <strong>Automatic Renewal:</strong> If the wallet balance is sufficient, the AutoPay feature automatically
        renews the subscription plan without requiring any manual intervention from the user.
      </li>

      <strong>User Control:</strong>
      <StyledParagraph>
        Users have full control over the AutoPay functionality and can toggle it on or off based on their preferences.
      </StyledParagraph>

      <strong>Benefits:</strong>
      <ul className="pointList">
        <li>
          <strong>Seamless Renewals:</strong> Ensures a seamless and hassle-free experience for users by automatically
          renewing plans on the day of expiration.
        </li>
        <li>
          <strong>Avoid Service Interruption:</strong> Helps users avoid any interruption in service by ensuring timely
          renewal if the wallet balance allows.
        </li>
        <li>
          <strong>Convenient Management:</strong> Offers users the convenience of managing their subscription renewals
          without the need for constant manual monitoring.
        </li>
      </ul>

      <strong>Notification:</strong>
      <StyledParagraph>
        Users may receive notifications or alerts about the AutoPay renewal process to stay informed about plan updates.
      </StyledParagraph>

      <strong>Important Note:</strong>
      <StyledParagraph>
        Users are encouraged to maintain a sufficient balance in their wallet to enable successful AutoPay renewals.
      </StyledParagraph>

      <strong>User-Friendly Design:</strong>
      <StyledParagraph>
        The AutoPay toggle button features a user-friendly design, making it easy for users to enable or disable the
        functionality as per their preferences.
      </StyledParagraph>

      <div className="overall-Objective">
        By utilizing the AutoPay functionality, users can enjoy a hands-free approach to managing their subscription
        renewals, ensuring a continuous and uninterrupted monitoring experience within our platform.
      </div>
    </ol>
  </Section>
);

export default ContactSale;
