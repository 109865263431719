import React from 'react';
import styled from 'styled-components';
import { NavbarPage } from '../Navbar/Navbar';
import { Footer } from '../Footer';
import DocumentPage from './Documentation/DocumentPage';

const TopMargin = styled.div`
  margin-top: 62px;  // Adjust this value based on your design needs
  text-align: justify;
`;

export default function DocumentDetailPage() {
  return (
    <>
      <NavbarPage />
      <TopMargin>
        <DocumentPage />
      </TopMargin>
      <Footer />
    </>
  );
}
