import React from 'react';
import PropTypes from 'prop-types';
import { CardContent, Card, Divider, Typography, CardHeader, Stack } from '@mui/material';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from '@mui/lab';
import { fDateTime } from '../../../utils/formatTime';

AppOrderTimeline.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppOrderTimeline({ title, subheader, list, ...other }) {
  const sortedList = list.slice().sort((a, b) => Date.parse(b.time) - Date.parse(a.time));

  const displayedList = sortedList.slice(0, 5);

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <CardContent
        sx={{
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none',
          },
          p: 2,
        }}
      >
        <Timeline>
          <Stack spacing={1.5} sx={{ pr: 0 }}>
            {displayedList.map((item) => (
              <OrderItem key={item.id} item={item} />
            ))}
          </Stack>
        </Timeline>
      </CardContent>
      <Divider />
    </Card>
  );
}

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    time: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

function OrderItem({ item, isLast }) {
  const { type, title, time } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (type === 'order1' && 'primary') ||
            (type === 'order2' && 'success') ||
            (type === 'order3' && 'info') ||
            (type === 'order4' && 'warning') ||
            (type === 'order5' && 'error') ||
            (type === 'order6' && 'primary') ||
            (type === 'order7' && 'success') ||
            (type === 'order8' && 'info') ||
            (type === 'order9' && 'warning') ||
            'success'
          }
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="subtitle2">{title}</Typography>

        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {fDateTime(time)}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}
