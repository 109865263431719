import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import {
  Section,
  SectionTitle,
  StyledTitle,
  StyledDescription,
  CodeBlockContainer,
  StyledLink,
  CopyButton,
  copyToClipboard,
  DarkBlueColor,
  StyledParagraph,
  Code,
} from '../DocumentStyles';

const PublicIpAndNatIp = () => {
  const [copiedCommand, setCopiedCommand] = useState('');

  const publicIpAndNatIpDoc = [
    {
      step: 'Pull the Pushprox Docker Image',
      description: 'First, you need to pull the Pushprox Docker image from the Docker registry.',
      command: 'docker pull prometheuscommunity/pushprox',
    },
    {
      step: 'Run the Pushprox Docker Container',
      description:
        "Run the Pushprox container in detached mode using the host network. This allows the container to use the host's network stack, which is often required for proper communication with the Pushprox server.",
      command:
        'docker run -d --network host --name pushprox-client --entrypoint /app/pushprox-client prometheuscommunity/pushprox --proxy-url=http://164.52.207.135:8080/',
    },
    {
      step: 'How to Get Host-Name',
      description: 'Get the hostname inside the container.',
      command: 'hostname',
    },
  ];

  return (
    <Section id="publicIpAndNatIp" style={{ padding: '3rem 0' }}>
      <SectionTitle>
        <DarkBlueColor>
          <u>Private/NAT-IP</u>
        </DarkBlueColor>
      </SectionTitle>
      <StyledTitle>Set Up for the Private/NAT-IP</StyledTitle>
      <StyledParagraph>
        Pushprox is a service that allows you to expose local applications to Prometheus for monitoring. Follow these
        steps to integrate Pushprox into your application using Docker.
      </StyledParagraph>

      <StyledDescription>
        <strong>Prerequisites</strong>
        <li>Docker installed on your machine.</li>
        <li>Node Exporter installed on your machine.
        <StyledLink href="#nodeExporter" rel="noopener noreferrer">
            Click here
          </StyledLink>
        </li>
        <li>
          Access to the Pushprox server with &nbsp;
          <StyledLink href="http://164.52.207.135:8080/" target="_blank" rel="noopener noreferrer">
            http://164.52.207.135:8080/
          </StyledLink>
          &nbsp; Pushprox server URL.
        </li>
        <li>Your application's hostname or Fully Qualified Domain Name (FQDN).</li>
        <br />
        <StyledTitle>Steps to Integrate Pushprox</StyledTitle>

        {publicIpAndNatIpDoc.map((item, index) => (
          <div key={index}>
            <strong>{index + 1}</strong> &nbsp;
            <strong>{item.step}</strong>
            <StyledParagraph>{item.description}</StyledParagraph>
            {item.command && (
              <CodeBlockContainer>
                <Code>{item.command}</Code>
                <Tooltip title={copiedCommand === item.command ? 'Copied!' : 'Copy'} arrow placement="top">
                  <CopyButton onClick={() => copyToClipboard(item.command, () => setCopiedCommand(item.command))}>
                    <CopyAllIcon />
                  </CopyButton>
                </Tooltip>
              </CodeBlockContainer>
            )}
            {item.subSteps &&
              item.subSteps.map((subStep, subIndex) => (
                <div key={subIndex}>
                  <StyledParagraph>{subStep.instruction}</StyledParagraph>
                  <CodeBlockContainer>
                    <Code>{subStep.command}</Code>
                    <Tooltip title={copiedCommand === subStep.command ? 'Copied!' : 'Copy'} arrow placement="top">
                      <CopyButton
                        onClick={() => copyToClipboard(subStep.command, () => setCopiedCommand(subStep.command))}
                      >
                        <CopyAllIcon />
                      </CopyButton>
                    </Tooltip>
                  </CodeBlockContainer>
                </div>
              ))}
          </div>
        ))}
      </StyledDescription>
    </Section>
  );
};

export default PublicIpAndNatIp;
