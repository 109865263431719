import React, { useState } from 'react';
import {
  Section,
  SectionTitle,
  StyledTitle,
  StyledDescription,
  DarkBlueColor,
  StyledQuestion,
  StyledParagraph,
  StyledAnswer,
  StyledListItem,
} from '../DocumentStyles';

const FAQ = () => {
  const [showFirstFaqAnswer, setShowFirstFaqAnswer] = useState(false);
  const [showSecondFaqAnswer, setShowSecondFaqAnswer] = useState(false);
  const [showThirdFaqAnswer, setShowThirdFaqAnswer] = useState(false);
  const [showFourthFaqAnswer, setShowFourthFaqAnswer] = useState(false);
  const [showFifthFaqAnswer, setShowFifthFaqAnswer] = useState(false);
  const [showSixFaqAnswer, setShowSixFaqAnswer] = useState(false);
  const [showSevenFaqAnswer, setShowSevenFaqAnswer] = useState(false);

  const toggleFaqAnswer = (answerNumber) => {
    setShowFirstFaqAnswer(false);
    setShowSecondFaqAnswer(false);
    setShowThirdFaqAnswer(false);
    setShowFourthFaqAnswer(false);
    setShowFifthFaqAnswer(false);
    setShowSixFaqAnswer(false);
    setShowSevenFaqAnswer(false);
    switch (answerNumber) {
      case 1:
        setShowFirstFaqAnswer(!showFirstFaqAnswer);
        break;
      case 2:
        setShowSecondFaqAnswer(!showSecondFaqAnswer);
        break;
      case 3:
        setShowThirdFaqAnswer(!showThirdFaqAnswer);
        break;
      case 4:
        setShowFourthFaqAnswer(!showFourthFaqAnswer);
        break;
      case 5:
        setShowFifthFaqAnswer(!showFifthFaqAnswer);
        break;
      case 6:
        setShowSixFaqAnswer(!showSixFaqAnswer);
        break;
      case 7:
        setShowSevenFaqAnswer(!showSevenFaqAnswer);
        break;
      default:
        break;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      for (let i = 1; i <= 7; i += 1) {
        toggleFaqAnswer(i);
      }
    }
  };

  return (
    <Section id="faq" style={{ padding: '3rem 0' }}>
      <SectionTitle>
        <DarkBlueColor>
          <u>FAQ</u>
        </DarkBlueColor>
      </SectionTitle>
      <StyledTitle>Frequently Asked Questions (FAQs)</StyledTitle>
      <StyledDescription>
        <StyledQuestion role="button" tabIndex={0} onClick={() => toggleFaqAnswer(1)} onKeyPress={handleKeyPress}>
          <StyledParagraph>
            <strong>Q1: How do I recharge my wallet?</strong>
          </StyledParagraph>
        </StyledQuestion>
        {showFirstFaqAnswer && (
          <StyledAnswer>
            <StyledParagraph>
              <strong>Recharging your wallet is a simple process:</strong>
            </StyledParagraph>
            <StyledListItem>&gt; Navigate to the Wallet Page within the WatchNod.</StyledListItem>
            <StyledListItem>&gt; Locate the Add Balance button on the page.</StyledListItem>
            <StyledListItem>&gt; Click the "Add Balance" button to initiate the recharge process.</StyledListItem>
            <StyledListItem>&gt; Follow the prompts to complete the recharge transaction securely.</StyledListItem>
          </StyledAnswer>
        )}

        <StyledQuestion role="button" tabIndex={0} onClick={() => toggleFaqAnswer(2)} onKeyPress={handleKeyPress}>
          <StyledParagraph>
            <strong>Q2: Where can I see my running plans?</strong>
          </StyledParagraph>
        </StyledQuestion>
        {showSecondFaqAnswer && (
          <StyledAnswer>
            <StyledParagraph>
              <strong>To view your active subscription plans, follow these steps:</strong>
            </StyledParagraph>

            <StyledListItem>&gt; Navigate to the Wallet Page within the WatchNod. </StyledListItem>
            <StyledListItem>&gt; Scroll down to the section labeled Running Plans. </StyledListItem>
            <StyledListItem>
              &gt; In this section, you will find a comprehensive list of all your currently active subscription plans.
            </StyledListItem>
            <StyledListItem>
              &gt; Each running plan is accompanied by details such as creation date, expiration date, and associated
              metrics.
            </StyledListItem>

            <StyledParagraph>
              By accessing the Running Plans section on the Wallet Page, you can easily monitor and manage your active
              subscription plans. If you have further questions or encounter any issues with your running plans, feel
              free to reach out to our support team for assistance.
            </StyledParagraph>
          </StyledAnswer>
        )}

        <StyledQuestion role="button" tabIndex={0} onClick={() => toggleFaqAnswer(3)} onKeyPress={handleKeyPress}>
          <StyledParagraph>
            <strong>Q3: What is the use of the Contact Sales plan?</strong>
          </StyledParagraph>
        </StyledQuestion>
        {showThirdFaqAnswer && (
          <StyledAnswer>
            <StyledParagraph>
              <strong>
                The Contact Sales plan serves as a specialized feature designed to cater to your unique monitoring
                needs. By selecting the Contact Sales plan:
              </strong>
            </StyledParagraph>
            <strong>User-Requested Customization:</strong>
            <StyledParagraph>
              <StyledListItem>
                &gt; Clicking on the Contact Sales plan allows users to request the creation of a personalized
                monitoring plan tailored to their specific requirements.
              </StyledListItem>
            </StyledParagraph>
            <strong>Form Submission:</strong>
            <StyledParagraph>
              <StyledListItem>
                &gt; Users can fill out a dedicated form to communicate their desired plan details and any additional
                requirements.
              </StyledListItem>
            </StyledParagraph>
            <strong>Community Collaboration:</strong>
            <StyledParagraph>
              <StyledListItem>
                &gt; The submitted plan request is then reviewed and processed by the WatchNod community.
              </StyledListItem>
            </StyledParagraph>
            <strong>Custom Plan Creation:</strong>
            <StyledParagraph>
              <StyledListItem>
                &gt; Once approved, a custom monitoring plan is created based on the user's specifications and becomes
                accessible in the Customize Plans section on the Subscription Page.
              </StyledListItem>
            </StyledParagraph>

            <StyledParagraph>
              By leveraging the Contact Sales plan, users can actively participate in shaping their monitoring
              experience according to their unique needs. For any additional information or assistance, please contact
              our support team.
            </StyledParagraph>
          </StyledAnswer>
        )}

        <StyledQuestion role="button" tabIndex={0} onClick={() => toggleFaqAnswer(4)} onKeyPress={handleKeyPress}>
          <StyledParagraph>
            <strong>Q4: How do I add a new monitoring address to monitor it?</strong>
          </StyledParagraph>
        </StyledQuestion>
        {showFourthFaqAnswer && (
          <StyledAnswer>
            <StyledParagraph>
              <strong>Adding a new monitoring address is a straightforward process. Follow these steps</strong>
            </StyledParagraph>

            <StyledListItem>&gt; Navigate to the Monitor Page within the WatchNod. </StyledListItem>
            <StyledListItem>
              &gt; Select the subscription plan in which you want to add the new monitoring address.{' '}
            </StyledListItem>
            <StyledListItem>
              &gt; In the Dashboard Name field, provide a unique name for the address for easy identification.{' '}
            </StyledListItem>
            <StyledListItem>
              &gt; In the IP Address field, enter the public IP address of the server you want to monitor.{' '}
            </StyledListItem>
            <StyledListItem>
              &gt; In the Port Address field, input the running port on your server that you wish to monitor.{' '}
            </StyledListItem>
            <StyledListItem>&gt; Click the Submit button to initiate the address addition process. </StyledListItem>
            <StyledListItem>
              &gt; Upon submission, the system checks whether the entered IP and port address are publicly available. If
              successful, the new monitoring address is saved.
            </StyledListItem>
            <StyledParagraph>
              By following these steps, you can effortlessly add and monitor a new address in your chosen subscription
              plan. For further assistance or if you encounter any issues during this process, please consult our
              support team.
            </StyledParagraph>
          </StyledAnswer>
        )}

        <StyledQuestion role="button" tabIndex={0} onClick={() => toggleFaqAnswer(5)} onKeyPress={handleKeyPress}>
          <StyledParagraph>
            <strong>Q5: Unable to add monitor address to monitor it?</strong>
          </StyledParagraph>
        </StyledQuestion>
        {showFifthFaqAnswer && (
          <StyledAnswer>
            <StyledParagraph>
              <strong>Follow these steps to successfully add new monitoring address:</strong>
            </StyledParagraph>

            <StyledListItem>
              &gt; Ensure that the provided IP and port address are accurate. Check for any typos or errors in the
              entry.
            </StyledListItem>
            <StyledListItem>
              &gt; Confirm whether the specified IP and port are publicly accessible. Verify network configurations and
              firewall settings for any restrictions.
            </StyledListItem>
            <StyledListItem>
              &gt; If issues persist, consider re-entering the monitor address. Carefully input the correct IP and port
              details before submitting.
            </StyledListItem>
            <StyledParagraph>
              By following these steps, you can effortlessly add new address in your chosen subscription plan. For
              further assistance or if you encounter any issues during this process, please consult our support team.
            </StyledParagraph>
          </StyledAnswer>
        )}

        <StyledQuestion role="button" tabIndex={0} onClick={() => toggleFaqAnswer(6)} onKeyPress={handleKeyPress}>
          <StyledParagraph>
            <strong>Q6: What is the Use of AutoPay on Wallet Page?</strong>
          </StyledParagraph>
        </StyledQuestion>
        {showSixFaqAnswer && (
          <StyledAnswer>
            <StyledParagraph>
              <strong>AutoPay Functionality:</strong>
            </StyledParagraph>
            <StyledParagraph>
              <StyledListItem>
                &gt; The AutoPay feature on the wallet page serves the purpose of automatically renewing a user's
                running plan when it reaches expiration. This ensures uninterrupted access to services without manual
                intervention.
              </StyledListItem>
            </StyledParagraph>
          </StyledAnswer>
        )}

        <StyledQuestion role="button" tabIndex={0} onClick={() => toggleFaqAnswer(7)} onKeyPress={handleKeyPress}>
          <StyledParagraph>
            <strong>Q7: How do I know when my running subscription plan is about to expire?</strong>
          </StyledParagraph>
        </StyledQuestion>
        {showSevenFaqAnswer && (
          <StyledAnswer>
            <StyledParagraph>
              <strong>
                You will receive notifications to keep you informed about the status of your subscription plan:
              </strong>
            </StyledParagraph>
            <StyledListItem>
              &gt; Before three days of expiration, we will send you a subscription expiring warning email.{' '}
            </StyledListItem>
            <StyledListItem>
              &gt; On the day of expiration, another email will be sent to notify you that your subscription has
              expired.
            </StyledListItem>
          </StyledAnswer>
        )}
      </StyledDescription>
    </Section>
  );
};

export default FAQ;
