import React, { useState } from 'react';
import { Container, Paper, Typography, TextField, Button, Grid, CircularProgress } from '@mui/material';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const PageContainer = styled(Container)`
  padding: 20px;
`;

const NewsForm = styled(Paper)`
  padding: 20px;
`;

const NewsTitle = styled(Typography)`
  font-size: 24px;
  margin-bottom: 10px;
`;

const NewsUpdatePage = (props) => {
  NewsUpdatePage.propTypes = {
    closeForm: PropTypes.func,
    reloadData: PropTypes.func,
  };

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('token');

  const [titleError, setTitleError] = useState('');
  const [contentError, setContentError] = useState('');

  const handleTitleChange = (event) => {
    const inputValue = event.target.value;
    const isValidInput = /^[a-zA-Z\s]+$/.test(inputValue);
    const isWithinLengthLimit = inputValue.length <= 30;

    if ((isValidInput || inputValue === '') && isWithinLengthLimit) {
      setTitle(inputValue);
      setTitleError('');
    } else if (!isWithinLengthLimit) {
      setTitleError('Title must be 30 characters or less');
    } else {
      setTitleError('Only alphabets and spaces are allowed');
    }
  };

  const handleContentChange = (event) => {
    const inputValue = event.target.value;
    const isValidInput = /^[a-zA-Z\s]+$/.test(inputValue);
    const isWithinLengthLimit = inputValue.length <= 50;
    if ((isValidInput || inputValue === '') && isWithinLengthLimit) {
      setContent(inputValue);
      setContentError('');
    } else if (!isWithinLengthLimit) {
      setContentError('Content must be 50 characters or less');
    } else {
      setContentError('Only alphabets and spaces are allowed');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!title || !content) {
      toast.error('Please fill in all required fields.');
      setIsLoading(false);
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const newsData = {
        newsTitle: title,
        newsDescription: content,
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/addNewsUpdateByAdmin`, newsData, config);

      toast.success(response.data.message);
      props.closeForm();
      props.reloadData();
    } catch (error) {
      console.error('Error submitting form data:', error);

      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Server Not Found');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageContainer>
      <NewsForm elevation={3}>
        <NewsTitle variant="h5">Add News Update</NewsTitle>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={handleTitleChange}
              required
              error={!title}
              helperText={titleError || (!title ? 'Title is required' : '')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Content"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              value={content}
              onChange={handleContentChange}
              required
              error={!content}
              helperText={contentError || (!content ? 'Content is required' : '')}
            />
          </Grid>
        </Grid>
        <br />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button variant="contained" color="primary" onClick={(e) => handleSubmit(e)}>
            Add News
          </Button>
        )}
      </NewsForm>
    </PageContainer>
  );
};

export default NewsUpdatePage;
