import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/system';
import { Paper, Dialog, DialogTitle, DialogContent, DialogContentText, Button } from '@mui/material';
import ReactConfetti from 'react-confetti';
import ConfettiExplosion from 'react-confetti-explosion';

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

const SuccessPaper = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(3)};
  text-align: center;
`;

const emojiConfettiContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
};

const emojiConfettiStyle = {
  fontSize: '2em',
  margin: '0 0.5em',
  animation: 'emoji-confetti-fall 1s ease-in-out infinite',
};

const SuccessPage = () => {
  const [showConfetti, setShowConfetti] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const showSuccess = location.state?.showSuccess;
  // const showSuccess = true;
  const [emojiExplosion, setEmojiExplosion] = useState('');

  useEffect(() => {
    const paymentSuccessful = true;

    if (paymentSuccessful) {
      setShowConfetti(true);
      setOpenDialog(true);
      setEmojiExplosion('🎉✨🥳');
      const timeoutId = setTimeout(() => {
        handleDialogClose();
        setEmojiExplosion('');
      }, 3500);
      return () => clearTimeout(timeoutId);
    }
    return () => {};
    // eslint-disable-next-line
  }, []);

  const handleDialogClose = () => {
    setOpenDialog(false);
    navigate('/dashboard/wallet');
  };

  return (
    <>
      {showSuccess === true ? (
        <>
          <SuccessContainer>
            <SuccessPaper elevation={3} theme={theme} style={{ padding: '1rem', backgroundColor: '#f2f2f2' }}>
              {/* <Typography variant="h4" gutterBottom>
              Congratulations
            </Typography>
            <Typography variant="body1" paragraph>
              Your balance has been added to your wallet.
            </Typography> */}

              <ReactConfetti
                active={showConfetti.toString()}
                config={{ angle: 90, spread: 360, startVelocity: 60, elementCount: 100, decay: 0.9 }}
              />
              <ConfettiExplosion
                active={showConfetti.toString()}
                config={{ angle: 90, spread: 360, startVelocity: 60, elementCount: 100, decay: 0.9 }}
              />
            </SuccessPaper>
          </SuccessContainer>
          <Dialog open={openDialog} onClose={handleDialogClose}>
            <div style={{ position: 'relative' }}>
              {/* <ReactConfetti
            active={showConfetti}
            config={{ angle: 90, spread: 360, startVelocity: 60, elementCount: 100, decay: 0.9 }}
          />
          <ConfettiExplosion
            active={showConfetti}
            config={{ angle: 90, spread: 360, startVelocity: 60, elementCount: 100, decay: 0.9 }}
          /> */}
              <DialogTitle style={{ textAlign: 'center', margin: '0' }}>Congratulations {emojiExplosion}</DialogTitle>
              <DialogContent>
                <DialogContentText>Your balance has been added to your wallet.</DialogContentText>
                <div style={emojiConfettiContainerStyle}>
                  <span role="img" aria-label="confetti" style={emojiConfettiStyle}>
                    🎊
                  </span>
                  <span role="img" aria-label="confetti" style={emojiConfettiStyle}>
                    🎉
                  </span>
                </div>
              </DialogContent>
            </div>
            <Button onClick={handleDialogClose} color="primary">
              OK
            </Button>
          </Dialog>
        </>
      ) : (
        navigate('/dashboard/wallet')
      )}
    </>
  );
};

export default SuccessPage;
