import React from 'react';
import {
    Section,
    SectionTitle,
    StyledTitle,
    DarkBlueColor,
    StyledParagraph,
    StyledDescription,
    StyledLink
} from '../DocumentStyles';

const PublicIp = () => (
    <Section id="publicIp" style={{ padding: '3rem 0' }}>
        <SectionTitle>
            <DarkBlueColor>
                <u>Public-IP</u>
            </DarkBlueColor>
        </SectionTitle>
        <StyledTitle>Set Up for the Public-IP</StyledTitle>
        <StyledParagraph>
            Public IP addresses are crucial for services that need to be accessible from the internet,
            such as web servers, APIs, or any application that requires external users to connect to it.


        </StyledParagraph>
        <StyledDescription>
            <strong>Prerequisites</strong>
                <li>Node Exporter installed on your machine.
                    <StyledLink href="#nodeExporter" rel="noopener noreferrer">
                        Click here
                    </StyledLink>
                </li>
        </StyledDescription>
    </Section>

);

export default PublicIp;