// src/styles.js or src/commonStyles.js
import styled from 'styled-components';
import { Typography, Card, ListItem, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';

export const Section = styled.div`
  margin-bottom: 5px;
`;

export const SectionTitle = styled.h2`
  border-bottom: 2px solid #75c6e9;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

export const StyledContainer = styled(Card)`
  background-color: #f0f8ff;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px auto;
  text-align: justify;
`;

export const StyledTitle = styled(Typography)`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: justify;
`;

export const StyledDescription = styled(Typography)`
  margin-bottom: 16px;
  text-align: justify;
`;

export const StyledNotes = styled(Typography)`
  margin-bottom: 16px;
  color: blue;
  font-style: italic;
  text-decoration: underline;
  text-align: justify;
`;

export const StyledAccordion = styled(Accordion)`
  margin-top: 16px;
  text-align: justify;
`;

export const StyledListItem = styled(ListItem)`
  && {
    padding: 16px;
  }
  text-align: justify;
`;

export const StyledParagraph = styled(Typography)`
  && {
    margin-bottom: 10px;
  }
  text-align: justify;
`;

export const StyledQuestion = styled.div`
  background-color: #75c6e9;
  color: #2f3d4a;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
  text-align: justify;

  &:hover {
    background-color: #049ee0;
  }
`;

export const StyledAnswer = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: #75c6e9;
  color: #000;
  border-bottom: 1px solid #ddd;

  &:hover {
    background-color: #049ee0;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 10px;
  text-align: justify;
`;

export const CodeBlockContainer = styled.div`
  position: relative;
  background-color: #ebebe3;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
  font-family: monospace;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-x: auto;

  @media (max-width: 768px) {
    padding: 8px;
    margin: 8px 0;
  }
`;

export const Code = styled.code`
  display: block;
`;

export const DarkBlueColor = styled.div`
  color: darkblue;
`;

export const CopyButton = styled(IconButton)`
  position: absolute;
  top: 6px;
  right: 10px;
  background-color: #d3d3d3;
  color: #fff;
  padding: 5px;

  &:hover {
    background-color: #049ee0;
  }

  @media (max-width: 768px) {
    top: 5px;
    right: 8px;
  }
`;

export const StyledLink = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

// Copy to Clipboard Function
export const copyToClipboard = (text, setCopied) => {
  navigator.clipboard.writeText(text).then(
    () => setCopied(true),
    (err) => console.error('Failed to copy: ', err)
  );
};
