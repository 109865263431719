import { Card } from '@mui/material';
import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99999,
  },
  content: {
    position: 'absolute',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '400px',
    margin: 'auto',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    background: 'white', 
    border: '1px solid #ccc', 
  },
};

const StyledMessage = styled.p`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const StyledButton = styled.button`
  background-color: ${(props) => props.color};
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${(props) => props.color};
  }
`;

Modal.setAppElement('#root');

const ConfirmationDialog = ({ isOpen, onClose, onConfirm, message }) => (
  <Card>
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel="Confirmation Dialog">
      <StyledMessage>{message}</StyledMessage>
      <StyledButtonContainer>
        <StyledButton onClick={onConfirm} color="#ff0000">
          Yes
        </StyledButton>
        <StyledButton onClick={onClose} color="#0000ff">
          No
        </StyledButton>
      </StyledButtonContainer>
    </Modal>
  </Card>
);
ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  message: PropTypes.string,
};

export default ConfirmationDialog;
