import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';

SubscriptionPage.propTypes = {
  openForm: PropTypes.func,
  openPurchaseForm: PropTypes.func,
};

export default function SubscriptionPage(props) {
  const [plans, setPlans] = useState([]);
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPlans() {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/getAllMasterData`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        if (data.success) {
          setPlans(data.data);
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchPlans();
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleSelectPlan = (plan) => {
    if (plan.subscriptionTitle === 'Contact Sales') {
      props.openForm(plan._id);
      navigate(`/dashboard/subscription/${plan._id}`);
    } else {
      props.openPurchaseForm(plan._id);
      const encodedSubscriptionId = encodeURIComponent(plan._id);
      const encodedSubscriptionName = encodeURIComponent(plan.subscriptionTitle);
      const encodedSubscriptionAmount = encodeURIComponent(plan.SubscriptionAmount);
      navigate(
        `/dashboard/subscription/${encodedSubscriptionId}/${encodedSubscriptionName}/${encodedSubscriptionAmount}`
      );
    }
  };

  const headerStyles = {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    color: '#2196F3',
  };

  const priceStyles = {
    fontSize: '1.2rem',
    color: '#E91E63',
  };

  const listItemStyles = {
    padding: '0',
    marginBottom: '5px',
    textAlign: 'left',
  };

  const selectButtonStyles = {
    marginTop: '16px',
    backgroundColor: '#2196F3',
    color: 'white',
  };

  const hrLineStyle = {
    position: 'relative',
    maxWidth: '500px',
    margin: '5px auto',
    textAlign: 'center',
  };

  const hrBeforeStyle = {
    content: ' ',
    height: '2px',
    width: windowWidth <= 600 ? '0' : '130px',
    background: '#2196F3',
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '0',
  };

  const hrAfterStyle = {
    content: ' ',
    height: '2px',
    width: windowWidth <= 600 ? '0' : '130px',
    background: '#2196F3',
    display: 'block',
    position: 'absolute',
    top: '50%',
    right: '0',
  };

  const sortedPlans = plans.slice().sort((a, b) => a.SubscriptionAmount - b.SubscriptionAmount);

  const freeTrialPlan = sortedPlans.find((plan) => plan.subscriptionTitle === 'Free Trial');
  const basicPlan = sortedPlans.find((plan) => plan.subscriptionTitle === 'Basic');
  const standardPlan = sortedPlans.find((plan) => plan.subscriptionTitle === 'Standard');
  const contactSalesPlan = sortedPlans.find((plan) => plan.subscriptionTitle === 'Contact Sales');

  const plansToRender = [freeTrialPlan, basicPlan, standardPlan, contactSalesPlan];

  return (
    <>
      {isLoading ? (
        <CircularProgress style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }} />
      ) : (
        <>
          <section className="bg-light">
            {plansToRender.map((plan) => {
              if (plan) {
                const isHovered = plan._id === hoveredCardId;

                return (
                  <Container style={{ marginBottom: '1%' }} key={plan._id}>
                    <Grid container>
                      <Grid item xs={12} lg={12}>
                        <Card
                          className="card"
                          style={{
                            transition: 'transform 0.3s ease-in-out',
                            border: '1px solid #2196F3',
                            transform: isHovered ? 'scale(1.05)' : 'scale(1.0)',
                            boxShadow: isHovered ? '0px 5px 15px rgba(0, 0, 0, 0.5)' : 'none',
                            borderColor: isHovered ? '#E91E63' : 'transparent',
                          }}
                          onMouseEnter={() => setHoveredCardId(plan._id)}
                          onMouseLeave={() => setHoveredCardId(null)}
                        >
                          <CardContent>
                            <Grid container>
                              <Grid item xs={8} md={8}>
                                <div>
                                  <Typography variant="h4" style={headerStyles} gutterBottom>
                                    {plan.subscriptionTitle}
                                  </Typography>
                                  <Typography style={{ color: '#777' }}>{plan.subscriptionDescription}</Typography>
                                </div>
                                <div>
                                  <div style={hrLineStyle}>
                                    <span style={hrBeforeStyle} />
                                    <h4>What's included?</h4>
                                    <span style={hrAfterStyle} />
                                  </div>
                                </div>
                                <div>
                                  <List>
                                    <ListItem style={listItemStyles}>
                                      <ListItemText
                                        primary={`Subscription Days: ${plan.subscriptionDays || '__'} Days`}
                                      />
                                    </ListItem>
                                    <ListItem style={listItemStyles}>
                                      <ListItemText primary={`Number of Node's: ${plan.numberOfIp || '__'}`} />
                                    </ListItem>
                                  </List>
                                </div>
                              </Grid>
                              <Grid item xs={4} md={4}>
                                <div>
                                  <div style={{ textAlign: 'right' }}>
                                    <Button
                                      className="btn-sm"
                                      style={selectButtonStyles}
                                      onClick={() => handleSelectPlan(plan)}
                                    >
                                      Buy Plan
                                    </Button>
                                  </div>
                                  <List>
                                    <ListItem style={listItemStyles}>
                                      <ListItemText primary="Price" />
                                    </ListItem>
                                    <ListItem style={listItemStyles}>
                                      <Typography variant="h4" style={priceStyles} align="center">
                                        ${plan.SubscriptionAmount || '__'} for {plan.subscriptionDays || '__'} Days
                                      </Typography>
                                    </ListItem>
                                  </List>
                                </div>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Container>
                );
              }
              return null;
            })}
          </section>
        </>
      )}
    </>
  );
}
