import React, { useState } from 'react';
import { Typography } from '@mui/material';
import {
  Section,
  SectionTitle,
  StyledTitle,
  StyledDescription,
  StyledNotes,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  DarkBlueColor,
} from '../DocumentStyles';

const Overview = () => {
  const [showFirstAnswer, setShowFirstAnswer] = useState(false);
  const [showSecondAnswer, setShowSecondAnswer] = useState(false);
  const [showThirdAnswer, setShowThirdAnswer] = useState(false);
  const [showFourthAnswer, setShowFourthAnswer] = useState(false);
  const [showFifthAnswer, setShowFifthAnswer] = useState(false);
  const [showSixAnswer, setShowSixAnswer] = useState(false);
  const [showSevenAnswer, setShowSevenAnswer] = useState(false);

  const toggleAnswer = (index) => {
    switch (index) {
      case 1:
        setShowFirstAnswer(!showFirstAnswer);
        break;
      case 2:
        setShowSecondAnswer(!showSecondAnswer);
        break;
      case 3:
        setShowThirdAnswer(!showThirdAnswer);
        break;
      case 4:
        setShowFourthAnswer(!showFourthAnswer);
        break;
      case 5:
        setShowFifthAnswer(!showFifthAnswer);
        break;
      case 6:
        setShowSixAnswer(!showSixAnswer);
        break;
      case 7:
        setShowSevenAnswer(!showSevenAnswer);
        break;
      default:
        break;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      for (let i = 1; i <= 7; i += 1) {
        toggleAnswer(i);
      }
    }
  };

  return (
    <Section id="overview" style={{ padding: '3rem 0' }}>
      <SectionTitle>
        <DarkBlueColor>
          <u>Overview</u>
        </DarkBlueColor>
      </SectionTitle>
      <StyledDescription>
        If you haven't taken the plunge to try WatchNod, here's how you can get started. Here, we'll discuss how Watch
        Nod works for you and introduce you to our main concepts and features. The various articles listed here will
        give you insight into how each of these concepts works in sync to help you create an optimal monitoring
        environment.
      </StyledDescription>
      <StyledNotes>
        <strong>Notes :- </strong>WatchNod is agent-based and the agent needs to be downloaded and installed on every
        server that needs to be monitored.
      </StyledNotes>

      {/* Second Section */}
      <StyledTitle>Introduction to Monitoring Solutions:</StyledTitle>
      <StyledDescription>
        In the dynamic landscape of web-based services and applications, ensuring the optimal performance and
        reliability of server infrastructure is crucial. Monitoring solutions for web servers play a vital role in
        proactively managing, analyzing, and maintaining the health of servers to prevent potential issues and minimize
        downtime. These solutions offer real-time insights into various aspects of server performance, allowing
        administrators to make informed decisions and optimize resource utilization.
      </StyledDescription>

      {/* Accordion Section */}
      <StyledAccordion
        expanded={showFirstAnswer ? 'true' : undefined}
        onClick={() => toggleAnswer(1)}
        onKeyPress={handleKeyPress}
      >
        <StyledAccordionSummary>
          <Typography>Performance Metrics Monitoring</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            Constantly track key performance indicators (KPIs) such as CPU usage, memory consumption, disk I/O, and
            network activity. This helps identify bottlenecks or potential resource shortages.
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        expanded={showSecondAnswer ? 'true' : undefined}
        onClick={() => toggleAnswer(2)}
        onKeyPress={handleKeyPress}
      >
        <StyledAccordionSummary>
          <Typography>Uptime and Availability Monitoring:</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            Monitor the availability of web servers to ensure they are responsive and accessible. Receive alerts for
            downtime and address issues promptly to minimize impact on users.
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        expanded={showThirdAnswer ? 'true' : undefined}
        onClick={() => toggleAnswer(3)}
        onKeyPress={handleKeyPress}
      >
        <StyledAccordionSummary>
          <Typography>Resource Utilization Analysis:</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            Analyze resource utilization patterns over time to identify trends and forecast future requirements. This
            helps in capacity planning and ensures that the server infrastructure can handle increasing workloads.
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        expanded={showFourthAnswer ? 'true' : undefined}
        onClick={() => toggleAnswer(4)}
        onKeyPress={handleKeyPress}
      >
        <StyledAccordionSummary>
          <Typography>Scalability and Elasticity:</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            Scale resources up or down based on demand, and monitor the impact of these changes on server performance.
            This ensures that the infrastructure is flexible enough to adapt to varying workloads.
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        expanded={showFifthAnswer ? 'true' : undefined}
        onClick={() => toggleAnswer(5)}
        onKeyPress={handleKeyPress}
      >
        <StyledAccordionSummary>
          <Typography>Alerting and Notification Systems:</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            Set up customizable alerts and notifications for predefined thresholds or critical events. Prompt alerts
            enable administrators to take immediate action and address potential issues before they escalate.
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        expanded={showSixAnswer ? 'true' : undefined}
        onClick={() => toggleAnswer(6)}
        onKeyPress={handleKeyPress}
      >
        <StyledAccordionSummary>
          <Typography>Historical Data and Reporting:</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            Maintain historical data for performance metrics, allowing for trend analysis and retrospective
            troubleshooting.
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        expanded={showSevenAnswer ? 'true' : undefined}
        onClick={() => toggleAnswer(7)}
        onKeyPress={handleKeyPress}
      >
        <StyledAccordionSummary>
          <Typography>User-friendly Dashboards:</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Typography>
            Provide intuitive and user-friendly dashboards that offer a comprehensive view of server health and
            performance. Customizable dashboards enable users to focus on specific metrics relevant to their roles.
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>
    </Section>
  );
};

export default Overview;
