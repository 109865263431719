import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  TextField,
  Container,
  Card,
  Paper,
  Typography,
  Grid,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../../../layouts/shared/ConfirmationDialog';

CustomizePlan.propTypes = {
  closeForm: PropTypes.func,
  reloadData: PropTypes.func,
};

function CustomizePlan(props) {
  const [formData, setFormData] = useState({
    SubscriptionAmount: '',
    subscriptionDays: '',
    numberOfIp: '',
  });

  const [userId, setUserId] = useState(null);
  const [subsciptionPlanId, setSubscriptionPlanId] = useState(null);
  const [customPlanId, setCustomPlanId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    SubscriptionAmount: '',
    subscriptionDays: '',
    numberOfIp: '',
  });
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const urlParts = window.location.pathname.split('/');
  const lastPart = urlParts[urlParts.length - 1];

  const token = localStorage.getItem('token');
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!/^\d*$/.test(value)) {
      setFieldErrors({ ...fieldErrors, [name]: 'Please enter numeric values only.' });
    } else {
      setFieldErrors({ ...fieldErrors, [name]: '' });
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        if (urlParts.length >= 3 && lastPart) {
          const customPlanId = lastPart;
          setCustomPlanId(customPlanId);

          const response = await axios.get(`${process.env.REACT_APP_API_URL}/getAllContactSalesData`, config);
          const responseData = response.data.data;

          if (responseData.some((item) => item._id === customPlanId)) {
            const matchingData = responseData.find((item) => item._id === customPlanId);
            setUserId(matchingData.userId);
            setSubscriptionPlanId(matchingData.subsciptionPlanId);
          }
        } else {
          toast.error('Invalid URL format.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, [lastPart, urlParts.length, token]);

  const handleSaveCustomization = (e) => {
    e.preventDefault();
    if (Object.values(fieldErrors).some((error) => error !== '')) {
      console.error('Form contains errors. Please fix them before submitting.');
      return;
    }
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirmCustomize = async () => {
    setIsConfirmationDialogOpen(false);
    setIsLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const customizeData = {
        ...formData,
        customPlanId,
        userId,
        subsciptionPlanId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/createCustomizedPlansThroughAdmin`,
        customizeData,
        config
      );

      toast.success(response.data.message);
      props.closeForm();
      props.reloadData();
    } catch (error) {
      console.error('Error submitting form data:', error);
      toast.error('An error occurred while submitting the form.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelCustomize = () => {
    setIsConfirmationDialogOpen(false);
  };

  return (
    <Card>
      <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          customize
        </Typography>
        <Paper elevation={3} style={{ padding: '2rem' }}>
          <form onSubmit={handleSaveCustomization}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Subscription Amount"
                  variant="outlined"
                  fullWidth
                  name="SubscriptionAmount"
                  value={formData.SubscriptionAmount}
                  onChange={handleInputChange}
                  error={fieldErrors.SubscriptionAmount !== ''}
                  helperText={fieldErrors.SubscriptionAmount}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Subscription Days"
                  variant="outlined"
                  fullWidth
                  name="subscriptionDays"
                  value={formData.subscriptionDays}
                  onChange={handleInputChange}
                  error={fieldErrors.subscriptionDays !== ''}
                  helperText={fieldErrors.subscriptionDays}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Number of IP"
                  variant="outlined"
                  fullWidth
                  name="numberOfIp"
                  value={formData.numberOfIp}
                  onChange={handleInputChange}
                  error={fieldErrors.numberOfIp !== ''}
                  helperText={fieldErrors.numberOfIp}
                  required
                />
              </Grid>
            </Grid>
            <DialogActions>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Button onClick={() => props.closeForm()} color="primary">
                      Cancel
                    </Button>

                    <Button type="submit" color="primary">
                      Submit
                    </Button>
                  </>
                )}
              </div>
            </DialogActions>
          </form>
        </Paper>
      </Container>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={handleCancelCustomize}
        onConfirm={handleConfirmCustomize}
        message="Are you sure you want to Customize for user ?"
      />
    </Card>
  );
}

export default CustomizePlan;
