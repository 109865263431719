import React from 'react';
import { Link as MuiLink, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { Colors } from '../style/theme';

const StyledLink = styled(MuiLink)({
  display: 'flex',
  alignItems: 'center',
});

const FooterLink = ({ text, link, icon }) => (
  <StyledLink
    href={`${link}`}
    variant="p"
    component="a"
    sx={{
      fontSize: '0.9rem',
      fontWeight: '400',
      textDecoration: 'none',
      color: Colors.black,
      textTransform: 'capitalize',
      '&:hover': {
        color: Colors.blue,
      },
    }}
  >
    {icon && icon}
    {text}
  </StyledLink>
);
FooterLink.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  link: PropTypes.string,
  icon: PropTypes.node,
};

export default FooterLink;
