import React from 'react';
import { MdDashboard } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';

import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const adminConfig = [
  {
    title: 'Users',
    path: '/dashboard/users',
    icon: <CgProfile />,
  },
  {
    title: 'Contact Sales',
    path: '/dashboard/contact-sales',
    icon: <CgProfile />,
  },
  {
    title: 'News Update',
    icon: <MdDashboard />,
    path: '/dashboard/news-update',
  },
  {
    title: 'Profile',
    icon: <CgProfile />,
    path: '/dashboard/profile',
  },
  {
    title: 'Logout',
    path: '/logout',
    icon: icon('ic_lock'),
  },
];

export default adminConfig;
