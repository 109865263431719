import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper } from '@mui/material';

function FeatureBox(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
    transform: isHovered && !isMobile ? 'scale(1.05)' : 'scale(1)',
    transition: 'transform 0.2s ease',
  };

  const imgStyle = {
    marginBottom: '8px',
    width: '50%',
    height: '50%',
  };

  return (
    <Paper
      elevation={3}
      style={boxStyle}
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
    >
      <div style={imgStyle}>
        <img src={props.image} alt={props.title} />
      </div>
      <div>
        <Typography variant="h6">{props.title}</Typography>
        <Typography variant="body2">{props.description}</Typography>
      </div>
    </Paper>
  );
}

FeatureBox.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default FeatureBox;
