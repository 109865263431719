import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Button,
  Avatar,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import axios from 'axios';
import { styled } from '@mui/system';
import { toast } from 'react-toastify';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import account from '../../../_mock/account';
import Scrollbar from '../../../components/scrollbar';

UserItem.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    emailVerified: PropTypes.bool.isRequired,
    phoneNumber: PropTypes.number.isRequired,
    promoCode: PropTypes.string.isRequired,
    referralCount: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
};

const StyledCard = styled(Card)({
  padding: '50px',
  background: 'none',
});

const cardStyle = {
  backgroundColor: '#FFF',
  padding: '20px',
  boxShadow: '1px 1px 8px 0 grey',
  borderRadius: '18px',
};

const fieldStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '2%',
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: '150px',
  height: '150px',
  backgroundColor: '#C0C0C0',
  color: '#fff',
  fontSize: '3rem',
  border: '4px solid #FFFFFF',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  marginBottom: '10px',
  [theme.breakpoints.down('sm')]: {
    margin: '0 auto',
    display: 'block',
  },
}));

const StyledTitle = styled(Typography)({
  fontSize: '1.5rem',
  fontWeight: 'bold',
});

const tableStyle = {
  border: 'none',
  fontSize: '16px',
  height: '270px',
  width: '100%',
};

const tableCellStyle = {
  fontWeight: 'bold',
};

export default function UserItem({ user }) {
  const token = localStorage.getItem('token');
  const [formData, setFormData] = useState({
    addressLine1: user.addressLine1 || '',
    addressLine2: user.addressLine2 || '',
    city: user.city || '',
    state: user.state || '',
    postalCode: user.postalCode || '',
    country: user.country || '',
  });

  const [editMode, setEditMode] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const apiUrl = `${process.env.REACT_APP_API_URL}/updateUserAddress`;
      const response = await axios.post(apiUrl, formData, config);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEditMode(false);
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'An error occurred', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <StyledCard>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} style={{ textAlign: 'center' }}>
          <StyledAvatar src={account.photoURL} alt={account.photoURL} />
          <Typography variant="h6" gutterBottom>
            {user.fullName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <StyledTitle variant="h4" gutterBottom>
            PERSONAL DETAIL
          </StyledTitle>
          <Card style={{ ...cardStyle }}>
            <Scrollbar>
              <CardContent>
                <Table style={tableStyle}>
                  <TableBody>
                    <TableRow>
                      <TableCell style={tableCellStyle}>Full Name</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell style={tableCellStyle}>{user.fullName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={tableCellStyle}>User Name</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell style={tableCellStyle}>{user.userName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={tableCellStyle}>Email</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell style={tableCellStyle}>{user.email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={tableCellStyle}>Email Verified</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell style={tableCellStyle}>
                        {user.emailVerified ? (
                          <CheckCircleOutline style={{ color: 'green' }} />
                        ) : (
                          <HighlightOff style={{ color: 'red' }} />
                        )}
                        {/* {user.emailVerified.toString()} */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={tableCellStyle}>Phone Number</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell style={tableCellStyle}>{user.phoneNumber}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={tableCellStyle}>Promo Code</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell style={tableCellStyle}>{user.promoCode}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={tableCellStyle}>Referral Count</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell style={tableCellStyle}>{user.referralCount}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Scrollbar>
          </Card>
        </Grid>
      </Grid>
      <Card style={{ ...cardStyle, marginTop: '20px' }}>
        <StyledTitle>ADDRESS DETAIL</StyledTitle>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <div style={fieldStyle}>
                  <TextField
                    name="addressLine1"
                    label="Address Line 1"
                    value={formData.addressLine1}
                    onChange={handleChange}
                    autoComplete="addressLine1"
                    variant="outlined"
                    size="small"
                    disabled={!editMode}
                    InputProps={{
                      style: {
                        background: editMode ? 'none' : '#f0f0f0',
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div style={fieldStyle}>
                  <TextField
                    name="addressLine2"
                    label="Address Line 2"
                    value={formData.addressLine2}
                    onChange={handleChange}
                    size="small"
                    autoComplete="addressLine2"
                    variant="outlined"
                    disabled={!editMode}
                    InputProps={{
                      style: {
                        background: editMode ? 'none' : '#f0f0f0',
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div style={fieldStyle}>
                  <TextField
                    name="city"
                    label="City"
                    value={formData.city}
                    onChange={handleChange}
                    autoComplete="city"
                    variant="outlined"
                    size="small"
                    disabled={!editMode}
                    InputProps={{
                      style: {
                        background: editMode ? 'none' : '#f0f0f0',
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div style={fieldStyle}>
                  <TextField
                    name="state"
                    label="State"
                    value={formData.state}
                    onChange={handleChange}
                    autoComplete="state"
                    variant="outlined"
                    size="small"
                    disabled={!editMode}
                    InputProps={{
                      style: {
                        background: editMode ? 'none' : '#f0f0f0',
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div style={fieldStyle}>
                  <TextField
                    name="postalCode"
                    label="Postal Code"
                    value={formData.postalCode}
                    onChange={handleChange}
                    autoComplete="postalCode"
                    variant="outlined"
                    size="small"
                    disabled={!editMode}
                    InputProps={{
                      style: {
                        background: editMode ? 'none' : '#f0f0f0',
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div style={fieldStyle}>
                  <TextField
                    name="country"
                    label="Country"
                    value={formData.country}
                    onChange={handleChange}
                    autoComplete="country"
                    variant="outlined"
                    size="small"
                    disabled={!editMode}
                    InputProps={{
                      style: {
                        background: editMode ? 'none' : '#f0f0f0',
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={11}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0.1rem' }}>
                  {editMode ? (
                    <>
                      <Button variant="contained" onClick={() => setEditMode(false)}>
                        Cancel
                      </Button>
                      <Button variant="contained" type="submit" style={{ marginLeft: '8px' }}>
                        Submit
                      </Button>
                    </>
                  ) : (
                    <Button variant="contained" onClick={() => setEditMode(true)}>
                      Edit
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </StyledCard>
  );
}
