import axios from 'axios';

const account = {
  displayName: '',
  email: '',
  photoURL: '/assets/images/avatars/avatar_default.jpg',
};

const fetchUserData = async () => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');

  if (token && userId) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getUserById?userId=${userId}`, config);

      if (response.status === 200) {
        const userData = response.data.data;

        account.displayName = userData.fullName;
        account.email = userData.email;
        const avatarNumber = Math.floor(Math.random() * 9) + 1;
        account.photoURL = `/assets/images/avatars/male/avatar_${avatarNumber}.jpg`;
      } else {
        console.error('Request failed with status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }
};

fetchUserData();

export default account;
